import { useEffect } from "react";
import { useHistory } from "react-router-dom";
// import { toast } from 'react-toastify';
// import { pushNotify } from "../../components/Notify";
import { getLoginUrl } from "../../utils/commonUtils";

const CheckMaintain = ({
  errorMaintain,
  logout
}) => {
  const history = useHistory();
  // const toastId = useRef(null);
  // const toastMessage = useRef(null);

  useEffect(() => {
    if (errorMaintain?.type) {
      // const message = errorMaintain?.errors?.messageShow
      // if(!toast.isActive(toastId.current) || message !== toastMessage.current) {
      //   toastId.current = pushNotify('error', message)
      //   toastMessage.current = message
      // }
      
      const loginUrl = getLoginUrl()
      if (history.location.pathname !== loginUrl) {
        history.push(loginUrl);
        logout();
      }
    }
  }, [errorMaintain, history, logout]);

  return null;
};

export default CheckMaintain;