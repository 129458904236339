import React from "react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {
  ProfileSettings,
  SettingGroup,
  SettingIcon,
  SettingItem,
  UserProfileImage
} from "../style";
import {
  TextBlackBold,
  TextBlackMedium,
  TextGrayDefault
} from "../../Text/style";
import ProfileIcon from "../../../static/images/Profile.png";
import FilterIcon from "../../../static/images/Filter.png";

const Profile = ({
  setEditProfile,
  setSettingTarget,
  nickName,
  userName,
  src
}) => (
  <>
    <UserProfileImage>
      <img src={src} alt="user" />
    </UserProfileImage>

    <div className="text-center mb-5">
      <TextBlackBold className="text-uppercase">{nickName}</TextBlackBold>
      <TextGrayDefault fontSize={14}>{userName}</TextGrayDefault>
    </div>

    <SettingGroup>
      <ProfileSettings>プロファイル設定</ProfileSettings>
      <SettingItem onClick={setEditProfile}>
        <div className="d-flex align-items-center">
          <SettingIcon src={ProfileIcon} alt="profile" />
          <TextBlackMedium fontSize={14}>プロフィール</TextBlackMedium>
        </div>
        <FontAwesomeIcon className="mr-2" icon={faAngleRight} />
      </SettingItem>

      <SettingItem onClick={setSettingTarget}>
        <div className="d-flex align-items-center">
          <SettingIcon src={FilterIcon} alt="profile" />
          <TextBlackMedium fontSize={14}>目標設定</TextBlackMedium>
        </div>
        <FontAwesomeIcon className="mr-2" icon={faAngleRight} />
      </SettingItem>
    </SettingGroup>
  </>
);

Profile.propTypes = {
  setEditProfile: PropTypes.func,
  setSettingTarget: PropTypes.func,
  nickName: PropTypes.string,
  email: PropTypes.string
};

Profile.defaultProps = {
  setEditProfile: () => {},
  setSettingTarget: () => {},
  nickName: "",
  email: ""
};

export default React.memo(Profile);
