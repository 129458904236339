import _ from 'lodash'
import { TYPES } from "../actions";

const initialState = {
  status: null,
  error: null,
  myNoteDetails: [],
  currentPage: 0,
  totalPage: 0,
  currentId: 0,
  autoWordsData: [],
  allWordsData: [],
  isOutSide: false,
  isAcceptOutSide: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ACTIONS WITHOUT DATA WILL BE PLACES HERE
    case TYPES.MY_NOTE_REQUEST:
    case TYPES.AUTO_WORDS_REQUEST:
    case TYPES.DEL_WORD_DETAILS_REQUEST:
    case TYPES.EDIT_WORD_DETAILS_REQUEST:
    case TYPES.EDIT_WORD_DETAILS_SUCCESS:
      return {
        ...state,
        status: action.type
      };

    // ACTIONS WITH DATA WILL BE PLACES HERE
    case TYPES.MY_NOTE_SUCCESS:
      return {
        ...state,
        status: action.type,
        myNoteDetails: action.data.myNoteDetails.myVocabularyDetailsList,
        currentPage: action.data.myNoteDetails.page.currentPage,
        totalPage: action.data.myNoteDetails.page.totalPage,
        currentId: action.data.id
      };
    
    case TYPES.DEL_WORD_DETAILS_SUCCESS:
      if (action.data.isDelGroup)
        return {
          ...state,
          status: action.type,
          myNoteDetails: []
        };

      const newDetails = [...state.myNoteDetails].filter(item => item.id !== action.data.id);
      return {
        ...state,
        status: action.type,
        myNoteDetails: newDetails
      };
    case TYPES.AUTO_WORDS_SUCCESS:
      const newData = _.cloneDeep(action.data.autoWords);
      return {
        ...state,
        status: action.type,
        autoWordsData: newData,
        allWordsData: action.data.autoWords
      };

    case TYPES.UPDATE_CURRENT_VOCAL_GROUP_SUCCESS:
      return {
        ...state,
        status: action.type,
        currentId: 0,
        myNoteDetails: []
      };
    
    case TYPES.CLEAR_AUTO_WORDS_SUCCESS:
      return {
        ...state,
        status: action.type,
        autoWordsData: []
      }

    case TYPES.CHECK_OUT_SIDE:
      return {
        ...state,
        status: action.type,
        isOutSide: action.data.data,
      }

    case TYPES.ACCEPT_OUT_SIDE:
      return {
        ...state,
        status: action.type,
        isAcceptOutSide: !state.isAcceptOutSide,
      }

    // ACTION FAILED WILL BE PLACED HERE
    case TYPES.MY_NOTE_FAILED:
    case TYPES.EDIT_WORD_DETAILS_FAILED:
      return {
        ...state,
        status: "",
        error: ""
      };
    default:
      return state;
  }
};