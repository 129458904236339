import { callApi } from "../../utils/commonUtils";
import Storage from "../../utils/storega";
import { getEnv } from "../../configs/env";
import { TYPES } from "./index";

const updateStatus = (status, statusInfo) => {
  return {
    type: status,
    data: statusInfo
  };
};

const getMyNoteDetails = (payload) => {
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.MY_NOTE_REQUEST));
    callApi(
      `${getEnv("REACT_APP_API_SERVER")}/users/my_vocabulary_groups/${payload.groupsId}/my_vocabulary_details?pageSize=${payload.pageSize || 20}`,
      config,
      null,
      (data) => {
        dispatch(
          updateStatus(TYPES.MY_NOTE_SUCCESS, { myNoteDetails: data.data, id: payload.groupsId })
        );
      },
      (err) => {
        dispatch(updateStatus(TYPES.MY_NOTE_FAILED, { error: err }));
      }
    );
  };
}

const delWWordDetails = (payload) => {
  const config = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };

  if (payload.isDelGroup)
    return (dispatch) => {
      dispatch(updateStatus(TYPES.DEL_WORD_DETAILS_SUCCESS, {isDelGroup: payload.isDelGroup}));
    };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.DEL_WORD_DETAILS_REQUEST));
    callApi(
      `${getEnv("REACT_APP_API_SERVER")}/users/my_vocabulary_groups/my_vocabulary_details/${payload.id}`,
      config,
      null,
      (data) => {
        dispatch(
          updateStatus(TYPES.DEL_WORD_DETAILS_SUCCESS, { myNoteDetail: data.data, id: payload.id, isDelGroup: payload.isDelGroup})
        );
      },
      (err) => {
        dispatch(updateStatus(TYPES.DEL_WORD_DETAILS_FAILED, { error: err }));
      }
    );
  };
}

const editWordDetails = (payload) => {
  const config = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    },
    body: JSON.stringify({
      myVocabularyDetailsList: payload.data
    })
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.EDIT_WORD_DETAILS_REQUEST));
    callApi(
      `${getEnv("REACT_APP_API_SERVER")}/users/my_vocabulary_groups/${payload.id}/my_vocabulary_details/edit_list`,
      config,
      null,
      (data) => {
        dispatch(
          updateStatus(TYPES.EDIT_WORD_DETAILS_SUCCESS, { editWordDetails: data.data})
        );
      },
      (err) => {
        dispatch(updateStatus(TYPES.EDIT_WORD_DETAILS_FAILED, { error: err }));
      }
    );
  };
}

const getAutoWords = (payload) => {
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.AUTO_WORDS_REQUEST));
    const name = payload.name?.toLowerCase().trim() || '';
    callApi(
      `${getEnv("REACT_APP_API_SERVER")}/users/questions/words?name=${name}`,
      config,
      null,
      (data) => {
        dispatch(
          updateStatus(TYPES.AUTO_WORDS_SUCCESS, { autoWords: data.data })
        );
      },
      (err) => {
        dispatch(updateStatus(TYPES.AUTO_WORDS_FAILED, { error: err }));
      }
    );
  };
}

const updateCurrentVocalGroup = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.UPDATE_CURRENT_VOCAL_GROUP_SUCCESS));
  }
};

const clearAllWords = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CLEAR_AUTO_WORDS_SUCCESS));
  }
};

const checkOutSideAction = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CHECK_OUT_SIDE,{data: payload}));
  }
};

const acceptOutSideAction = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.ACCEPT_OUT_SIDE));
  }
};

export {
  getMyNoteDetails,
  delWWordDetails,
  editWordDetails,
  getAutoWords,
  updateCurrentVocalGroup,
  clearAllWords,
  checkOutSideAction,
  acceptOutSideAction
};