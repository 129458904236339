import Storage from "../../utils/storega";
import { SYSTEM_IS_MAINTAINING, TOKEN_TIMEOUT } from "../../utils/commonUtils";
import { TYPES } from "../actions";

const getUserFromLocalStorage = () => {
  const tokenExpiration = Storage.get("USER_ACCESS_TOKEN_EXPIRATION");
  if (!tokenExpiration || Number(tokenExpiration) < Date.now()) return null;
  return { token: Storage.get("USER_ACCESS_TOKEN") };
};

const initialState = {
  status: null,
  error: null,
  errorStatus: null,
  errorTitle: null,
  errorMaintain: null,
  user: getUserFromLocalStorage(),
  maintainStt: false,
  maintainDes: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ACTIONS WITHOUT DATA WILL BE PLACES HERE
    case TYPES.LOGIN_REQUEST:
    case TYPES.SSO_TOKEN_VERIFY_REQUEST:
    case TYPES.CHECK_MAINTAIN_REQUEST:
      return {
        ...state,
        status: action.type
      };

    // ACTIONS WITH DATA WILL BE PLACES HERE
    case TYPES.LOGIN_SUCCESS:
      Storage.set("USER_ACCESS_TOKEN", action.data.user.token);
      return {
        ...state,
        status: action.type
      };

    case TYPES.CHECK_MAINTAIN_SUCCESS:
      const stt = action.data.maintain?.filter(item => item.systemState === 2);
      return {
        ...state,
        status: action.type,
        maintainStt: stt.length > 0 && stt[0].status === 1,
        maintainDes: stt.length > 0 ? stt[0].value : ''
      };

    case TYPES.SSO_TOKEN_VERIFY_SUCCESS:
      Storage.set("USER_ACCESS_TOKEN", action.data.user.smartEdutoken);
      return {
        ...state,
        status: action.type
      };

    case TYPES.LOGOUT:
      Storage.remove("USER_ACCESS_TOKEN");
      Storage.remove("USER_PROFILE");
      Storage.remove("USER_SELECT_GRADE");
      Storage.remove("USER_GRADE_HOMEPAGE");
      return false;
    // ACTION FAILED WILL BE PLACED HERE
    case TYPES.LOGIN_FAILED:
    case TYPES.SSO_TOKEN_VERIFY_FAILED:
    case TYPES.CHECK_MAINTAIN_FAILED: {
      const { error } = action.data || {}
      if (error?.type === SYSTEM_IS_MAINTAINING || error?.type === TOKEN_TIMEOUT) {
        return { ...state, errorMaintain: action.data.error, status: action.type }
      }
      return {
        ...state,
        status: action.type,
        error: action.data.error
      };
    }
    default:
      if (action.type.includes('FAILED')) {
        const { error } = action.data || {}
        if (error?.type === SYSTEM_IS_MAINTAINING || error?.type === TOKEN_TIMEOUT) {
          return { ...state, errorMaintain: action.data.error, status: action.type }
        }
      }
      return state;
  }
};
