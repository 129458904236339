import _ from 'lodash';
import { TYPES } from "../actions";

const initialState = {
  status: null,
  error: null,
  learningDatas: {},
  vocalGroupData: [],
  currentPage: 0,
  totalPage: 0,
  inforTest: {},
  breadSrumbData: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ACTIONS WITHOUT DATA WILL BE PLACES HERE
    case TYPES.LEARNING_REQUEST:
    case TYPES.VOCAL_GROUPS_REQUEST:
    case TYPES.SEARCH_GROUPS_REQUEST:
    case TYPES.CREATE_GROUP_REQUEST:
    case TYPES.ADD_WORD_GROUP_REQUEST:
    case TYPES.EDIT_GROUP_REQUEST:
    case TYPES.INFOR_TEST_REQUEST:
    case TYPES.DEL_WORD_GROUP_REQUEST:
      return {
        ...state,
        status: action.type
      };

    // ACTIONS WITH DATA WILL BE PLACES HERE
    case TYPES.LEARNING_SUCCESS:
      return {
        ...state,
        status: action.type,
        learningDatas: action.data.learnings
      };

    case TYPES.VOCAL_GROUPS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { myVocabularyGroupsList } = action.data.vocalGroups;
      // eslint-disable-next-line no-case-declarations
      const { currentPage, totalPage } = action.data.vocalGroups.page;

      return {
        ...state,
        status: action.type,
        vocalGroupData:
          currentPage === 0
            ? myVocabularyGroupsList
            : state.vocalGroupData.concat(myVocabularyGroupsList),
        currentPage,
        totalPage,
        breadSrumbData: action.data.vocalGroups.breadSrumbDTO
      };

    case TYPES.SEARCH_GROUPS_SUCCESS:
      return {
        ...state,
        status: action.type,
        vocalGroupData: action.data.searchData.myVocabularyGroupsList,
        currentPage: action.data.searchData.page.currentPage,
        totalPage: action.data.searchData.page.totalPage
      };

    case TYPES.CREATE_GROUP_SUCCESS:
      return {
        ...state,
        status: action.type
      };

    case TYPES.ADD_WORD_GROUP_SUCCESS:
      return {
        ...state,
        status: action.type
      };
    
    case TYPES.DEL_WORD_GROUP_SUCCESS:
      return {
        ...state,
        status: action.type
      };
    
    case TYPES.DEL_GROUP_SUCCESS:
      const newVocal = state.vocalGroupData.filter(item => item.id !== action.data.id);
      return {
        ...state,
        status: action.type,
        vocalGroupData: newVocal
      };

    case TYPES.EDIT_GROUP_SUCCESS:
      const newGroups = _.map(state.vocalGroupData, item => item.id === action.data.editGroup.id ? action.data.editGroup : item)
      return {
        ...state,
        status: action.type,
        vocalGroupData: newGroups
      };
    
    case TYPES.INFOR_TEST_SUCCESS:
      return {
        ...state,
        status: action.type,
        inforTest: action.data.inforTest
      };

    case TYPES.RESET_LEARNING_SUCCESS:
      return {
        ...initialState
      }

    // ACTION FAILED WILL BE PLACED HERE
    case TYPES.LEARNING_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error?.messageId || action.data.error?.message
      };

    case TYPES.VOCAL_GROUPS_FAILED:
    case TYPES.SEARCH_GROUPS_FAILED:
    case TYPES.CREATE_GROUP_FAILED:
    case TYPES.ADD_WORD_GROUP_FAILED:
    case TYPES.DEL_GROUP_FAILED:
      return {
        ...state,
        status: "",
        error: ""
      };
    default:
      return state;
  }
};
