/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useState} from 'react';
import {Navbar} from 'reactstrap';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import {Link, matchPath} from "react-router-dom";
import {getEnv} from "../../configs/env";
import Storage from "../../utils/storega";
import {TextBlackBold, TextBlackMedium} from '../Text/style';
import MainLogo from '../../static/images/logo2x.png';
import IconAva from '../../static/images/userImage.png'
import UnitedKingdom from '../../static/images/UnitedKingdom.png';
import Geography from '../../static/images/iconNVGeo.png';
import Nation from '../../static/images/iconNVNation.png';
import Math from '../../static/images/iconNVMath.png';
import Math_2 from '../../static/images/iconNVMath_2.png';
import Physical from '../../static/images/iconNVPhysical.png';
import Social from '../../static/images/iconNVSocial.png';
import IconExpStar from "../../static/images/IconExpStar.png";
import IconSidebar from "../../static/images/IconSidebar.png";
// import IconArrow from "../../static/images/IconArrow.png";
// import Modal from "../Modal/Modal";
import RouterPath from "../../constants/router-path";
import theme from '../../style/variables';
import useChangeGrade from "../../hooks/useChangeGrade";
import { SUBJECT } from "../../constants/common";
import {
  ContentGroup,
  ContentRight,
  CustomIcon,
  Divider,
  Header,
  Logo, MenuExplain,
  MobileLogo,
  NavigationIcon,
  SelectGroup,
  SelectItem,
  TextDark,
  UserImage,
  UserName
} from "./style";
// import HeaderMenu from "./HeaderMenu";


const Navigation = ({
  toggleSidebar,
  toggleSidebarRight,
  showModalGradeNavigation,
  // modalGradeNavigation,
  gradeList,
  changeGrade,
  currentGrade,
  updateSubSubjectList,
  history,
  statusHome,
  sidebar,
  getSubjectsHeader,
  headerSubjectList,
  headerSubSubjectList,
  isRedirectPage,
  isOutSide,
  checkOutSideAction,
  acceptOutSideAction,
  sidebarRight,
  isAcceptOutSide
}) => {
  const { character, exp, nickName } = Storage.get("USER_PROFILE", {});
  const [isShowGrade, setIsShowGrade] = useState(true);

  const {
    // selectedGrade,
    setSelectedGrade,
    // selectedSubject,
    setSelectedSubject,
    // selectedSubSubject,
    setSelectedSubSubject,
    localGrade
    // handleChangeSelectedGrade,
    // handleChangeGrade,
    // handleChangeSelectedSubject,
    // handleSelectSubSubject
  } = useChangeGrade({
    gradeList,
    changeGrade,
    headerSubjectList,
    headerSubSubjectList,
    showModalGrade: 
      showModalGradeNavigation,
      history,
      updateSubSubjectList,
      statusHome,
      getSubjectsHeader,
      isRedirectPage
    });

  const isCharactorPage = matchPath(history.location.pathname, {
    path: RouterPath.SELECT_CHARRACTOR.path
  });

  const matchLessonPath = matchPath(history.location.pathname, {
    path: RouterPath.LESSONS.path
  });

  const matchLessonPathLesson = matchPath(history.location.pathname, {
    path: RouterPath.LESSONS.pathLesson
  });

  const matchLessonPathUnit = matchPath(history.location.pathname, {
    path: RouterPath.LESSONS.pathUnit
  });

  useEffect(() => {
    if (history.location.pathname.includes('/lessons'))
      setIsShowGrade(true);
    else setIsShowGrade(false);
  }, [history.location]);

  useEffect(() => {
    
    const paramsPath = matchLessonPath?.params || matchLessonPathLesson?.params || matchLessonPathUnit?.params;
    
    if(history.location.pathname.includes('/lessons') && paramsPath?.gradeId) {
      const params = {gradeGroupCode: paramsPath.gradeId, menu: paramsPath.type || 1};
      getSubjectsHeader({ params });
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

useEffect(() => {
  const paramsPath = matchLessonPath?.params || matchLessonPathLesson?.params || matchLessonPathUnit?.params;

  if(headerSubjectList.length > 0 && history.location.pathname.includes('/lessons') &&  paramsPath?.gradeId) {
    const filterSubjectList = headerSubjectList.find( item => item.subjectId === Number(paramsPath.subjectId))
    const indexSubject = headerSubjectList.findIndex(itemIndex => itemIndex.subjectId === Number(paramsPath.subjectId));
    const grade = gradeList.find(itemIndex => itemIndex.groupCode === Number(paramsPath.gradeId));
    const indexGrade = gradeList.findIndex((i) => i.groupCode === Number(paramsPath.gradeId));

    changeGrade({
      grade: {
        ...grade,
        subject: filterSubjectList,
        subSubject: filterSubjectList?.subSubjectList.find((i) => i.id === Number(paramsPath.subSubjectId))
      }
    });

    Storage.set("USER_SELECT_GRADE", {
      grade: {
        ...grade,
        gradeLC: indexGrade
      },
      subject: {
        ...filterSubjectList,
        subjectLC: indexSubject > -1 ? indexSubject : 0
      },
      subSubject: filterSubjectList?.subSubjectList.find((i) => i.id === Number(paramsPath.subSubjectId))
    });
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[headerSubjectList, history])

  useEffect(() => {
    if (!sidebar && window.innerWidth <= theme.tabletWidth) {
      toggleSidebar();
    }

    if (localGrade && history.location.pathname !== RouterPath.SELECT_CHARRACTOR.path) {
      setSelectedGrade(localGrade.grade?.gradeLC);
      setSelectedSubject(localGrade.subject?.subjectLC);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentGrade.subject && currentGrade.subject.subjectId) {
      const grade = Storage.get("USER_SELECT_GRADE");
      updateSubSubjectList({ subject: grade.subject });
      setSelectedSubject(grade.subject.subjectLC);
      setSelectedSubSubject(grade.subSubject?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGrade]);

  const handleClickSidebar = () => {
    if (window.innerWidth <= theme.tabletWidth) {
      toggleSidebar();
    }
  };

  const openModal = () => {
    // eslint-disable-next-line no-shadow
    const localGrade = Storage.get("USER_SELECT_GRADE");
    if (localGrade) {
      const params = {gradeGroupCode: localGrade.grade?.groupCode, menu: Storage.get('CURRENT_MENU_TYPE') || 1};
      getSubjectsHeader({ params });
      updateSubSubjectList({ subject: localGrade.subject });
      setSelectedSubject(localGrade.subject?.subjectLC);
      setSelectedSubSubject(localGrade.subSubject?.id);
    }
    showModalGradeNavigation();
  }

  const checkOutSideAvatar = useCallback(() =>{
    if(isOutSide && history.location.pathname === RouterPath.MY_NOTE.path) {
      let cfAlet;
      cfAlet = window.confirm('このままではデータが失われます。よろしいですか？');
      if(cfAlet) {
        toggleSidebarRight();
        checkOutSideAction(false);
        acceptOutSideAction();
      }
    } else {
      toggleSidebarRight();
    }
  },[isOutSide, toggleSidebarRight, history, checkOutSideAction, acceptOutSideAction])

  useEffect(() => {
    if(!isOutSide 
      && history.location.pathname === RouterPath.MY_NOTE.path 
      && !sidebarRight 
      && isAcceptOutSide) {
      acceptOutSideAction();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sidebarRight])

  return (
    <>
      <Header $toggle={sidebar}>
          <MobileLogo $toggle={sidebar}>
            <Link className="mobile-logo-text" to={RouterPath.HOME.path} onClick={handleClickSidebar}>
              <Logo src={MainLogo} />
            </Link>
          </MobileLogo>
          <Navbar
            className="justify-content-between"
            color="transparent"
            light
            expand="md"
          >
            <div className="d-flex align-items-center">
              <NavigationIcon
                style={{ width: "25px" }}
                src={IconSidebar}
                alt="Side"
                onClick={!isCharactorPage ? toggleSidebar : () => null}
                className="desktop-nav-icon"
              />
              <Link className="logo-text" to={RouterPath.HOME.path}>
                <Logo src={MainLogo} />
              </Link>
            </div>

            <ContentRight className="desktop-content-right">
              <ContentGroup>
                <UserName className="nick-name">{nickName}</UserName>
                <UserImage
                  src={(character && `${getEnv("MEDIA_API_SERVER")}/${character.image}`) || IconAva}
                  alt="Avatar"
                  width={45}
                  onClick={() => checkOutSideAvatar()}
                  className="user-image"
                />
              </ContentGroup>

              <ContentGroup className="exp-group">
                <img className="mr-1" src={IconExpStar} alt="Star" />
                <div className="d-flex">
                  <TextDark className="exp">{exp}</TextDark><span>ポイント</span>
                </div>
              </ContentGroup>
              {isShowGrade && (
                <ContentGroup className="cursor">
                  <MenuExplain>
                    <TextBlackBold className="mr-1">学年と教科を選択:</TextBlackBold>
                    {/* <img className="mr-2" src={IconArrow} alt="Star" /> */}
                  </MenuExplain>

                  <SelectGroup onClick={() => openModal()}>
                    {(currentGrade.subject?.subjectName || currentGrade.groupCode === 1) && (
                      <>
                        <SelectItem>
                          <UserImage
                            src={currentGrade.subject?.subjectName === SUBJECT.ENGLISH ? UnitedKingdom 
                              : currentGrade.subject?.subjectName === SUBJECT.NATIONAL_LANGUAGE ? Geography 
                              : currentGrade.subject?.subjectName === SUBJECT.SOCIETY_2 ? Nation
                              : currentGrade.subject?.subjectName === SUBJECT.MATH ? Math
                              : currentGrade.subject?.subjectName === SUBJECT.MATH_2 ? Math_2
                              : currentGrade.subject?.subjectName === SUBJECT.PHYSICAL ? Physical : Social }
                            alt="Avatar"
                            className="mr-1 mr-sm-2"
                          />
                          <TextBlackBold className="subject-name">
                            {currentGrade.subject?.subjectName}
                            {currentGrade.groupCode === 1 && (
                              <span> - {currentGrade.subSubject?.name}</span>
                            )}
                          </TextBlackBold>
                        </SelectItem>

                        <Divider />
                      </>
                    )}

                    <SelectItem>
                      <TextBlackBold className="mr-1 mr-sm-2">学年:</TextBlackBold>
                      <TextBlackMedium className="mr-1 mr-sm-2 group-name">
                        {currentGrade.groupName}
                      </TextBlackMedium>
                      <CustomIcon icon={faChevronRight} />
                    </SelectItem>
                  </SelectGroup>
                </ContentGroup>
              )}
            </ContentRight>
          </Navbar>

        {/* <Modal
          show={modalGradeNavigation}
          close={handleCloseModal}
          title="学年と教科を選択"
        >
          <HeaderMenu
            gradeList={gradeList}
            headerSubjectList={headerSubjectList}
            headerSubSubjectList={headerSubSubjectList}
            selectedGrade={selectedGrade}
            handleChangeSelectedGrade={handleChangeSelectedGrade}
            selectedSubject={selectedSubject}
            handleChangeSelectedSubject={handleChangeSelectedSubject}
            selectedSubSubject={selectedSubSubject}
            handleSelectSubSubject={handleSelectSubSubject}
            handleChangeGrade={handleChangeGrade}
            number={1}
          />
        </Modal> */}
      </Header>
    </>
  );
};

export default React.memo(Navigation);

Navigation.propTypes = {
  toggleSidebar: PropTypes.func,
  toggleSidebarRight: PropTypes.func,
  changeGrade: PropTypes.func,
  updateSubSubjectList: PropTypes.func,
  getSubjectsHeader: PropTypes.func,
  currentGrade: PropTypes.instanceOf(Object),
  gradeList: PropTypes.instanceOf(Array),
  headerSubjectList: PropTypes.instanceOf(Array),
  headerSubSubjectList: PropTypes.instanceOf(Array)
  // modalGradeNavigation: PropTypes.bool
};

Navigation.defaultProps = {
  toggleSidebar: () => {},
  toggleSidebarRight: () => {},
  changeGrade: () => {},
  updateSubSubjectList: () => {},
  getSubjectsHeader: () => {},
  currentGrade: {},
  gradeList: [],
  headerSubjectList: [],
  headerSubSubjectList: []
  // modalGradeNavigation: false
};
