const RouterPath = {
  HOME: {
    path: "/"
  },
  SIGN_IN: {
    path: "/sign-in"
  },
  SIGN_IN_SCHOOL: {
    path: "/gaihan"
  },
  DEMO: {
    path: "/demo"
  },
  LESSONS: {
    path: `/lessons/:gradeId/subject/:subjectId/sub_subjectId/:subSubjectId/type/:type`,
    pathUnit: `/lessons/:gradeId/subject/:subjectId/sub_subjectId/:subSubjectId/unit/:unitId/type/:type`,
    pathLesson: `/lessons/:gradeId/subject/:subjectId/sub_subjectId/:subSubjectId/chapter/:unitId/unit/:lessonId/type/:type/is_myNote/:isMyNote`,
    pathType: ({ gradeId, subjectId, subSubjectId, type }) =>
      `/lessons/${gradeId}/subject/${subjectId}/sub_subjectId/${subSubjectId}/type/${type}`,
    pathTypeUnit: ({ gradeId, subjectId, subSubjectId, unitId, type }) =>
      `/lessons/${gradeId}/subject/${subjectId}/sub_subjectId/${subSubjectId}/unit/${unitId}/type/${type}`,
    pathTypeLesson: ({ gradeId, subjectId, subSubjectId, unitId, lessonId, type, isMyNote }) =>
      `/lessons/${gradeId}/subject/${subjectId}/sub_subjectId/${subSubjectId}/chapter/${unitId}/unit/${lessonId}/type/${type}/is_myNote/${isMyNote}`
  },
  UNITS: {
    path: "/units"
  },
  TESTING: {
    path: `/testing/:id/mode/:mode/shuffle_question/:isShuffleQuestion/type/:type/question_wrong/:questionWrong/is_myNote/:isMyNote`,
    pathQuest: `/testing/:id/testing_type/:testingType/shuffle_question/:isShuffleQuestion/question_wrong/:questionWrong`,
    pathParams: ({ id, mode, isShuffleQuestion, type, questionWrong, isMyNote }) => `/testing/${id}/mode/${mode}/shuffle_question/${isShuffleQuestion}/type/${type}/question_wrong/${questionWrong}/is_myNote/${isMyNote}`,
    pathTypeQuest: ({testingType, id, isShuffleQuestion, questionWrong}) => `/testing/${id}/testing_type/${testingType}/shuffle_question/${isShuffleQuestion}/question_wrong/${questionWrong}`,
    key: "testing"
  },
  LEARNING: {
    path: `/learning/:id/type/:type/is_quest/:isQuest/is_myNote/:isMyNote`,
    pathParams: ({id, type, isQuest, isMyNote}) => `/learning/${id}/type/${type}/is_quest/${isQuest || false}/is_myNote/${isMyNote}`
  },
  RESULT: {
    path: "/result",
    pathParams: (idUrl, mode) => `/result/${idUrl}/mode/${mode}`
  },
  COMMON: {
    path: "/common"
  },
  PROGRESS: {
    path: "/progress"
  },
  MY_NOTE: {
    path: "/my-note"
  },
  RANKING: {
    path: "/ranking"
  },
  OFTEN_WRONG: {
    path: "/often-wrong"
  },
  SELECT_CHARRACTOR: {
    path: "/character"
  }
};

export default RouterPath;
