import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from "react-router-dom";
import {showModalGrade, toggleSidebar} from '../../store/actions/sidebar'
import {changeGrade, getSubjectsHeader, updateSubSubjectList} from "../../store/actions/home";
import {
  checkOutSideAction,
  acceptOutSideAction
} from '../../store/actions/myNote';
import Sidebar from './Sidebar';

const mapStateToProps = (state) => ({
  status: state.sidebar.status,
  error: state.sidebar.error,
  sidebar: state.sidebar.sidebar,
  currentGrade: state.home.currentGrade,
  subjectList: state.home.subjectList,
  modalGrade: state.sidebar.modalGrade,
  isRedirectPage: state.sidebar.isRedirectPage,
  gradeList: state.home.gradeList,
  headerSubjectList: state.home.headerSubjectList,
  headerSubSubjectList: state.home.headerSubSubjectList,
  statusHome: state.home.status,
  isOutSide: state.myNote.isOutSide,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toggleSidebar,
  changeGrade,
  showModalGrade,
  updateSubSubjectList,
  getSubjectsHeader,
  checkOutSideAction,
  acceptOutSideAction,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
