/* eslint-disable no-nested-ternary */
import styled from "styled-components";
import { Button } from "reactstrap";

export const MainButton = styled(Button)`
  width: ${({ theme, width }) => width === 1 ? 'auto' : width ? `${width}px` : `${theme.buttonMinWidth}px`};
  box-shadow: none;
  outline: none;
  background-color: ${({ theme, color, outline }) =>
    outline ? theme.mainButtonOutline[color] : theme.mainButton[color]};
  border-color: ${({ theme, color }) => theme.mainButton[color]};
  overflow: hidden;
  text-overflow: ellipsis;
  padding: .375rem .5rem;

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none;
    outline: none;
    background-color: transparent;
    color: ${({ theme }) => theme.textColorGray};
  }
  
  pointer-events: ${({isloading}) => isloading === 1? "none" : 'auto'};
  touch-action: none;
  
  &.disabled {
    pointer-events: none;
  }

  &.btn--progress {
    background-color: ${({ theme, color, outline }) =>
    outline ? theme.mainButtonOutline[color] : theme.mainButton[color]};
    border-color: ${({ theme, color }) => theme.mainButton[color]};
  }

  &.btn--progress:focus {
    background-color: ${({ theme, color, outline }) =>
    outline ? theme.mainButtonOutline[color] : theme.mainButton[color]};
    border-color: ${({ theme, color }) => theme.mainButton[color]};
    color: white;
  }
  
  @media screen and (max-width: ${({ theme }) => theme.homepageMaxWidth}px) {
    &.prevent-active {
      &:hover,
      &:active {
        background-color: ${({ theme, color, outline }) => outline ? theme.mainButtonOutline[color] : theme.mainButton[color]};
        color: #007bff;
      }
    }
  }
`;
