import styled from "styled-components";
import { Modal } from "reactstrap";

export const CustomModal = styled(Modal)`
  height: 100%;
  margin: 0 auto;
  border-radius: .3rem;
  width: ${({ theme }) => theme.modalWidth}px;

  .modal-content {
    border: 0;
    box-shadow: ${({header}) => header === 0 ? "2px 2px 12px #2B53A01A" : 'none'} ;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ header }) =>  header === 0 ? "#fff" : "transparent"};
  }
  
  .modal-header {
    position: relative;
    border: 0;
    color: ${({ theme }) => theme.modalHeaderTextColor};
    background-color: ${({ theme }) => theme.modalHeaderBackgroundColor};
    
    .modal-title {
      font-weight: 700;
      text-align: center;
      width: 100%;
    }
    
    button {
      position: absolute;
      right: 12px;
      top: 17px;
      color: ${({ theme }) => theme.modalHeaderTextColor};
      text-shadow: none;
      outline: none;
    }
  }
  
  .modal-body {
    padding: 1rem 2.5rem 1.5rem 2.5rem;
  }
  
  @media screen and (max-width: ${({ theme }) => theme.iPadMinWidth}px) {
    max-width: 500px;
    width: 95%;
    
    .modal-body {
      padding: 1rem 1.5rem 1.5rem 1.5rem;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    max-width: 500px;
    width: 95%;
    
    .modal-body {
      padding: 1rem 1rem 1.2rem 1rem;
    }
  }
  
  &.multiple-images {
    width: 80%;
    max-width: 80%;
    overflow: hidden;
    
    .modal-content {
      background: transparent;
      box-shadow: none;
    }
    
    .modal-header {
      display: none;
    }
    
    @media screen and (max-width: ${({ theme }) => theme.iPadMinWidth}px) {
      width: 90%;
      max-width: 90%;
      overflow: visible;

      .modal-body {
        padding: 0;
      }
    }
  }
`;