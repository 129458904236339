import styled from "styled-components";
import { Input } from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { WrapperItem } from "../../components/WrapperItem/style";
import {
  TextBlackBold,
  TextBlackDefault,
  textBlackMedium,
  TextGrayBold,
  TextGrayDefault
} from "../../components/Text/style";

export const HomeWrapper = styled.div`
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  flex-wrap: wrap;
  height: 100%;

  &.px-content {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const LeftContentWrapper = styled.div`
  padding-right: 15px;
  width: 75%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.homepageMaxWidth}px) {
    width: 100%;
    padding-right: 1px;
    order: 2;
  }
`;

export const RightContentWrapper = styled.div`
  position: relative;
  width: 25%;
  max-width: 380px;
  background-color: white;
  box-shadow: 2px 2px 12px ${({ theme }) => theme.boxShadowColor};
  border-radius: 5px;
  padding: 1rem;

  @media screen and (max-width: ${({ theme }) => theme.homepageMaxWidth}px) {
    width: 100%;
    max-width: 100%;
    order: 1;
    margin-bottom: 15px;
    .user-content {
      display: flex;
      .user-content-right {
        width: calc(100% - 135px);
        padding-left: 12px;
      }
    }
  }
`;

export const SliderQuestItem = styled.div`
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.progressGroupBackgroundColor};
  }
  .slider-quest-item-text {
    @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
      font-size: 13px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    padding: 5px 2px;
  }
`;
export const NumberQuest = styled.div`
  min-width: 25px;
  height: 25px;
  border-radius: 5px;
  position: relative;
  color: #ffffff;
  text-align: center;
  background-color: ${({ theme }) => theme.mainButton.danger};

  &.number-friends__items {
    position: absolute;
    display: flex;
    justify-content: center;
    font-size: 12px;

    border-radius: 50%;
    min-width: 20px;
    height: 20px;
    top: -12px;
    right: -12px;
  }
`;

export const DivCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: "SVN-Gilroy";
  font-weight: 700;
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    flex-direction: row;
    padding: 5px;
  }
`;

export const QuestChapter = styled.div`
  background-color: ${({ theme, color }) => theme.mainButton[color]};
  border-radius: 5px;
  position: relative;
  width: auto;
  height: 52px;
  margin-right: 0.75rem;
  font-family: "SVN-Gilroy";
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 10px;
  color: #fff;
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    width: auto;
    height: auto;
  }

  @media screen and (max-width: 1600px) {
    margin-right: 5px;
  }
`;

export const FlexColumnCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    &.quest-number {
      flex-direction: row;
      min-width: 30px;
      justify-content: center;
    }
  }
  > * {
    line-height: 1.2;
  }
`;

export const NumberUnitText = styled.div`
  font-family: "SVN-Gilroy";
  font-size: 15px;
`;

export const SliderWrapper = styled.div`
  &.has-arrows {
    padding: 0 .75rem;
    @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
      padding: 0 .25rem;
    }
  }

  .slick-next {
    right: -1.5rem;
    @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
      right: -1rem;
    }
    &::before {
      font-family: "SVN-Gilroy";
      content: "›";
      font-size: 45px;
      position: absolute;
      top: -17px;
      color: ${({ theme }) => theme.textColorGray};
    }

    &.slick-disabled {
      cursor: default;
    }
  }

  .slick-prev {
    left: -1.5rem;
    @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
      left: -1rem;
    }
    &::before {
      font-family: "SVN-Gilroy";
      content: "‹";
      font-size: 45px;
      position: absolute;
      top: -17px;
      color: ${({ theme }) => theme.textColorGray};
    }

    &.slick-disabled {
      cursor: default;
    }
  }

  a:hover {
    text-decoration: none;
  }

  .slick-list {
    max-width: 1000px;
  }
`;

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.recent-training-block {
    @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
      flex-wrap: wrap;
    }
  }

  .btn-grade {
    width: auto;
    min-width: 80px;
    padding: 2px;
  }
`;

export const ActivityItemWrapper = styled.div`
  background-color: ${({ theme }) => theme.progressGroupBackgroundColor};
  border-radius: 5px;
  padding: 0.7rem 1rem;
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    padding: 1rem 0.7rem;
  }
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .home-block {
    flex: 1;
    @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
      margin-top: 1rem;
    }
  }

  a:hover {
    color: ${({ theme }) => theme.textColorBlack};
  }
  .block-control-wrapper {
    @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
      left: 50px !important;
    }
    &:before {
      @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
        left: 40px !important;
      }
    }
  }
  &:last-child {
    .block-control-wrapper {
      top: -125px !important;
      bottom: auto !important;
      @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
        left: 50px !important;
      }
      &:before {
        border-bottom: 10px solid transparent !important;
        border-top: 10px solid #0a2761 !important;
        top: auto !important;
        bottom: -20px !important;
        @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
          left: 40px !important;
        }
      }
    }
  }
`;

export const BlueDot = styled.div`
  min-width: 17px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  margin-top: 7px;
  background-color: ${({ theme }) => theme.blueDot};
  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    min-width: 12px;
    width: 12px;
    height: 12px;
  }
`;

export const NumberChapter = styled.div`
  font-family: "SVN-Gilroy";
  font-weight: 700;
  font-size: 19px;
  color: ${({ theme }) => theme.progressTextColor};

  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    font-size: 22px;
  }

  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    font-size: 14px;
  }
`;

export const NumberUnit = styled.div`
  font-family: "SVN-Gilroy";
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.numberUnitColor};

  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    font-size: 18px;
  }

  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    font-size: 14px;
  }
`;

export const LastAccess = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.lastAccessColor};
  font-weight: 300;
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    width: 100%;
    font-size: 10px;
  }
`;

export const ActivityListWrapper = styled.div`
  /* max-height: 180px; */
  overflow-y: auto;
  padding-right: 10px;
  margin-top: 0.75rem;
  max-height: calc(100% - 45px);
  height: 100%;
  @media screen and (min-height: 850px) {
    /* max-height: 205px; */
  }
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    padding-right: 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.iPadMaxWidth}px) {
    max-height: 100%;
  }
`;
export const ActivityList = styled.div``;

export const LeftWrapper = styled(WrapperItem)`
  flex: 2;
  margin-right: 15px;
  @media screen and (max-width: ${({ theme }) => theme.homepageMaxWidth}px) {
    // flex: 0 0 100%;
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    padding-bottom: 0;
  }
`;

export const RightWrapper = styled(WrapperItem)`
  flex: 1;
  @media screen and (max-width: ${({ theme }) => theme.homepageMaxWidth}px) {
    // flex: 0 0 100%;
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const ImageWrapper = styled.div`
  min-width: 46px;
  max-width: 46px;
  height: 46px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 0.75rem;

  &.none-subject__img {
    min-width: 64px;
    max-width: 64px;
    height: 64px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    &.none-subject__img {
      margin-right: 0;
    }
  }
`;

export const NumberLesson = styled.div`
  font-family: "SVN-Gilroy";
  font-weight: 500;
  font-size: 13px;
  width: 82px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    font-size: 15px;
    width: 90px;
  }
  
  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    width: 100px;
    text-align: right;
  }
`;

export const SubjectList = styled.div`
  margin-top: 0.75rem;
`;

export const SubjectItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0.5rem;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
    border-bottom: 2px solid ${({ theme }) => theme.modalBorderColor};
  }

  &.none-border {
    cursor: pointer;
    margin-bottom: 1rem;
    border-bottom: none;
    width: 33%;
  }

  @media (hover: hover) {
    &.none-border:hover .text-color {
      color: #007bff;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    &.none-border {
      margin-bottom: 0;
    }
  }
`;

export const SubSubjectItem = styled.div`
  background-color: ${({ theme }) => theme.progressGroupBackgroundColor};
  border-radius: 5px;
  padding: 0 0.7rem;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;

  button {
    width: 85px;
    margin: 0.5rem 0;
  }

  div {
    width: 30%;
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    padding: 0 1rem;

    button {
      width: 100px;
    }
  }
`;

export const FakeButton = styled.div`
  width: 30%;
`;

export const SubjectNameItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  min-width: 132px;

  &.none-subject {
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    margin-right: 1.5rem;
    &.none-subject {
      margin-right: 1.2rem;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
    justify-content: space-between;

    &.none-subject {
     flex-direction: column;
     align-items: center;
     min-width: 104px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.mobileWidthExtraSmall}px) {
    &.none-subject {
      flex-direction: column;
      align-items: center;
      min-width: 85px;
     }
  }
`;

export const SubjectNameTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    flex-direction: row;
    width: calc(100% - 60px);
    justify-content: space-between;

    &.none-subject__wrapper {
      flex-direction: column;
      width: 100%;
    }

    & .text-color {
      text-align: center;
      width: 100%;
    }
  }
`;

export const SubjectNameText = styled.div`
  ${textBlackMedium}
  font-size: 17px;
  width: 82px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    font-size: 20px;
    width: 82px;
  }
`;

export const ProgressList = styled.div`
  margin-top: 1.25rem;
  display: block;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 250px;
  padding-right: 10px;
  overflow-x: auto;
  > * {
    margin-bottom: 1rem;
  }
  > div {
    @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
      display: flex;
      width: calc(50% - 10px);
      margin-bottom: 1.5rem;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    display: flex;
    
  }
`;

export const UserImageWrapper = styled.div`
  max-width: 280px;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  margin-bottom: 0.5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.ranking {
    width: 100%;

    @media screen and (max-width: ${({ theme }) => theme.homepageMaxWidth}px) {
      width: 40%;
      margin: 0;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    max-width: 135px;
    height: 135px;
  }
`;

export const BlurWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  font-size: ${({ theme }) => theme.fontSizeBase * 0.75}px;
  color: white;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  @media screen and (min-width: ${({ theme }) =>
      theme.tabletMinWidth}px) and (max-width: ${({ theme }) =>
      theme.homepageMaxWidth}px) {
    padding: 0.5rem 1rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    font-size: 0.675rem;
    padding: 2px;
    height: auto;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const UserNameWrapper = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 0.5rem;

  span {
    font-weight: 300;
  }

  & .explain-ava {
    z-index: 6;
  }

  & .show-explain {
    display: ${({checkTooltips}) => checkTooltips ? 'block' : 'none'};
    position: absolute;
    top: 35px;
    right: 2px;
    width: auto;
    z-index: 5;
    padding: 0.5rem .5rem;
    font-size: .75rem;
    background: ${({ theme }) => theme.buttonColorBlue};
    backdrop-filter: blur(10px);
    color: white;
    text-align: left;
  }
  & .show-explain:before {
    content: '';
    position: absolute;
    top: -6px;
    right: 6px;
    width: 0; 
    height: 0; 
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid ${({ theme }) => theme.buttonColorBlue};
  }

  @media screen and (max-width: ${({ theme }) => theme.laptopWidth}px) {
    & .show-explain {
      top: 30px;
      right: 0;
    }
    & .show-explain:before {
      right: 3px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    text-align: left;
  }
`;

export const UserExpNumber = styled(TextBlackBold)`
  font-family: "SVN-Gilroy";
  margin-right: 3px;
`;

export const UserExpText = styled(TextGrayDefault)`
  font-family: "SVN-Gilroy";
`;

export const UserExpLevel = styled(TextGrayBold)`
  font-family: "SVN-Gilroy";
`;

export const CurrentPointWrapper = styled.div`
  background-color: ${({ theme }) => theme.modalBorderColor};
  border-radius: 5px;
  padding: 0.2rem 0.7rem;
  margin: 1rem auto 1rem auto;
  width: fit-content;
  display: table;

  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    margin: 1.5rem auto 2rem auto;
  }

  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    width: 100%;
  }
`;

export const CurrentPointNumber = styled.span`
  font-family: "SVN-Gilroy";
  font-weight: 700;
  color: ${({ theme }) => theme.mainButton.danger};
`;

export const ActivityNameWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 2;
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    flex: 1 0 100%;
  }
`;

export const FlexWrap = styled.div`
  display: flex;
  @media screen and (max-width: ${({ theme }) => theme.homepageMaxWidth}px) {
    flex-wrap: wrap;
  }
  @media (min-width: ${({ theme }) => theme.tabletMaxWidth + 1}px\0/) {
    &.flex-daily {
      flex: 1 0 auto;
    }
  }
`;

export const QuestLeft = styled.div`
  width: calc(25% - 15px);
  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    width: calc(30% - 15px);
  }
`;

export const QuestRight = styled.div`
  width: 75%;
  margin-left: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #f5f6fc;
  border-radius: 5px;
  padding: 7px 7px 2px 7px;

  @media screen and (max-width: 1370px) {
    margin-left: 10px;
  }
  
  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    width: 70%;
    justify-content: center;
  }
`;

export const QuestDayItem = styled.div`
  padding: 5px;
  margin-bottom: 5px;
`;

export const QuestDay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #dee0ec;
  background-color: #edeff8;
  margin: 0 auto;

  .date {
    color: #aeb3d0;
    font-size: 14px;
    line-height: 1;
  }
  .month {
    color: #aeb3d0;
    font-size: 12px;
    line-height: 1;
  }
  &.complete {
    background-color: #ffdea3;
    border: 1px solid ${({ theme }) => theme.mainButton.warning};
    .date,
    .month {
      color: #fff;
    }
  }
  
  @media screen and (max-width: 1530px) {
    width: 45px;
    height: 45px;
  }

  @media screen and (max-width: 1370px) {
    width: 40px;
    height: 40px;
    .date {
      font-size: 12px;
    }
    .month {
      font-size: 10px;
    }
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.tabletMinWidth}px) and (max-width: ${({ theme }) =>
      theme.homepageMaxWidth}px) {
    width: 50px;
    height: 50px;
  }
`;

export const CustomSelect = styled(Input)`
  cursor: pointer;
  padding: 0.3rem 0.8rem;
  border: 2px solid ${({ theme }) => theme.selectGroupBorderColor};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.selectGroupBackgroundColor};
  outline: none;
  font-size: 16px;
  font-weight: 700;

  &:focus {
    box-shadow: none;
  }
  &:hover {
    box-shadow: 0 0 3px 0 #ccc;
  }

  option {
    cursor: pointer;
  }
`;

export const QuestDescription = styled(TextGrayDefault)`
  margin-top: 0.5rem;
  font-size: 15px;
  width: 100%;
  text-align: center;
  
  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    font-size: 10.5px;
  }
`;

export const TableRankingTitle = styled(TextBlackBold)`
  margin-right: 1rem;
  flex: 1;
`;

export const QuestItemSubject = styled(TextBlackBold)`
  font-size: 13px;
  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "inherit")};
  }
`;

export const RecentTrainingTitle = styled(TextBlackDefault)`
  cursor: pointer;
  white-space: normal;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.buttonColorBlue};
  }
  @media screen and (max-width: ${({ theme }) => theme.laptopWidth}px) {
    font-size: 14px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    font-size: 12px;
  }
`;

export const HomepageRankingWrapper = styled.div`
  display: none;
  &.ranking-home-mobile {
    display: block;
  }

  @media screen and (min-width: ${({ theme }) => theme.homepageMaxWidth}px) {
    display: block;
    &.ranking-home-mobile {
      display: none;
    }
  }
`;

export const QuestTestingTitle = styled.p`
  color: ${({ theme }) => theme.buttonColorBlue};
  font-weight: 500;
`;

export const QuestionIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  cursor: pointer;
  margin-left: auto;
  
  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.buttonColorBlue};
  }

  &.explain-ava {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 22px;
  }

  @media screen and (max-width: ${({ theme }) => theme.laptopWidth}px) {
    &.explain-ava {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 22px;
    }
  }
`;

export const ExplainModalContent = styled.div`
  color: ${({ theme }) => theme.textColorBlack};
  p {
    font-weight: 700;
  }
  
  ul {
    list-style-position: inside;
  }
  
  span {
    font-weight: normal;
    font-size: 100%;
  }
`;