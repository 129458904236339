import _ from "lodash";
import {pushNotify} from "../components/Notify";
import {ERROR_CODE_BE} from "../constants/common";
import RouterPath from "../constants/router-path";
import Storage from "./storega";

// eslint-disable-next-line no-unused-vars
const SYSTEM_ERROR = 1;
const API_ERROR = 2;
export const TOKEN_TIMEOUT = 3;
export const SYSTEM_IS_MAINTAINING = 4;

class ApiError extends Error {
  constructor(error, type) {
    super();
    this.errors = error;
    this.type = type;
  }
}

async function validateResponse(response) {
  if (!response.ok) {
    let error = {};
    let type = API_ERROR;
    switch (response.status) {
      case 400:
        // eslint-disable-next-line no-case-declarations
        const err = await response.json();
        if (ERROR_CODE_BE[err.messageId]) {
          error = {
            messageId: ERROR_CODE_BE[err.messageId].code,
            message: ERROR_CODE_BE[err.messageId].message
          };
        } else {
          error = {
            messageId: err.messageId,
            message: err.message
          };
        }
        break;
      case 401:
        type = TOKEN_TIMEOUT;
        error = {
          messageId: 'TOKEN_TIMEOUT',
          message: 'アクセスが拒否されました'
        };
        break;
      case 403:
        error = {
          messageId: 'ACCESS_DENIED',
          message: 'アクセスする権利はありません。'
        };
        break;
      case 503:
        type = SYSTEM_IS_MAINTAINING;
        error = {
          messageId: 'SYSTEM_IS_MAINTAINING',
          message: 'システムのメンテナンスが実施中です。しばらくお待ちしてください。'
        };
        break;
      default:
        type = SYSTEM_ERROR;
        error = {
          messageId: 'INTERNAL_ERROR',
          message: 'INTERNAL_ERROR'
          // message: response.statusText,
        };
        break;
    }

    if (error && type !== SYSTEM_ERROR) {
      throw new ApiError(error, type);
    } else {
      // throw new ApiError(response.statusText, error, type);
      // throw Error(response.statusText);
      throw Error(error.message);
    }
  }
  return response.json();
}

function logError(error) {
  if (error instanceof ApiError) {
    if(error.errors.message !== 'SUBJECT_SUBJECT_IS_MAINTAINING') {
      pushNotify('error', error.errors.message);
    }
  }
  // throw error;
  return false;
}

const callApiNoToken = (
  url,
  config,
  onRequestFinish,
  onRequestSuccess,
  onRequestFailure
) => {
  fetch(url, config)
    .then(validateResponse)
    .then((data) => {
      if (data) {
        data && onRequestSuccess && onRequestSuccess(data);
      }
    })
    .catch((err) => {
      logError(err);
      onRequestFinish && onRequestFinish();
      onRequestFailure && onRequestFailure(err);
    });
};

/* Call API with token */
const callApi = (
  url,
  config,
  onRequestFinish,
  onRequestSuccess,
  onRequestFailure
) => {
  if (!Storage.has("USER_ACCESS_TOKEN")) {
    return;
  }
  // eslint-disable-next-line no-use-before-define
  config.headers.Mobile = detectMob();

  fetch(url, config)
    .then(validateResponse)
    .then((data) => {
      if (data) {
        onRequestSuccess && onRequestSuccess(data);
      }
    })
    .catch((err) => {
      logError(err);
      onRequestFinish && onRequestFinish();
      onRequestFailure && onRequestFailure(err);
    });
};

const params = (payload = {}) => {
  let url = '';
  const {length} = Object.keys(payload);
  if (length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [index, [key, value]] of Object.entries(payload).entries()) {
      url = `${url + key}=${value}`;
      if (index < length - 1) url += '&';
    }
  }

  return url;
};

const fakeArray = (count) => Array.from(Array(count).keys());

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
function isImage(content) {
  const values = ['.jpg', '.png', '.JPG', '.PNG'];
  return _.some(values, (el) => _.includes(content, el));
}
function getLoginUrl() {
  const isSignInSchool = Storage.get("IS_SIGN_IN_SCHOOL", false);
  if (isSignInSchool) {
    return RouterPath.SIGN_IN_SCHOOL.path
  }
  return RouterPath.SIGN_IN.path
}

function isIE() {
  const ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones */
  return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
}

function detectMob() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) return 1;
  return 0;
}

export { callApiNoToken, callApi, params, fakeArray, iOS, isImage, getLoginUrl, isIE, detectMob };
