export const userRanking = {
  100: "ビギナー",
  101: "ビギナー",
  102: "シニア",
  103: "エキスパート",
  104: "マスター",
  105: "ハイマスター"
};


export const SLIDER_CONFIG = {
  MAX_LENGTH: 3
};

export const LARGE_UNIT_TYPE = {
  ALL: 0,
  LEARNING_AND_TEST: 1,
  ONLY_TEST: 2
};

export const SUBJECT = {
  ENGLISH: "英語",
  SOCIETY: "社会",
  NATIONAL_LANGUAGE: "国語",
  SOCIETY_2: "地歴公民",
  MATH: "算数",
  MATH_2: "数学",
  PHYSICAL: "理科"
};

export const QUEST_TYPE = {
  REGULAR: 1,
  SPECIAL: 2,
}

export const ERROR_CODE_BE = {
  1000: { code: "Successfully", message: "システムのエラー[1000]" },
  1001: { code: "Invalid_User_Id", message: "システムのエラー[1001]" },
  1002: { code: "invalid_grade_id", message: "システムのエラー[1002]" },
  1003: { code: "invalid_subjectId", message: "システムのエラー[1003]" },
  1004: { code: "invalid_subSubjectId", message: "システムのエラー[1004]" },
  1005: { code: "invalid_largeUnitId", message: "システムのエラー[1005]" },
  1006: { code: "invalid_lessonId", message: "システムのエラー[1006]" },
  1007: { code: "invalid_blockId", message: "システムのエラー[1007]" },
  1008: { code: "invalid_grade_name", message: "システムのエラー[1008]" },
  1013: { code: "invalid_block_name", message: "システムのエラー[1013]" },
  1014: { code: "subject_name_MUST_NOT_NULL_or_subject_ID_MUSt_greater_0", message: "システムのエラー[1014]" },
  1015: { code: "subject_name_MUST_NOT_NULL_or_subSubject_ID_MUSt_greater_0", message: "システムのエラー[1015]" },
  1016: { code: "subject_name_MUST_NOT_NULL_or_large_unit_ID_MUSt_greater_0", message: "システムのエラー[1016]" },
  1017: { code: "subject_name_MUST_NOT_NULL_or_lesson_ID_MUSt_greater_0", message: "システムのエラー[1017]" },
  1018: { code: "subject_name_MUST_NOT_NULL_or_block_ID_MUSt_greater_0", message: "システムのエラー[1018]" },
  1020: { code: "Invalid_friend_Id", message: "システムのエラー[1020]" },
  1022: { code: "Invalid_userLearningHistoriesId", message: "システムのエラー[1022]" },
  1023: { code: "Invalid_mode", message: "システムのエラー[1023]" },
  1024: { code: "Invalid_input_data", message: "システムのエラー[1024]" },
  1025: { code: "Invalid_type", message: "システムのエラー[1025]" },
  1026: { code: "Empty_move_data", message: "システムのエラー[1026]" },
  1027: { code: "Invalid_Large_Unit_Type", message: "システムのエラー[1027]" },
  1028: { code: "INVALID_GRADE_GROUP_MSG", message: "システムのエラー[1028]" },
  1029: { code: "INVALID_QUESTION_ID_MSG", message: "システムのエラー[1029]" },
  1030: { code: "INVALID_CHARACTER_ID_MSG", message: "システムのエラー[1030]" },
  1031: { code: "INVALID_CHARACTER_NAME_MSG", message: "システムのエラー[1031]" },
  1032: { code: "INVALID_CHARACTER_RANK_ID_MSG", message: "システムのエラー[1032]" },
  1033: { code: "INVALID_CHARACTER_RANK_RANK_MSG", message: "システムのエラー[1033]" },
  1034: { code: "INVALID_CHARACTER_RANK_IMAGE_MSG", message: "システムのエラー[1034]" },
  1043: { code: "INVALID_TYPE_ORDER_MSG", message: "システムのエラー[1043]" },
  1044: {
    code: "INCORRECT_USER_OR_PASSWORD",
    message: "ID又はパスワードが違います。"
  },
  1045: { code: "USER_EXPIRED", message: "有効期限が切れました。管理者に連絡してください。" },
  1047: { code: "EMPTY_MY_VOCABULARY", message: "システムのエラー[1047]" },
  1048: { code: "EMPTY_MY_VOCABULARY_GROUP", message: "システムのエラー[1047]" },
  1049: { code: "INVALID_QUESTION_FORMAT", message: "システムのエラー[1049]" },
  1050: { code: "INVALID_ANSWER_FORMAT", message: "システムのエラー[1050]" },
  1051: { code: "REQUIRED_CORRECT_ANSWER", message: "システムのエラー[1051]" },
  1052: { code: "REQUIRED_POINT", message: "システムのエラー[1052]" },
  1053: { code: "REQUIRED_TIME_LIMT", message: "システムのエラー[1053]" },
  1054: { code: "REQUIRED_DIFFICULTLY", message: "システムのエラー[1054]" },
  1055: { code: "REQUIRED_MANDATORY", message: "システムのエラー[1055]" },
  1056: { code: "QUESTION_IN_USE", message: "システムのエラー[1056]" },
  1057: { code: "EMPTY_USER_ID", message: "システムのエラー[1057]" },
  1058: { code: "INVALID_SYSTEM_STATUS", message: "システムのエラー[1058]" },
  1059: { code: "INVALID_MY_VOCABULARY_GROUP_MSG", message: "システムのエラー[1059]" },
  1060: { code: "INVALID_MY_VOCABULARY_DETAILS_ID_MSG", message: "システムのエラー[1060]" },
  1061: { code: "INVALID_LEVEL_MSG", message: "システムのエラー[1061]" },
  1062: { code: "INVALID_BODY_REQUEST_MSG", message: "システムのエラー[1062]" },
  1063: { code: "INVALID_NICKNAME_MSG", message: "システムのエラー[1063]" },
  1064: { code: "INVALID_HISTORY_ID_MSG", message: "システムのエラー[1064]" },
  1065: { code: "NOT_SUPPORT_REVERT_THIS_ACTION", message: "システムのエラー[1065]" },
  1066: { code: "REQUIRED_WRONG_ANSWER", message: "システムのエラー[1066]" },
  1067: { code: "INVALID_CORRECT_ANSWER", message: "システムのエラー[1067]" },
  1068: { code: "EMPTY_TREE_MANAGEMENT", message: "システムのエラー[1068]" },
  1069: { code: "EMPTY_LESSON_MSG", message: "システムのエラー[1069]" },
  1070: { code: "INVALID_VOCABULARY_ID_MSG", message: "システムのエラー[1070]" },
  1071: { code: "INVALID_PUBLISH_STATUS", message: "システムのエラー[1071]" },
  1072: { code: "THE_PARENT_ITEM_IS_NOT_PUBLISHING", message: "システムのエラー[1072]" },
  1073: { code: "THE_NEW_PARENT_ITEM_IS_NOT_PUBLISHING", message: "システムのエラー[1073]" },
  2001: { code: "UserId_Not_Yet_Exist", message: "ユーザーは存在しません。" },
  2002: { code: "SUBJECT_Name_Exist_Already", message: "教科名が既に存在しました。" },
  2003: { code: "SubSubjectGradeLessons_NOT_EXIT_BY_GID", message: "システムのエラー[2003]" },
  2004: { code: "SUB_SUBJECT_Name_Exist_Already", message: "科目名が既に存在しました。" },
  2005: { code: "SubSubjectGradeLessons_NOT_EXIT_BY_GID_SID", message: "システムのエラー[2006]" },
  2006: { code: "LARGE_UNIT_Name_Exist_Already", message: "大単元名が既に存在しました。" },
  2007: { code: "SubSubjectGradeLessons_NOT_EXIT_BY_GID_SID_SSiD", message: "システムのエラー[2007]" },
  2008: { code: "LESSON_Name_Exist_Already", message: "小単元名が既に存在しました。" },
  2009: { code: "SubSubjectGradeLessons_NOT_EXIT_BY_GID_SID_SSID_LUID", message: "システムのエラー[2009]" },
  2010: { code: "Block_Name_Exist_Already", message: "ブロック名が既に存在しました。" },
  2011: { code: "SubSubjectGradeLessons_NOT_EXIT_BY_GID_SID_SSID_LUID_LEID", message: "システムのエラー[2011]" },
  2012: { code: "NOT_YET_EXIST_GRADE_ID", message: "学年が存在しません。" },
  2013: { code: "DUPLICATE_GRID_SUID", message: "システムのエラー[2013]" },
  2014: { code: "NOT_YET_EXIST_SUBJECT_ID", message: "教科が存在しません。" },
  2015: { code: "NOT_YET_EXIST_SUB_SUBJECT_ID", message: "科目が存在しません。" },
  2016: { code: "DUPLICATE_GRID_SUID_SSUID", message: "システムのエラー[2016]" },
  2017: { code: "NOT_YET_EXIST_LARGE_UNIT_ID", message: "大単元が存在しません。" },
  2018: { code: "DUPLICATE_GRID_SUID_SSUID_LUID_CODE", message: "システムのエラー[2018]" },
  2019: { code: "NOT_YET_EXIST_LESSON_ID_CODE", message: "小単元が存在しません。" },
  2020: { code: "DUPLICATE_GRID_SUID_SSUID_LUID_LEID_MSG", message: "システムのエラー[2020]" },
  2021: { code: "NOT_YET_EXIST_BLOCK_ID_MSG", message: "ブロックが存在しません[2021]" },
  2022: { code: "DUPLICATE_GRID_SUID_SSUID_LUID_LEID_BLID_MSG", message: "システムのエラー[2022]" },
  2023: { code: "EXIST_NICKNAME_MSG", message: "ニックネームが存在しました。他のニックネームを入力してください。" },
  2024: { code: "NOT_FRIEND_MSG", message: "システムのエラー[2024]" },
  2025: { code: "NOT_YET_EXIST_FRIEND_ID_MSG", message: "システムのエラー[2025]" },
  2026: { code: "NOT_YET_EXIST_USER_ID_MSG", message: "ユーザーが存在しません。" },
  2027: { code: "EXISTING_FRIEND_MSG", message: "システムのエラー[2027]" },
  2028: { code: "NOT_EXIST_USER_LEARNING_HISTORIES_BY_ULH_ID_MSG", message: "テスト履歴が存在しません。" },
  2029: { code: "TEST_EXPIRED_MSG", message: "システムのエラー[2029]" },
  2030: { code: "TEST_EXPIRED_OR_COMPLETED_MSG", message: "システムのエラー[2030]" },
  2031: { code: "NOT_EXIST_QUESTION_ID_MSG", message: "質問が存在しません。" },
  2032: { code: "NOT_YET_EXIST_QUEST_MSG", message: "システムのエラー[2032]" },
  2033: { code: "QUEST_NOT_OPEN_MSG", message: "システムのエラー[2033]" },
  2036: { code: "EXIST_MY_VOCABULARY", message: "単語が既に追加されました。" },
  2037: { code: "NOT_EXIST_SSGL_BY_BLOCK_ID_MSG", message: "ブロックが存在しません[2037]" },
  2038: { code: "Expired user", message: "有効期限が切れました。管理者に連絡してください。" },
  2039: { code: "NOT_EXIST_CHARACTER_ID_MSG", message: "キャラクターが存在しません。" },
  2040: { code: "NOT_YET_EXIST_QUESTION_ID_MSG", message: "質問が存在しません。" },
  2043: { code: "EXIST_CHARACTER_NAME_MSG", message: "キャラクター名が既に存在しました。" },
  2044: { code: "NOT_EXIST_CHARACTER_RANK_ID_MSG", message: "システムのエラー[2044]" },
  2045: { code: "EXIST_CHARACTER_RANK_MSG", message: "システムのエラー[2045]" },
  2046: { code: "NOT_YET_EXIST_MODE_MSG", message: "システムのエラー[2046]" },
  2047: { code: "MODE_IS_LOCKED_MSG", message: "システムのエラー[2047]" },
  2048: { code: "NOT_YET_EXIST_TESTING_TYPE_MSG", message: "システムのエラー[2048]" },
  2049: { code: "FORMAT_DATE_TIME_INVALID_MSG", message: "日付と時刻の形式が無効です。" },
  2050: { code: "Invalid SSO UserId", message: "システムのエラー[2050]" },
  2052: { code: "NOT_EXIST_SSGL_BY_LARGE_UNIT_ID_MSG", message: "システムのエラー[2052]" },
  2053: { code: "NOT_YET_EXIST_MY_VOCABULARY_GROUP_ID_MSG", message: "システムのエラー[2053]" },
  2054: { code: "NOT_YET_EXIST_MY_VOCABULARY_DETAILS_ID_MSG", message: "システムのエラー[2054]" },
  2055: { code: "NOT_YET_EXIST_MY_VOCABULARY_GROUP_NAME_MSG", message: "システムのエラー[2055]" },
  2056: { code: "EXIST_SSGL_BY_GRADE_ID_MSG", message: "システムのエラー[2056]" },
  2057: { code: "NOT_YET_EXIST_LEVEL_MSG", message: "システムのエラー[2057]" },
  2058: { code: "NOT_EXIST_USER_LEARNING_HISTORIES_ID_MSG", message: "システムのエラー[2058]" },
  2060: { code: "LESSON_IS_IGNORE_MSG", message: "アクセスできません。目標設定を確認してください。" },
  2061: { code: "NOT_EXIST_SSGL_BY_LESSON_ID_MSG", message: "システムのエラー[2061]" },
  2062: { code: "NOT_EXIST_VOCABULARY_ID_MSG", message: "システムのエラー[2062]" },
  2063: { code: "NOT_YET_EXIST_QUESTION_CONTENT_MSG", message: "システムのエラー[2063]" },
  2064: { code: "DUPLICATE_MY_VOCABULARY_MSG", message: "システムのエラー[2064]" },
  2068: { code: "LARGE_UNIT_NOT_PUBLISH", message: "システムのエラー[2068]" },
  2069: { code: "LESSON_NOT_PUBLISH", message: "システムのエラー[2069]" },
  2070: { code: "BLOCK_NOT_PUBLISH", message: "ブロックが存在しません[2070]" },
  1077: { code: "NICKNAME_IN_BLACK_LIST_CODE  ", message: "不適切な言葉が入力されています。ニックネームを変更してください" }
};
