import React, { memo } from "react";
import PropTypes from "prop-types";
import { MainButton } from "../Button/style";
import { NumberQuest } from "../../containers/HomePage/style";
import { MainButtonGroup } from "./style";

const ButtonGroup = memo(
  ({
    listButton,
    width,
    selected,
    handleChangeSelected,
    field,
    getId,
    classNameGroup,
    totalRequest,
    isLoading,
    ...props
  }) => (
    <>
      {!!listButton.length && (
        <MainButtonGroup className={`${classNameGroup} btn-number`}>
          {listButton.map((item, index) => (
            <MainButton
              {...props}
              color="primary"
              className={`${item.name === "リクエスト" && 'request-number'}`}
              active={index === selected}
              key={index}
              width={width}
              onClick={() =>
                getId
                  ? handleChangeSelected(item.id, index, item)
                  : handleChangeSelected(index, item.id, item)
              }
              isloading={isLoading ? 1 : 0}
            >
              {item[field] || item.name}
              {item.name === "リクエスト" && totalRequest > 0 && <NumberQuest className="number-friends__items ml-3">{totalRequest}</NumberQuest>}
            </MainButton>
          ))}
        </MainButtonGroup>
      )}
    </>
  )
);

export default ButtonGroup;

ButtonGroup.propTypes = {
  listButton: PropTypes.instanceOf(Array),
  width: PropTypes.number,
  selected: PropTypes.number,
  handleChangeSelected: PropTypes.func,
  field: PropTypes.string,
  classNameGroup: PropTypes.string
};

ButtonGroup.defaultProps = {
  listButton: [],
  width: 0,
  selected: 0,
  handleChangeSelected: () => {},
  field: "",
  classNameGroup: ""
};
