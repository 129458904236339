import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {textGrayDefault} from "../Text/style";

export const SidebarWrapper = styled.div`
  width: ${({ $toggle, theme }) => $toggle ? `${theme.sidebarWidthSmall}px` : '15%'};
  max-width: ${({theme}) => theme.sidebarWidth}px;
  min-width: ${({theme}) => theme.sidebarWidthSmall}px;
  min-height: calc(${({ isscrollinpage, theme }) => {
    if (isscrollinpage === 'true') {
      return `100% - ${theme.headerHeight}px`;
    }
    return `100vh - ${theme.headerHeight}px`;
  }});
  background-color: #fff;
  padding-top: 3rem;
  padding-bottom: 2rem;
  transition: all .3s ease;
  display: flex;
  flex-direction: column;
  
  > * {
    margin-left: 2.15rem;
  }
  margin-left: -15rem;
  @media (min-width: 768px) {
    margin-left: 0;
  }
  @media (max-width: ${({theme}) => theme.macWidth}px) {
    width: ${({ $toggle, theme }) => $toggle ? theme.sidebarWidthSmall : theme.sidebarMacWidth}px;
    min-width: ${({ $toggle, theme }) => $toggle ? theme.sidebarWidthSmall : theme.sidebarMacWidth}px;
  }
  @media (max-width: ${({theme}) => theme.tabletWidth}px) {
    padding-top: 30px;
    width: ${({ $toggle, theme }) => $toggle ? 0 : theme.sidebarMacWidth}px;
    min-width: ${({ $toggle, theme }) => $toggle ? 0 : theme.sidebarMacWidth}px;
    margin-left: 0;
  }
`;

export const SiderbarMobile = styled.div`
`;

export const SidebarLink = styled(NavLink)`
  ${textGrayDefault}
  display: flex;
  align-items: center;
  padding: 7px 0;
  cursor: pointer;
  &:hover, &.active {
    color: ${({ theme }) => theme.textActive};
    border-right: 3px solid ${({ theme }) => theme.buttonColorBlue};
    text-decoration: none;
    span {
      color: ${({ theme }) => theme.textActive};
    }
  }
  
  &.active {
    pointer-events: none;
    color: ${({ theme }) => theme.textActive};
    span {
      color: ${({ theme }) => theme.textActive};
    }
  }
  
  &:first-child {
    margin-bottom: 1rem;
  }
  
  &:not(:first-child) {
    margin-top: 1.5rem;
    @media (max-width: ${({theme}) => theme.maxWidthMobile}px) {
      margin-top: 1.1rem;
    }
  }
  
  .sidebar-icon {
    content: '';
    width: 20px;
    height: 20px;
    min-width: 20px;
    background-image: url(${({url}) => url});
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  &:hover .sidebar-icon {
    background-image: url(${({urlhover}) => urlhover});
  }
  
  &.active .sidebar-icon {
    background-image: url(${({urlhover}) => urlhover});
  }
`;

export const SidebarItem = styled.span`
  font-size: 15px;
  color: ${({ theme }) => theme.textColorGray};
  margin-left: ${({ theme }) => theme.contentPadding}px;
  text-decoration: none;
  transform: translateX(${({ $toggle }) => $toggle ? '50' : '0' }px);
  transition: .3s ease;
  white-space: nowrap;
  
  @media screen and (min-width: ${({theme}) => theme.laptopWidth}px) {
    font-size: ${({ theme }) => theme.fontSizeBase}px;
  }
`;

export const Divider = styled.div`
  padding-bottom: 0.5rem;
  border-top: 2px solid ${({ theme }) => theme.textColorLightGray};
  width: ${({ $toggle }) => $toggle ? '100' : '78' }%;
  margin-left: ${({ $toggle }) => $toggle ? '0' : '1.5rem' };
`;

export const Copyright = styled.div`
  color: ${({ theme }) => theme.copyRightColor};
  font-size: 11px;
  font-weight: 300;
  margin-top: auto;
  text-align: center;
  margin-left: 0;
  overflow: hidden;
  white-space: nowrap;
  transform: translateX(${({ $toggle }) => $toggle ? '100' : '0' }px);
  transition: .2s ease;
  
  @media screen and (min-width: ${({theme}) => theme.laptopWidth}px) {
    font-size: 12px;
  }
`;