import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  logout
} from "../../store/actions/auth";
import CheckMaintain from "./CheckMaintain";

const mapStateToProps = (state) => ({
  errorMaintain: state.auth.errorMaintain
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckMaintain));
