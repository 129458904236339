import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: 0;
}

*::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: rgba(15, 34, 0, 0.05); }
    
* { -webkit-overflow-scrolling: touch; }
    
*::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: rgba(15, 34, 0, 0.05); }
    
*::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 50px; }
    
*::-webkit-scrollbar-corner {
    background-color: rgba(15, 34, 0, 0.05); }
`;