import { TYPES } from "../actions";

const initialState = {
  status: null,
  error: null,
  loading: false,
  currentPage: 0,
  totalPage: 0,
  oftenWrongList: [],
  similarWordList: [],
  oftenWrongHistoryId: 0,
  oftenWrongInforTest: {},
  idInfor: 0,
  oftenWrongSubjectList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ACTIONS WITHOUT DATA WILL BE PLACES HERE
    case TYPES.GET_OFTEN_WRONG_REQUEST:
    case TYPES.GET_SIMILAR_WORD_REQUEST:
    case TYPES.CREATE_BLOCK_OFTEN_WRONG_REQUEST:
    case TYPES.GET_SUBJECT_OFTEN_WRONG_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true,
        similarWordList: []
      };
    case TYPES.INFOR_TEST_OFTEN_WRONG_REQUEST:
      return {
        ...state,
        loading: true,
        status: action.type
      }

    // ACTIONS WITH DATA WILL BE PLACES HERE
    case TYPES.GET_OFTEN_WRONG_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        oftenWrongList: action.data.data.oftenWrongListList,
        currentPage: action.data.data.page.currentPage,
        totalPage: action.data.data.page.totalPage
      };

    case TYPES.GET_SIMILAR_WORD_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        similarWordList: action.data.data || []
      };
    
    case TYPES.INFOR_TEST_OFTEN_WRONG_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        oftenWrongInforTest: action.data.data
      };
    
    case TYPES.CREATE_BLOCK_OFTEN_WRONG_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        idInfor: action.data.data.id
      }

    case TYPES.GET_SUBJECT_OFTEN_WRONG_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        oftenWrongSubjectList: action.data.data
      }

    // ACTION FAILED WILL BE PLACED HERE
    case TYPES.GET_OFTEN_WRONG_FAILED:
    case TYPES.GET_SIMILAR_WORD_FAILED:
    case TYPES.INFOR_TEST_OFTEN_WRONG_FAILED:
    case TYPES.CREATE_BLOCK_OFTEN_WRONG_FAILED:
    case TYPES.GET_SUBJECT_OFTEN_WRONG_FAILED:
      return {
        ...state,
        status: "",
        error: "",
        loading: false
      };

    default:
      return state;
  }
};
