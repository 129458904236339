const ENV = {
  LCL: {
    REACT_APP_API_SERVER: 'https://stg-edu-api.bappartners.com',
    MEDIA_API_SERVER: 'https://objectstorage.ap-tokyo-1.oraclecloud.com/n/nrzfrynccg3o/b/smartedu-test/o',
    IS_SHOW_ANSWER: 'true'
  },

  DEV: {
    REACT_APP_API_SERVER: 'https://dev-edu-api.bappartners.com',
    MEDIA_API_SERVER: 'https://objectstorage.ap-tokyo-1.oraclecloud.com/n/nrzfrynccg3o/b/smartedu-test/o',
    IS_SHOW_ANSWER: 'true'
  },

  STG: {
    REACT_APP_API_SERVER: 'CI_STG_REACT_APP_API_SERVER',
    MEDIA_API_SERVER: 'CI_STG_MEDIA_API_SERVER',
    IS_SHOW_ANSWER: 'CI_STG_IS_SHOW_ANSWER'
  },

  PRO: {
    REACT_APP_API_SERVER: 'https://wt-api.atwill-net.com',
    MEDIA_API_SERVER: 'https://objectstorage.us-phoenix-1.oraclecloud.com/n/sanarucloud/b/Webtra2021_OS/o',
    IS_SHOW_ANSWER: 'false'
  }
};

const config = ENV[process.env.REACT_APP_STAGE || 'LCL'];

const getEnv = (name, defaultValue) =>
  process.env[name] || config[name] || defaultValue;

export { getEnv };
