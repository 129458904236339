import React from 'react'
import {Button} from 'reactstrap';
import { useHistory } from 'react-router-dom'
import {Container} from './style';

function ContentMaintain({ home, onClose, btnText }) {
  const history = useHistory();
  return (
    <Container>
      <div><p>ただいまメンテナンス中です</p></div>
      <Button
        color='primary'
        className='mt-3'
        onClick={() => {
          home && history.push('/');
          onClose();
        }}
      > 
        {btnText || 'ホームへ戻る'}
      </Button>
    </Container>
  )
}

export default ContentMaintain
