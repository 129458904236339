import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './reducers';
import { rootPersistConfig } from './rootPersistConfig';


const composeEnhancers =
  typeof window === 'object' &&
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose;

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const createAppStore = (intitialState = {}) => {
  const store = createStore(
    persistedReducer,
    intitialState,
    composeEnhancers(
      applyMiddleware(thunkMiddleware)
    )
  );
  return { store, persistor: persistStore(store) }
};

const { store, persistor } = createAppStore();
global.store = store;

export { store, persistor };
