import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Card,
  CardTitle,
  Input,
  Progress,
  Row,
  InputGroup
} from "reactstrap";

export const BreadcrumbWrapper = styled(Breadcrumb)`
  flex: 1;
  & .breadcrumb {
    background-color: transparent;
    padding: 0;
  }
  &.result .breadcrumb {
    margin-bottom: 0;
  }
`;
export const BreadcrumbItemStyle = styled(BreadcrumbItem)`
  align-items: center;
`;

export const QuestionMap = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: solid 1px ${({ theme }) => theme.modalHeaderTextColor};
  background-color: ${({ theme, active, visited, iscorrect }) => {
    if (active) {
      return theme.buttonColorBlue;
    }
    if (visited === true && iscorrect === 1) {
      return theme.backgroundColorGreen;
    }
    if (visited === true && iscorrect === 2) {
      return theme.mainButton.danger;
    }
    return "#fff";
  }};
  color: ${({ theme, active, visited }) =>
    active || visited ? theme.textHover : theme.textColorGray};
`;

export const PauseButton = styled(Button)`
  background-color: ${({ theme }) => theme.buttonColorGreen};
`;

export const Col2 = styled.div`
  width: 20%;
  min-width: 32px;
`;

export const QuestionTitle = styled(CardTitle)`
  color: ${({ theme }) => theme.textActive};
  margin-bottom: 10px;
`;

export const Option = styled.div`
  width: 100%;
  pointer-events: ${({selected}) => selected ? 'none' : 'auto'};
  min-height: ${({ theme }) => theme.testingOptionButtonHeight}px;
  border: 2px solid #c8dbfe;
  height: 100%;
  border-color: ${({ theme, iscorrect, selected, isanswer }) => {
    if (selected && iscorrect) {
      return theme.backgroundColorGreen;
    }
    if (selected && iscorrect === false) {
      return theme.mainButton.danger;
    }
    if (isanswer) {
      return theme.backgroundColorGreen;
    }
    return theme.modalHeaderTextColor;
  }};
  border-radius: ${({ theme }) => theme.borderRoundCard}rem;
  background-color: ${({ theme, iscorrect, selected, isanswer, isColor }) => {
    if (isColor) {
      return "#c6edfc";
    }
    if (selected && iscorrect) {
      return theme.backgroundColorGreen;
    }
    if (selected && iscorrect === false) {
      return theme.mainButton.danger;
    }
    if (isanswer) {
      return theme.backgroundColorLightGreen;
    }
    return "#fff";
  }};
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: ${({ theme, selected, isanswer }) => {
    if (selected) {
      return theme.textHover;
    }
    if (isanswer) {
      return theme.backgroundColorGreen;
    }
    return theme.textPrimary;
  }};
  position: relative;
  padding: ${({ theme, isColor }) =>
      isColor ? 0 : theme.contentPadding * 1.5}px
    ${({ theme }) => theme.contentPadding}px;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      box-shadow: none;
      color: ${({ theme }) => theme.textHover}!important;
      border-color: ${({ theme, iscorrect, selected, isanswer }) => {
        if (selected && iscorrect) {
          return theme.backgroundColorGreen;
        }
        if (selected && iscorrect === false) {
          return theme.mainButton.danger;
        }
        if (isanswer) {
          return theme.backgroundColorGreen;
        }
        return theme.backgroundColorGreen;
      }};
      background-color: ${({ theme, isColor, iscorrect, selected, isanswer }) =>{
        if (isColor) {
          return "#c6edfc";
        }
        if (selected && iscorrect) {
          return theme.backgroundColorGreen;
        }
        if (selected && iscorrect === false) {
          return theme.mainButton.danger;
        }
        if (isanswer) {
          return theme.backgroundColorLightGreen;
        }
        return theme.backgroundColorGreen;
      }};
      .option-id {
        color: ${({ theme }) => theme.backgroundColorGreen};
        background-color: ${({ theme }) => theme.backgroundColorLightGreen};
      }
      .option-text {
        color: ${({ theme }) => theme.textHover};
      }
    }
  }  
  &:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 201, 168, 0.5) !important;
  }
  & .option-id {
    color: ${({ theme, iscorrect, selected }) => {
      if (selected && iscorrect === false) {
        return theme.mainButton.danger;
      }
      return theme.colorPrimary;
    }};
  }
  @media (max-width: ${({ theme }) => theme.tabletWidth}px) {
    padding-top: ${({ isColor, theme }) =>
      isColor ? 0 : theme.contentPadding * 0.9}px;
    padding-bottom: ${({ isColor, theme }) =>
      isColor ? 0 : theme.contentPadding * 0.9}px;
    min-height: 50px;
  }
`;

export const OptionWrapper = styled(Col)`
  @media (min-width: ${({ theme }) => theme.tabletWidth}px) {
    &::after {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 250;
      background-color: transparent; /* must have a color on ie10, if not :after does not exist... */
      opacity: 1;
      display: ${({ isshowanswer }) => (isshowanswer ? "block" : "none")};
    }
  }
`;

export const OptionId = styled.div`
  min-width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({
    theme,
    selected,
    isanswer,
    iscorrect,
    temporaryShow
  }) => {
    if (selected && iscorrect === false) {
      return theme.mainButtonOutline.danger;
    }
    if (selected && iscorrect === true) {
      return theme.backgroundColorLightGreen;
    }
    if (isanswer) {
      return theme.backgroundColorGreen;
    }
    if (temporaryShow) {
      return theme.backgroundColorGreen;
    }
    return theme.modalHeaderTextColor;
  }};
  color: ${({ theme, selected, isanswer, iscorrect }) => {
    if (isanswer) {
      return theme.textHover;
    }
    if (selected && iscorrect === false) {
      return theme.mainButton.danger;
    }
    if (selected && iscorrect === true) {
      return theme.backgroundColorGreen;
    }

    return theme.selectedCardColor;
  }};
  font-size: 12px;
  font-weight: 700;
`;

export const OptionText = styled.div`
  font-size: ${({ theme }) => theme.fontSizeBase * 1.2}px;
  width: 100%;
  text-align: center;
  -webkit-touch-callout: none;
    user-select: none;
`;

export const CounterTimeWrapper = styled.div`
  display: flex;
  padding-left: ${({ theme }) => theme.contentPadding * 2.3}px;
  padding-right: ${({ theme }) => theme.contentPadding * 2.3}px;
  padding-bottom: ${({ theme }) => theme.contentPadding * 6}px;
  padding-top: ${({ theme }) => theme.contentPadding}px;
  border-bottom: solid 1px ${({ theme }) => theme.textColorLightGray};
  width: 100%;

  @media (min-width: ${({ theme }) => theme.tabletWidth}px) and (max-width: ${({ theme }) => theme.largeBoostrap}px) {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
    padding-left: ${({ theme }) => theme.contentPadding}px;
    padding-right: ${({ theme }) => theme.contentPadding}px;
    border-right: solid 1px ${({ theme }) => theme.textColorLightGray};
  }

  &.show-line {
    display: flex;
  }

  &.hide-line {
    display: none;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1280px) and (orientation : landscape) {
    &.show-line {
      display: none;
    }
  
    &.hide-line {
      display: flex;
      padding-bottom: 0;
      padding-top: 0;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
`;

export const CounterNumberWrapper = styled.div`
  padding-left: ${({ theme }) => theme.contentPadding * 2.3}px;
  padding-right: ${({ theme }) => theme.contentPadding * 2.3}px;
  padding-bottom: ${({ theme }) => theme.contentPadding}px;
  padding-top: ${({ theme }) => theme.contentPadding * 6}px;

  @media (min-width: ${({ theme }) => theme.tabletWidth}px) and (max-width: ${({ theme }) => theme.largeBoostrap}px) {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: ${({ theme }) => theme.contentPadding}px;
    padding-right: ${({ theme }) => theme.contentPadding}px;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1280px) and (orientation : landscape) {
    padding-top: ${({ theme }) => theme.contentPadding * 2}px;
  }
`;

export const CardStyle = styled(Card)`
  box-shadow: 2px 2px 12px #2b53a01a;
  border-radius: 10px;
  padding: 2.5rem 1rem;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.tabletWidth}px) and (max-width: ${({ theme }) => theme.largeBoostrap}px) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    &.testing-question-card {
      padding-bottom: 2rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.tabletWidth}px) {
    padding: 15px 20px 15px 20px;
    min-height: unset;
    background-color: transparent;
    box-shadow: none;
    height: auto;
  }
`;

export const CorrectIconStyle = styled(FontAwesomeIcon)`
  background-color: ${({ theme }) => theme.backgroundColorLightGreen};
  font-size: ${({ theme }) => theme.fontSizeBase * 1.3}px;
  border-radius: 50%;
`;

export const WrongIconStyle = styled(FontAwesomeIcon)`
  background-color: ${({ theme }) => theme.mainButtonOutline.danger};
  font-size: ${({ theme }) => theme.fontSizeBase * 1.3}px;
  border-radius: 50%;
`;

export const ResultWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, iscorrect }) =>
    iscorrect ? theme.progressCheckColor : theme.mainButton.danger};
`;

export const CountDownTimerLabel = styled.p`
  font-size: ${({ theme }) => theme.fontSizeBase * 1.3}px;
  color: ${({ theme }) => theme.buttonColorBlue};
`;

export const CloseIconStyle = styled(FontAwesomeIcon)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.textColorGray};
  color: ${({ theme }) => theme.backgroundColorWhite};
  font-size: ${({ theme }) => theme.fontSizeBase * 1.5}px;
  border-radius: 50%;
  
  &:hover {
    background-color: #FD6867;
  }
  
  &:focus, &:active {
    background-color: #fff;
    path {
      fill: #FD6867;
    }
  }
`;

export const CountDownLabel = styled.p`
  color: ${({ theme }) => theme.textColorGray};
`;

export const InputStyle = styled(Input)`
  border-color: ${({ theme }) => theme.modalHeaderTextColor};
`;

export const ItemDrops = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;
  border: 3px dashed #a3caff;
  border-radius: 5px;

  margin-right: 1rem;
  margin-bottom: 1rem;
  color: #2b53a0;
  font-size: 20px;
  font-weight: 500;

  cursor: ${(props) => (props.func ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.func ? "none" : "auto")};
`;

export const ContentQuest = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  div:last-child {
    margin-right: 0;
  }
`;
export const BtnAnswer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 4px 15px #276bf01a;
  border: 2px solid #c8dbfe;
  border-radius: 5px;
  margin-right: 1rem;
  margin-bottom: 1rem;

  width: 50px;
  height: 50px;
  cursor: ${(props) => (props.func ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.func ? "none" : "auto")};
  span {
    display: ${(props) => (props.stt ? "none" : "block")};
  }
`;

export const WrapperTitle = styled.div`
  min-height: ${({CheckHieght}) => CheckHieght !== 'input' ? '37vh' : 'auto'};

  @media (min-width: ${({ theme }) => theme.tabletWidth}px) and (max-width: ${({ theme }) => theme.largeBoostrap}px) {
    min-height: ${({CheckHieght}) => CheckHieght !== 'input' ? 'calc(37vh - 130px)' : 'auto'};
  }

  @media (max-width: ${({ theme }) => theme.tabletWidth}px) {
    min-height: 20vh;
    height: auto;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1280px) and (orientation : landscape) {
    &.show-keyboard {
      display: block;
      min-height: auto;
    }
  }
`;

export const BackToHomeBtn = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 0;
  cursor: pointer;

  .sidebar-icon {
    content: "";
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-right: 10px;
    background-image: url(${({ url }) => url});
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

export const TestingBreadCrumLink = styled.span`
  color: ${({ theme }) => theme.textActive};
  &:hover {
    color: ${({ theme }) => theme.buttonColorBlue};
    text-decoration: underline;
  }
`;

export const BtnFooter = styled(Row)`
  @media (max-width: ${({ theme }) => theme.tabletWidth}px) {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 6px 50px;
    background-color: #fff;
    z-index: 1000;
    box-shadow: 4px -2px 6px #00000029;

    .next-btn,
    .submit-btn {
      border-radius: 0;
      height: 42px;
      width: 100%;
      border-radius: 40px;
      margin-top: 6px;
    }

    .comment-mobile-btn {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #757ca84e;
      border: none;
      position: absolute;
      right: 25px;
      top: -58px;
      padding: 0px;
      overflow: visible;
      z-index: 1000;
    }
  }
`;
export const TestingWrapper = styled.div`
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.tabletWidth}px) and (max-width: ${({ theme }) => theme.largeBoostrap}px) {
    .testing-question-col {
    }
    .question-map-col {
      margin-bottom: 12px;
    }
  }

  @media (max-width: ${({ theme }) => theme.tabletWidth}px) {
    padding-left: 5px;
    padding-right: 5px;
    .mt-content {
      padding-bottom: 0;
    }
  }
`;
export const TestingContentRow = styled(Row)`
  height: 100%;
  @media (min-width: ${({ theme }) => theme.tabletWidth}px) and (max-width: ${({ theme }) => theme.largeBoostrap}px) {
    flex-direction: column-reverse;
  }
`;

export const QuestionWrapper = styled.div`
  width: 75%;
  @media (max-width: ${({ theme }) => theme.tabletWidth}px) {
    width: 100%;
    ${({ isshowanswer }) => isshowanswer === 'true' && 'padding-bottom: 108px;'}
  }

  & .show-btn {
    display: flex;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1280px) and (orientation : landscape) {
    & .show-btn {
      display: none;
    }
  }
`;

export const TimerLineWrapper = styled(Row)`
  color: #0a2761;
  margin-bottom: 10px;
`;

export const TestingProgressLine = styled(Progress)`
  margin-left: 10px;
  margin-right: 10px;
  height: 8px;
  border-radius: 4px;
  background: #f8f9fd 0% 0% no-repeat padding-box;
  
  .progress-bar {
    background: #276bf0 0% 0% no-repeat padding-box;
    border-radius: 4px;
    transition: ${({ transition }) => transition};
  }
`;

export const MobileRowStatus = styled(Row)`
  @media (max-width: ${({ theme }) => theme.tabletWidth}px) {
    width: 100%;
    span {
      font-size: 18px;
    }
  }
`;
export const InputGroupQuestion = styled(InputGroup)`
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3rem;

  input {
    padding-right: 38px;
    border-radius: .25rem!important;
  }
  .input-group-prepend {
    button {
      z-index: 4;
      padding: 8px;
      background: inherit;
      border: none;
      position: absolute;
      right: 0px;
      height: 100%;
      display: flex;
      align-items: center;
      img {
        height: 20px;
        transform: rotate(180deg);
      }
    }
  }

  & .show-keyboard {
    display: none;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1280px) and (orientation : landscape)  {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 1rem;

    & .show-keyboard {
      display: flex;
    }
  }
`;

export const FooterModalStop = styled.div`
  width: 75%;
  @media (max-width: 380px) {
    width: 100%;
  }
`;

export const GroupResultStt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .answer-stt {
    margin-top: 1rem;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1280px) and (orientation : landscape) {
    flex-direction: row;

    & .answer-stt {
      margin-top: 0;
      margin-left: 1rem;
    }
  }
`
export const NetWorkErr = styled.div`
  display: flex;
  align-items: center;
  background-color: #F8F9FD;
  padding: 8px 16px;
  font-weight: bold;
  margin-bottom: 2rem;
  min-width: 75%;
  font-size: 14px;
  border-radius: 6px;
  img {
    margin-right: 15px;
  }

  .network-text {
    display: flex;
    flex-wrap: wrap;
  }
  span {
    color: #4A90E2;
  }
`