import { TYPES } from "../actions";

const TOTAL_RANKING_SIZE = 50;
const initialState = {
  status: null,
  grades: [],
  rankings: [],
  currentUserRanking: {},
  rankingPageSize: TOTAL_RANKING_SIZE,
  rankingPageNo: 0,
  rankingTotal: 0,
  userRankingProfile: {},
  startDate: "",
  endDate: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ACTIONS WITH DATA WILL BE PLACES HERE
    case TYPES.GET_USER_GRADES_SUCCESS:
      const grades = action.data.data.data;
      return {
        ...state,
        status: action.type,
        grades
      };

    case TYPES.GET_USER_RANKING_REQUEST:
      return {
        ...state,
        status: action.type
      };

    case TYPES.RESET_RANKING_REQUEST:
      return {
        ...initialState
      };
      
    case TYPES.GET_USER_RANKING_SUCCESS:
      const rankingData = action.data.data.data;

      const rankingDataTemp =
        rankingData.rankings && rankingData.rankings.length > 0
          ? rankingData.rankings
          : initialState.rankings;
      return {
        ...state,
        rankings: rankingDataTemp,
        currentUserRanking: rankingData.currentRanking,
        rankingPageNo: rankingData.page.currentPage,
        rankingTotal: rankingData.page.totalPage,
        userRankingProfile: rankingData.profileDTO,
        startDate: rankingData.startDate,
        endDate: rankingData.endDate,
        status: action.type
      };

    case TYPES.GET_USER_RANKING_FAILED:
      return {
        ...state,
        rankings: initialState.rankings,
        currentUserRanking: initialState.currentUserRanking,
        rankingPageNo: initialState.rankingPageNo,
        rankingTotal: initialState.rankingTotal
      };
    default:
      return state;
  }
};
