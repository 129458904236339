import React, {useCallback} from 'react';
import {FormGroup, Input, Label} from "reactstrap";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import {TextBlackBold, TextGrayBold} from "../Text/style";
import {MainButton} from "../Button/style";
import {
  CustomOption,
  CustomSelect
} from "../../containers/OftenWrong/style";
import useWindowSize from "../../hooks/useWindowSize";
import {ChangeGradeWrapper, SubSubjectWrapper} from "./style";

const HeaderMenu = ({
  gradeList,
  headerSubjectList,
  headerSubSubjectList,
  selectedGrade,
  handleChangeSelectedGrade,
  selectedSubject,
  handleChangeSelectedSubject,
  selectedSubSubject,
  handleSelectSubSubject,
  handleChangeGrade,
  number,
  type
}) => {
  const size = useWindowSize();
  const RenderOption = useCallback(() => {
    return(
      <CustomSelect
        type="select"
        name="gradeList"
        id="gradeList"
        onChange={(e) => handleChangeSelectedGrade('', e)}
        defaultValue={selectedGrade + 1}
      >
        {gradeList.map((gradesitem, index) => (
          <CustomOption
            key={index}
            value={gradesitem.groupCode}
            // selected={index === selectedGrade}
            >
            {gradesitem.groupName}
          </CustomOption>
        ))}
      </CustomSelect>)
  },[selectedGrade, gradeList, handleChangeSelectedGrade])

  return (
    <ChangeGradeWrapper>
      {size.width > 480 && 
      <ButtonGroup
        listButton={gradeList}
        selected={selectedGrade}
        handleChangeSelected={handleChangeSelectedGrade}
        width={1}
        field="groupName"
      />}
      {size.width < 480 && <RenderOption />}
     
      <ButtonGroup
        listButton={headerSubjectList}
        selected={selectedSubject}
        handleChangeSelected={handleChangeSelectedSubject}
        field="subjectName"
      />
      {selectedGrade === 0 
      && <SubSubjectWrapper isWrap={headerSubSubjectList !== undefined && !!headerSubSubjectList.length && headerSubSubjectList?.length > 3}>
        {headerSubSubjectList !== undefined && !!headerSubSubjectList.length &&
          headerSubSubjectList.map((item) => (
          <FormGroup key={item.id} check>
            <Label check>
              <Input
                type="radio"
                name={`radio1${number}`}
                checked={selectedSubSubject === item.id}
                onChange={(e) => handleSelectSubSubject(e)}
                value={item.id}
                disabled={item.totalLesson <= 0}
              />{" "}
              {item.totalLesson > 0 ?
                <TextBlackBold>{item.name}</TextBlackBold> :
                <TextGrayBold>{item.name}</TextGrayBold>
              }
            </Label>
          </FormGroup>
        ))}
      </SubSubjectWrapper>}
      <div className="mt-4 text-center">
        <MainButton
          color="primary"
          className="modal-btn"
          size="sm"
            onClick={() => handleChangeGrade(type)}
            disabled={(headerSubSubjectList !== undefined && !headerSubSubjectList.length) || !selectedSubSubject}
          >
          決定
        </MainButton>
      </div>
    </ChangeGradeWrapper>
  )
};

export default React.memo(HeaderMenu);