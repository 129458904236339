import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "reactstrap";
import { textBlackBold, textGrayDefault } from "../Text/style";

const flexBox = css`
  display: flex;
  align-items: center;
`;

export const SidebarRightWrapper = styled.div`
  width: ${({ theme }) => theme.sidebarRightWidth}px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  transform: translateX(${({ theme }) => theme.sidebarRightWidth}px);
  padding: 2rem 1.5rem;

  &.active {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }

  @media screen and (min-width: ${({ theme }) =>theme.mobileWidthExtraSmall}px) and (max-width: ${({ theme }) =>theme.maxWidthMobile}px) {
    width: 0;
    &.active {
      width: 100%;
    }
  }
`;

export const Overlay = styled.div`
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 0;
  transition: all 1s ease-in-out;
  top: 0;

  &.active {
    display: block;
    opacity: 1;
  }
`;

export const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  width: 20px !important;
  cursor: pointer;
  color: ${({ color }) => color || "inherit"};
`;

export const FlexBetween = styled.div`
  ${flexBox}
  justify-content: space-between;
`;

export const CloseWrapper = styled.div`
  ${flexBox}
  cursor: pointer;
  img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
  &:hover .close-btn-label {
    color: ${({ theme }) => theme.textActive};
  }
`;

export const UserProfileImage = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 3px solid ${({ theme }) => theme.selectGroupBorderColor};
  overflow: hidden;
  margin: 1rem auto 0.5rem auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SettingGroup = styled.div`
  padding: 0 1rem;
`;

export const SettingIcon = styled.img`
  width: 17px;
  height: 20px;
  margin-right: 0.75rem;
  margin-left: 0.5rem;
  object-fit: contain;
`;

export const ProfileSettings = styled.div`
  ${textGrayDefault}
  padding-bottom: .5rem;
  border-bottom: 2px solid #f3f6ff;
  margin-bottom: 0.5rem;
`;

export const SettingItem = styled(FlexBetween)`
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #f3f6ff;
  }

  div {
    color: #757ca8;
  }
`;

export const TextCenter = styled.div`
  text-align: center;
  min-height: 25px;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    color: ${({ theme }) => theme.textColorBlack};
  }
`;

export const EditProfileWrapper = styled.div`
  margin-top: 3rem;
  padding: 0 1rem;
  text-align: center;
`;

export const SettingTargetWrapper = styled.div`
  margin-top: 2rem;
  padding: 0 0.75rem;
  @media screen and (min-width: ${({ theme }) =>theme.mobileWidthExtraSmall}px) and (max-width: ${({ theme }) =>theme.maxWidthMobile}px) {
    padding: 0 0;
  }
`;

export const CenterLine = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.progressGroupBackgroundColor};
  margin: 0 5px;
  flex: 1;
`;

export const CustomSelect = styled(Input)`
  width: 120px;
  cursor: pointer;
  ${textBlackBold}

  option {
    ${textBlackBold}
  }

  &:focus,
  &:active {
    box-shadow: none;
  }
`;

export const ChapterList = styled.div`
  max-height: calc(100vh - 370px);
  overflow-y: auto;
  margin-bottom: 1.5rem;
  @media screen and (min-width: ${({ theme }) =>theme.mobileWidthExtraSmall}px) 
    and (max-width: ${({ theme }) =>theme.maxWidthMobile}px) {
      max-height: calc(100vh - 450px);
  }
`;

export const ChapterItemWrapper = styled.div`
  background-color: ${({ theme }) => theme.tableBackgroundColor};
  border-radius: 5px;
  padding: 0.5rem 0.75rem;

  .custom-checkbox {
    label {
      cursor: pointer;
      font-size: 14px;
      font-weight: 300;
      padding-top: 2px;
    }

    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none;
    }
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const FlexLesson = styled(FlexBetween)`
  flex-wrap: wrap;
`;

export const LogoutWrapper = styled.div`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.textActive};
  }
`;
