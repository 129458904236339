import { TYPES } from "../actions";

const TOTAL_LESSON_SIZE = 20;
const TOTAL_UNIT_SIZE = 20;
const TOTAL_BLOCK_SIZE = 20;

const initialState = {
  status: null,
  lessonList: [],
  lessonPageSize: TOTAL_LESSON_SIZE,
  lessonPageNo: 0,
  lessonTotal: 0,
  unitList: [],
  unitPageSize: TOTAL_UNIT_SIZE,
  unitPageNo: 0,
  unitTotal: 0,
  blockList: [],
  blockPageSize: TOTAL_BLOCK_SIZE,
  blockPageNo: 0,
  blockTotal: 0,
  testingInfo: {},
  loadingLesson: false,
  loadingBlock: false,
  stateLessonId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LARGE_UNIT_REQUEST:
      return {
        ...state,
        status: action.type,
        loadingLesson: true
      };
    case TYPES.GET_LARGE_BLOCK_REQUEST:
      return {
        ...state,
        status: action.type,
        loadingBlock: !!(parseFloat(state.stateLessonId) !== parseFloat(action.data.lessonId) || state.stateLessonId === null)
      };  

    case TYPES.RESET_LESSONS_SUCCESS:
      return {
        ...state,
        lessonPageNo: 0,
        lessonList: [],
        lessonTotal: 0
      };
    case TYPES.GET_LARGE_LESSON_SUCCESS:
      const lessons = action.data.data.data;
      return {
        ...state,
        lessonPageNo: lessons.page.currentPage,
        lessonList:
          lessons.page.currentPage === 0
            ? lessons.largeUnitList
            : [...state.lessonList, ...lessons.largeUnitList],
        lessonTotal: lessons.page.totalPage
      };
    
    case TYPES.GET_LARGE_UNIT_SUCCESS:
      const units = action.data.data.data;
      return {
        ...state,
        unitPageNo: units.page.currentPage,
        unitList:
          units.page.currentPage === 0
            ? units.lessonList
            : [...state.unitList, ...units.lessonList],
        unitTotal: units.page.totalPage,
        loadingLesson: false
      };
    
    case TYPES.GET_LARGE_BLOCK_SUCCESS:
      const blocks = action.data.data.data;

      return {
        ...state,
        status: action.type,
        blockPageNo: blocks.page.currentPage,
        blockList:
          blocks.page.currentPage === 0
            ? blocks.blockList
            : [...state.blockList, ...blocks.blockList],
        blockTotal: blocks.page.totalPage,
        loadingBlock: false,
        stateLessonId: blocks.lesson?.id
      };
    
    case TYPES.CHANGE_GRADE_LESSONS_REQUEST:
      return {
        ...state,
        unitList: [],
        blockList: []
      };
    
    case TYPES.RESET_DATA_REQUEST:
      return {
        ...initialState
      };
    
    case TYPES.GET_TESTING_INFO_SUCCESS:
      const testingInfo = action.data.data.data;
      return {
        ...state,
        testingInfo: { ...testingInfo }
      };

    case TYPES.GET_LARGE_UNIT_FAILED:
      return {
        ...state,
        status: "",
        loadingLesson: false
      };
    
    case TYPES.GET_LARGE_BLOCK_FAILED:
      return {
        ...state,
        status: "",
        loadingBlock: false
      };
    
    default:
      return state;
  }
};
