import {TYPES} from ".";

const updateStatus = (status, statusInfo) => ({
  type: status,
  data: statusInfo
});

const toggleSidebar = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.TOGGLE_SIDEBAR_REQUEST));
  };
};

const toggleSidebarRight = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.TOGGLE_SIDEBAR_RIGHT_REQUEST));
  };
};

const setEditProfile = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.EDIT_PROFILE_REQUEST));
  };
};

const setSettingTarget = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SETTING_TARGET_REQUEST));
  };
};

const showModalGrade = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SHOW_MODAL_GRADE_REQUEST));
  };
};

const showModalGradeNavigation = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SHOW_MODAL_GRADE_NAVIGATION_REQUEST));
  };
};

export {
  toggleSidebar,
  toggleSidebarRight,
  setEditProfile,
  setSettingTarget,
  showModalGrade,
  showModalGradeNavigation
};
