/* eslint-disable no-empty */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { faAngleLeft, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import * as yup from "yup";
import { FormFeedback, FormGroup, Input } from "reactstrap";
import _ from "lodash";
import CloseButton from "../../static/images/CloseButton.png";
import { TextBlackBold, TextGrayDefault } from "../Text/style";
import useMyForm from "../../hooks/useMyForm";
import IconAva from "../../static/images/userImage.png";
import { CustomForm } from "../../containers/Learning/style";
import { TYPES } from "../../store/actions";
import Storage from "../../utils/storega";
import RouterPath from "../../constants/router-path";
import { getEnv } from "../../configs/env";
import { pushNotify } from "../Notify";
import { getLoginUrl } from "../../utils/commonUtils";
import {
  CloseWrapper,
  CustomIcon,
  FlexBetween,
  Overlay,
  SidebarRightWrapper,
  TextCenter,
  LogoutWrapper
} from "./style";
import Profile from "./Profile/Profile";
import EditProfile from "./Profile/EditProfile";
import SettingTarget from "./SettingTarget/SettingTarget";

const SidebarRight = ({
  sidebarRight,
  toggleSidebarRight,
  isEditProfile,
  setEditProfile,
  updateNickName,
  loadingSettings,
  isSettingTarget,
  setSettingTarget,
  userSettingTarget,
  getTargetSubjects,
  targetSubjectList,
  getTargetSubSubjects,
  targetSubSubjectList,
  getLargeUnitAndLesson,
  largeUnitAndLessonList,
  resetLargeUnitAndLesson,
  statusSetting,
  currentGrade,
  logout,
  gradeList
}) => {
  const [currentSubject, setCurrentSubject] = useState({
    index: 0,
    id: ""
  });
  const [chapterList, setChapterList] = useState([]);
  const [currentSubSubject, setCurrentSubSubject] = useState(null);
  const history = useHistory();
  const { character, nickName, email, userName } = Storage.get("USER_PROFILE", {});
  const [settingGroupCode, setSettingGroupCode] = useState(null);
  const [checkSubmit, setCheckSubmit] = useState(true);

  useEffect(() => {
    if (!settingGroupCode && currentGrade?.groupCode && history.location.pathname !== RouterPath.SELECT_CHARRACTOR.path) {
      setSettingGroupCode(currentGrade.groupCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGrade.groupCode, getTargetSubjects]);

  useEffect(() => {
    if(!settingGroupCode) return;

    if(sidebarRight) {
      getTargetSubjects({
        params: {
          gradeGroupCode: settingGroupCode
        }
      });
    } else {
      setChapterList([]);
      setCheckSubmit(true);
    }
    setCurrentSubject({
      index: 0,
      id: ""
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[settingGroupCode, sidebarRight]);

  

  const handleSetChapterList = useCallback((value) => {
    const getNew = value.map((item) => {
      const lessonList = [];
      let allChecked = true;
      item.lessonDTOS.forEach((lesson) => {
        if (!lesson.check) allChecked = false;
        lessonList.push({
          ...lesson,
          isChecked: !!lesson.check
        });
      });
      return {
        ...item,
        allChecked,
        lessons: lessonList
      };
    });
    return getNew;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if (!largeUnitAndLessonList.length) setChapterList([]);
    const newList = handleSetChapterList(largeUnitAndLessonList);
    setChapterList(newList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [largeUnitAndLessonList]);

  useEffect(() => {
    if (statusSetting === TYPES.USER_SETTING_TARGET_SUCCESS && sidebarRight) {
      pushNotify("success", "目標設定を変更しました");
      toggleSidebarRight();
      setCheckSubmit(true);
      getTargetSubjects({
        params: {
          gradeGroupCode: settingGroupCode
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSetting, setEditProfile, toggleSidebarRight]);

  useEffect(() => {
    if (statusSetting === TYPES.GET_TARGET_SUBJECTS_SUCCESS) {
      if (!targetSubjectList.length) return;
      getTargetSubSubjects({
        params: {
          gradeGroupCode: settingGroupCode || currentGrade?.groupCode,
          subjectId: targetSubjectList[0]?.id
        }
      });
    }
    if (statusSetting === TYPES.GET_TARGET_SUB_SUBJECTS_SUCCESS) {
      if (!targetSubSubjectList.length) {
        resetLargeUnitAndLesson();
        return;
      }
      setCurrentSubSubject(targetSubSubjectList[0]?.id);
      getLargeUnitAndLesson({
        params: {
          gradeGroupCode: settingGroupCode || currentGrade?.groupCode,
          subjectId: currentSubject.id || targetSubjectList[0]?.id,
          subSubjectId: targetSubSubjectList[0]?.id
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSetting, settingGroupCode]);

  useEffect(() => {
    setCurrentSubject({
      index: 0,
      id: targetSubjectList[0]?.id,
      code: targetSubjectList[0]?.code
    });
  },[targetSubjectList])

  const handleChangeSubject = useCallback(
    (subjectId, index, item) => {
      if (loadingSettings) return;
      setCurrentSubject({
        index,
        id: subjectId,
        code: item?.code
      });
      getTargetSubSubjects({ 
        params: {
          gradeGroupCode: settingGroupCode,
          subjectId
        }
      });
    },
    [getTargetSubSubjects, loadingSettings, settingGroupCode]
  );

  const handleChangeSubSubject = useCallback(
    (e) => {
      if (loadingSettings) return;
      setCurrentSubSubject(Number(e.target.value));
      getLargeUnitAndLesson({
        params: {
          gradeGroupCode: settingGroupCode || currentGrade?.groupCode,
          subjectId: currentSubject.id || targetSubjectList[0]?.id,
          subSubjectId: e.target.value
        }
      });
    },
    [
      getLargeUnitAndLesson,
      loadingSettings,
      currentGrade.groupCode,
      currentSubject.id,
      targetSubjectList,
      settingGroupCode
    ]
  );

 const handleCompareChapter = useCallback((value1, value2)  =>{
  return _.differenceWith(value1, value2, _.isEqual);
 },[])

  const handleCheckAll = useCallback(
    async ({ e, index, isCheckAll, id }) => {
      const { checked } = e.target;
      const newList = _.cloneDeep(chapterList);
      const oldList = await handleSetChapterList(largeUnitAndLessonList);

      if (isCheckAll) {
        newList[index].allChecked = checked;
        newList[index].lessons = await newList[index].lessons.map((lesson) => ({
          ...lesson,
          isChecked: checked
        }));

        if(handleCompareChapter(oldList, newList).length > 0) {
          setChapterList([...newList]);
          setCheckSubmit(false)
        } else {
          setChapterList([...newList]);
          setCheckSubmit(true);
        }
      } else {
        newList[index].lessons = newList[index].lessons.map((lesson) =>
          lesson.id === id ? { ...lesson, isChecked: checked } : lesson
        );
        newList[index].allChecked = newList[index].lessons.every(
          (item) => item.isChecked
        );

        if(handleCompareChapter(oldList, newList).length > 0) {
          setChapterList([...newList]);
          setCheckSubmit(false)
        } else {
          setChapterList([...newList]);
          setCheckSubmit(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chapterList, largeUnitAndLessonList]
  );

  const handleUpdateNickName = useCallback(
    (data) => {
      const formData = { ...data };
      updateNickName(formData);
    },
    [updateNickName]
  );

  const handleSettingTarget = useCallback(() => {
    const lessonsIds = [];
    chapterList.map((chapter) =>
      chapter.lessons.forEach((lesson) => {
        lessonsIds.push({
          lessonsId: lesson.id,
          removed: !!lesson.isChecked
        });
      })
    );

    if (!lessonsIds.length) {
      return;
    }

    userSettingTarget({lessonsIds, idx: currentSubject});
  }, [chapterList, userSettingTarget, currentSubject]);

  const handleLogout = () => {
    logout();
    history.push(getLoginUrl());
  };

  const schema = useMemo(
    () =>
      yup.object().shape({
        nickName: yup.string().required("ニックネームが必須項目です。")
      }),
    []
  );
  const { register, errors, onSubmit } = useMyForm({
    validationSchema: schema,
    api: (formData) => handleUpdateNickName(formData)
  });

  const changeNameForm = useMemo(
    () => (
      <CustomForm onSubmit={onSubmit} noValidate>
        <FormGroup>
          <Input
            invalid={!!errors.nickName}
            id="create"
            className="input-transparent pl-2"
            type="nickName"
            required
            innerRef={register}
            name="nickName"
            defaultValue={nickName}
            readOnly
          />
          <FormFeedback>
            {errors.nickName && errors.nickName.message}
          </FormFeedback>
        </FormGroup>
      </CustomForm>
    ),
    [onSubmit, errors, register, nickName]
  );

  return (
    <>
      <SidebarRightWrapper className={`${sidebarRight ? "active" : ""}`}>
        {isEditProfile && (
          <>
            <TextCenter>
              <TextBlackBold fontSize={14}>プロフィール</TextBlackBold>
              <CustomIcon icon={faAngleLeft} onClick={setEditProfile} />
            </TextCenter>
            <EditProfile changeNameForm={changeNameForm} />
          </>
        )}

        {isSettingTarget && (
          <>
            <TextCenter>
              <TextBlackBold fontSize={14}>目標設定</TextBlackBold>
              <CustomIcon icon={faAngleLeft} onClick={setSettingTarget} />
            </TextCenter>
            <SettingTarget
              currentSubject={currentSubject}
              handleChangeSubject={handleChangeSubject}
              chapters={chapterList}
              setChapters={setChapterList}
              handleCheckAll={handleCheckAll}
              handleSettingTarget={handleSettingTarget}
              loadingSettings={loadingSettings}
              targetSubjectList={targetSubjectList}
              targetSubSubjectList={targetSubSubjectList}
              handleChangeSubSubject={handleChangeSubSubject}
              currentSubSubject={currentSubSubject}
              gradeList={gradeList}
              choiceGrade={(e) => setSettingGroupCode(Number(e.target.value))}
              groupCode={settingGroupCode}
              gradeCurrent={currentGrade?.groupCode}
              sttSubmit={checkSubmit}
            />
          </>
        )}

        {!isEditProfile && !isSettingTarget && (
          <>
            <FlexBetween>
              <CloseWrapper onClick={toggleSidebarRight}>
                <img src={CloseButton} alt="Close" />
                <TextGrayDefault fontSize={14} className="close-btn-label">
                  閉じる
                </TextGrayDefault>
              </CloseWrapper>
              <LogoutWrapper onClick={() => handleLogout()}>
                ログアウト
                <CustomIcon
                  icon={faSignOutAlt}
                  className="ml-2"
                  tooltip="Logout"
                />
              </LogoutWrapper>
            </FlexBetween>
            <Profile
              setEditProfile={setEditProfile}
              setSettingTarget={setSettingTarget}
              nickName={nickName}
              userName={userName}
              email={email}
              src={
                (character &&
                  `${getEnv("MEDIA_API_SERVER")}/${character.image}`) ||
                IconAva
              }
            />
          </>
        )}
      </SidebarRightWrapper>

      <Overlay className={`${sidebarRight ? "active" : ""}`} onClick={toggleSidebarRight}/>
    </>
  );
};

export default React.memo(SidebarRight);

SidebarRight.propTypes = {
  sidebarRight: PropTypes.bool,
  toggleSidebarRight: PropTypes.func,
  isEditProfile: PropTypes.bool,
  setEditProfile: PropTypes.func,
  updateNickName: PropTypes.func,
  loadingSettings: PropTypes.bool,
  isSettingTarget: PropTypes.bool,
  setSettingTarget: PropTypes.func,
  userSettingTarget: PropTypes.func,
  getTargetSubjects: PropTypes.func,
  getTargetSubSubjects: PropTypes.func,
  getLargeUnitAndLesson: PropTypes.func,
  resetLargeUnitAndLesson: PropTypes.func,
  logout: PropTypes.func,
  targetSubjectList: PropTypes.instanceOf(Array),
  currentGrade: PropTypes.instanceOf(Object)
};

SidebarRight.defaultProps = {
  sidebarRight: false,
  toggleSidebarRight: () => {},
  isEditProfile: false,
  setEditProfile: () => {},
  updateNickName: () => {},
  loadingSettings: false,
  isSettingTarget: false,
  setSettingTarget: () => {},
  userSettingTarget: () => {},
  getTargetSubjects: () => {},
  getTargetSubSubjects: () => {},
  getLargeUnitAndLesson: () => {},
  resetLargeUnitAndLesson: () => {},
  logout: () => {},
  targetSubjectList: [],
  currentGrade: {}
};
