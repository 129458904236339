import Storage from "../../utils/storega";
import { TYPES } from "../actions";

const initialState = {
  status: null,
  error: null,
  loading: false,
  userRanking: {
    exp: 0,
    expLoan: 0,
    level: 0,
    progress: 0
  },
  questData: {},
  blockList: [],
  subjectList: [],
  headerSubjectList: [],
  subSubjectList: [],
  headerSubSubjectList: [],
  gradeList: [],
  currentGrade: {},
  userProfile: {},
  currentConditionMessageCharacter: 0,
  currentCharacterMessage: "",
  totalQuestQuestion: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ACTIONS WITHOUT DATA WILL BE PLACES HERE
    case TYPES.GET_RANKING_REQUEST:
    case TYPES.GET_QUESTS_REQUEST:
    case TYPES.GET_RECENT_ACTIVITIES_REQUEST:
    case TYPES.GET_SUBJECT_HOMEPAGE_REQUEST:
    case TYPES.GET_GRADE_REQUEST:
    case TYPES.UPDATE_USER_INFO_REQUEST:
      const data = action.payload;
      const isLevelUpResult = action.isLevelUp;
      if (data) {
        const newUserProfile = state.userProfile;
        if (isLevelUpResult) {
          const character = data.characterDTO;
          if (character && newUserProfile.character) {
            newUserProfile.character.image = character.image;
            newUserProfile.character.name = character.name;
          } 
        }
        newUserProfile.level = data.newLevel;
        newUserProfile.exp = data.newExp;
        newUserProfile.expLoan = data.newExpLoan;
        newUserProfile.progress = data.progress;
        Storage.set("USER_PROFILE", newUserProfile);
        
        return {
          ...state,
          newUserProfile,
          userRanking: {
            ...state.userRanking,
            exp: data.newExp,
            expLoan: data.newExpLoan,
            level: data.newLevel,
            progress: data.progress
          }
        };
      }
      return {
        ...state
      };
    case TYPES.GET_USER_PROFILE_REQUEST:
    case TYPES.GET_SUBJECT_HEADER_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true
      };

    // ACTIONS WITH DATA WILL BE PLACES HERE
    case TYPES.GET_RANKING_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        userRanking: action.data.data
      };

    case TYPES.GET_QUESTS_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        questData: action.data.data
      };

    case TYPES.GET_RECENT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        blockList: action.data.data
      };

    case TYPES.GET_SUBJECT_HOMEPAGE_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        subjectList: action.data.data,
        subSubjectList: action.data.data[0]?.subSubjectList
      };

    case TYPES.GET_GRADE_SUCCESS:
      if (!Storage.has("USER_SELECT_GRADE"))
        Storage.set("USER_SELECT_GRADE", {
          grade: {
            ...action.data.data[0],
            gradeLC: 0
          },
          subject: {
            subjectLC: 0
          },
          subSubject: {
            subSubjectLC: 0
          }
        });
      return {
        ...state,
        status: action.type,
        loading: false,
        gradeList: action.data.data,
        currentGrade: action.data.data[0]
      };

    case TYPES.GET_USER_PROFILE_SUCCESS:
      Storage.set("USER_PROFILE", action.data.data);
      const userProfile = action.data.data;
      return {
        ...state,
        status: action.type,
        loading: false,
        userProfile,
        currentCharacterMessage:
          userProfile.character?.characterMessage.messageCharacter,
        currentConditionMessageCharacter:
          userProfile.character?.characterMessage.conditionMessageCharacter
      };

    case TYPES.GET_MESSAGE_CHARACTER_SUCCESS:
      const messageCharacter = action.data.data;
      return {
        ...state,
        currentCharacterMessage: messageCharacter.messageCharacter,
        currentConditionMessageCharacter:
          messageCharacter.conditionMessageCharacter
      };

    case TYPES.CHANGE_GRADE_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: false,
        currentGrade: action.data.grade
      };

    case TYPES.SET_DEFAULT_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: false
      };

    case TYPES.UPDATE_SUB_SUBJECT_LIST_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: false,
        headerSubSubjectList: action.data.subject
          ? action.data.subject.subSubjectList
          : []
      };

    case TYPES.GET_SUBJECT_HEADER_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        headerSubjectList: action.data.data,
        headerSubSubjectList: action.data.data?.length
          ? action.data.data[0].subSubjectList
          : []
      };

    case TYPES.GET_QUEST_TESTING_INFO_SUCCESS:
      const questInfo = action.data.data.data;
      return {
        ...state,
        totalQuestQuestion: questInfo.totalQuestion
      };
    // ACTION FAILED WILL BE PLACED HERE
    case TYPES.GET_RANKING_FAILED:
    case TYPES.GET_QUESTS_FAILED:
    case TYPES.GET_RECENT_ACTIVITIES_FAILED:
    case TYPES.GET_SUBJECT_HOMEPAGE_FAILED:
    case TYPES.GET_GRADE_FAILED:
    case TYPES.GET_USER_PROFILE_FAILED:
    case TYPES.GET_SUBJECT_HEADER_FAILED:
      return {
        ...state,
        status: "",
        error: "",
        loading: false
      };
    default:
      return state;
  }
};
