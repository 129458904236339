import styled from "styled-components";
import { Button, Input } from "reactstrap";
import { textBlackBold } from "../../components/Text/style";

export const OftenWrongWrapper = styled.div`
  padding: 1rem 0;
  min-height: calc(100vh - ${({ theme }) => theme.headerHeight}px);
`;

export const CustomOption = styled.option`
  ${textBlackBold}
  font-size: 16px;
`;

export const CustomSelect = styled(Input)`
  width: auto;
  margin-right: ${({ pagination }) => pagination === 1 ? 0 : '1.25rem'};
  margin-left: ${({pagination}) => pagination === 1 ? '1.25rem' : 0 };
  font-weight: 700;

  @media screen and (max-width: ${({theme}) => theme.homepageMaxWidth}px) {
    margin-right: .5rem;
    font-size: 14px;
  }

  @media screen and (max-width: ${({theme}) => theme.iPadMaxWidth - 1}px) {
    width: ${({checkitem}) => checkitem === "last" ? '30%' : '100%'};
    margin-right: ${({checkitem}) => checkitem === "last" ? '1rem' : '0'};;
    margin-bottom: ${({checkitem}) => checkitem === "last" ? '0' : '0.8rem'};
    margin-top:  ${({checkitem}) => checkitem === "last" ? '0' : '0.8rem'};
  }
`;

export const CustomButton = styled(Button)`
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid ${({ theme }) => theme.modalHeaderTextColor};
  line-height: 0;
  font-weight: 500;
  white-space: nowrap;
  svg {
    margin-right: 0.5rem;
  }
  
  &:disabled:hover {
    border: 2px solid ${({ theme }) => theme.modalHeaderTextColor};
  }
`;

export const TableWrapper = styled.div`
  background-color: white;
  box-shadow: 2px 2px 12px #2b53a01a;
  border-radius: 5px;
  
  @media screen and (max-width: ${({theme}) => theme.homepageMaxWidth}px) {
    height: 70vh;
    overflow: auto;
    margin: 0;
  }
`;

export const TableRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.75rem 0.2rem;
  border-bottom: ${({ nodata }) => nodata === 'true' ? 0 : 2}px solid #edeff8;
  word-break: break-all;
  transition: all 0.3s ease;

  &:first-child {
    .header {
      font-weight: 300 !important;
      color: #aeb3d0 !important;
    }
  }

  > div:first-child {
    width: 70px;
    text-align: center;
  }

  .translate {
    flex: 1;
    
    &.none {
      display: none;
    }
  }

  &.active {
    background: #f8f9fd 0% 0% no-repeat padding-box;
  }
  
  .none {
    display: none;
  }

  @media screen and (max-width: ${({theme}) => theme.homepageMaxWidth}px\0/) {
    width: 100vw;
  }
  @media screen and (max-width: ${({theme}) => theme.largeBoostrap}px) {
    width: fit-content;
  }
  @media screen and (max-width: ${({theme}) => theme.homepageMaxWidth}px) {
    flex-wrap: nowrap;
    
    > div:first-child {
      min-width: 50px;
      width: 50px;
    }
    
    &.active {
      align-items: flex-start;
      flex-wrap: wrap;
    }
    
    &.no-wrap {
      flex-wrap: nowrap;
    }
    
    .translate {
      min-width: 135px;
    }
  }
`;

export const TableColumn = styled.div`
  flex: 1;
  font-weight: 500;
  color: #757ca8;
  padding-right: 15px;
  white-space: pre-line;
  word-break: break-word;

  &:not(.header) {
    padding-left: 2px;
  }

  &:nth-child(2), &:nth-child(3), &:nth-child(4) {
    flex: 0.5;
  }

  &:nth-child(5) {
    flex: 3;
  }

  &:nth-child(6) {
    flex: 2;
  }
  
  @media screen and (max-width: ${({theme}) => theme.homepageMaxWidth}px) {
    min-width: 120px;
    &:nth-child(2), &:nth-child(3), &:nth-child(4) {
      min-width: 90px;
    }
    &:nth-child(5) {
      min-width: 230px;
    }
  }
`;

export const ItemWrapper = styled.div`
  cursor: pointer;
  visibility: ${({ isUi }) => isUi ? 'hidden' : 'visible'};
`;

export const CustomIcon = styled.img`
  width: 11px;
  cursor: ${({sttDisable}) => sttDisable ? 'default' : 'pointer'};

  &.small {
    width: 8px;
  }

  &.large {
    width: 19px;
  }

  &.huge {
    width: 25px;
  }
`;

export const WrongNumber = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.mainButton.success};
  
  &.negative {
    color: ${({ theme }) => theme.mainButton.danger};
  }
`;

export const RecordWrapper = styled.div`
  height: calc(100vh - 295px);
  overflow-y: auto;
  
  @media screen and (max-width: ${({theme}) => theme.homepageMaxWidth}px) {
    height: calc(100vh - 295px);
    overflow-y: visible;
  }
`;

export const TableRowChild = styled.div`
  width: 100%;
  max-height: 0;
  transition: all 0.3s ease;
  overflow: hidden;

  &.active {
    max-height: 500px;
    transition: all 0.7s ease;
  }

  > div {
    border-bottom: 0;
  }

  & .last_child {
    flex: 1;
  }
`;

export const ShuffleQuestionWrapper = styled.div`
  & label {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #276bf0;
  }
  & .custom-control-label::before,
  & .custom-control-label::after {
    width: 18px;
    height: 18px;
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (max-width: ${({theme}) => theme.iPadMaxWidth -1}px){
    flex-direction: column;
    align-items: stretch;
    + button {
      margin-bottom: 0.8rem;
    }
  }
  
  @media screen and (max-width: ${({theme}) => theme.homepageMaxWidth}px) {
    font-size: 14px;
    flex-basis: 50%;
    + button {
      font-size: 14px;
    }
    
    .text-full {
      width: 100%;
    }
  }
`;

export const CustomTitles = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    margin-top: .18rem;
  }
`

export const GrpWrongTimes = styled.div`
  display: flex;
  align-items: center;
`
