import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  isAuth,
  redirect,
  condition = () => {},
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (condition()) return <Component {...props} />;
      return <Redirect to={redirect} />;
    }}
  />
);

export default PrivateRoute;