import React, { memo } from 'react';
import { ModalBody, ModalHeader } from "reactstrap";
import useWindowSize from "../../hooks/useWindowSize";
import theme from "../../style/variables";
import { CustomModal } from "./style";
import './style.css';

const MainModal = memo(({ show, close, title, children, type, className, header }) => {
  const size = useWindowSize();

  return (
    <CustomModal
      isOpen={show}
      toggle={close}
      fade={false}
      backdropClassName='styled'
      onClick={() => type === 'Solution' && size.width > theme.iPadMinWidth && close()}
      className={className}
      header={header === "modal" ? 1 : 0}
    >
      {!header && <ModalHeader toggle={close}>{title}</ModalHeader>}
      <ModalBody>
        {children}
      </ModalBody>
    </CustomModal>
  )
});

export default MainModal;

MainModal.defaultProps = {
  show: false,
  setShow: () => { }
};