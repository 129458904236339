import React from 'react';
import PropTypes from 'prop-types';
import {EditProfileWrapper} from "../style";
import {TextBlackMedium} from "../../Text/style";

const EditProfile = ({changeNameForm}) => (
  <EditProfileWrapper>
    <TextBlackMedium className="mb-2" fontSize={14}>ニックネーム</TextBlackMedium>
    {changeNameForm}
  </EditProfileWrapper>
);

EditProfile.propTypes = {
  changeNameForm: PropTypes.element
};

EditProfile.defaultProps = {
  changeNameForm: null
};

export default React.memo(EditProfile);