import styled, { css } from "styled-components";

export const textGrayDefault = css`
  color: ${({ theme }) => theme.textColorGray};
  font-weight: normal;
`;

export const textGrayMedium = css`
  color: ${({ theme }) => theme.textColorGray};
  font-weight: 500;
`;

export const textGrayBold = css`
  color: ${({ theme }) => theme.textColorGray};
  font-weight: 700;
`;

export const textBlackDefault = css`
  color: ${({ theme }) => theme.textColorBlack};
  font-weight: normal;
`;

export const textBlackMedium = css`
  color: ${({ theme }) => theme.textColorBlack};
  font-weight: 500;
`;

export const textBlackBold = css`
  color: ${({ theme }) => theme.textColorBlack};
  font-weight: 700;
`;

export const TextGrayDefault = styled.div`
  ${textGrayDefault}
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "inherit")};
  color: ${({ color }) => color || ""};
`;

export const TextGrayMedium = styled.div`
  ${textGrayMedium}
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "inherit")};
  color: ${({ color }) => color || ""};
`;

export const TextGrayBold = styled.div`
  ${textGrayBold}
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "inherit")};
  color: ${({ color }) => color || ""};
`;

export const TextBlackDefault = styled.div`
  ${textBlackDefault}
  font-size: 100%;
  color: ${({ color }) => color || ""};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "inherit")};
  }
`;

export const TextBlackMedium = styled.div`
  ${textBlackMedium}
  font-size: 100%;
  color: ${({ color }) => color || ""};

  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "inherit")};
  }
`;

export const TextBlackBold = styled.div`
  position: relative;
  ${textBlackBold}
  font-size: 100%;
  color: ${({ color }) => color || ""};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.oftenWrong {
    text-overflow: unset;
    white-space: unset;
    overflow: unset;
    width: 80%;
  }
  &.progress-home {
    text-overflow: unset;
    white-space: unset;
    overflow: visible;
  }
  & .text-new {
    position: absolute;
    top: -35%;
    right: 0%;
    transform: translate(100%, 0%);
    width: auto;
  }
  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "inherit")};
  }
`;
