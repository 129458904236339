import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from "react-router-dom";
import {toggleSidebar, toggleSidebarRight, showModalGradeNavigation} from '../../store/actions/sidebar';
import {
  getGrades,
  getUserProfile,
  changeGrade,
  getSubjects,
  updateSubSubjectList,
  getSubjectsHeader
} from '../../store/actions/home'
import {
  checkOutSideAction,
  acceptOutSideAction
} from '../../store/actions/myNote';
import Navigation from './Navigation';

const mapStateToProps = (state) => ({
  status: state.sidebar.status,
  error: state.sidebar.error,
  sidebar: state.sidebar.sidebar,
  sidebarRight: state.sidebar.sidebarRight,
  modalGradeNavigation: state.sidebar.modalGrade,
  isRedirectPage: state.sidebar.isRedirectPage,
  gradeList: state.home.gradeList,
  currentGrade: state.home.currentGrade,
  subjectList: state.home.subjectList,
  subSubjectList: state.home.subSubjectList,
  headerSubjectList: state.home.headerSubjectList,
  headerSubSubjectList: state.home.headerSubSubjectList,
  statusHome: state.home.status,
  isOutSide: state.myNote.isOutSide,
  isAcceptOutSide: state.myNote.isAcceptOutSide
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toggleSidebar,
  toggleSidebarRight,
  showModalGradeNavigation,
  getGrades,
  getUserProfile,
  changeGrade,
  getSubjects,
  updateSubSubjectList,
  getSubjectsHeader,
  checkOutSideAction,
  acceptOutSideAction
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation));
