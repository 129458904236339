import React from 'react';
import PropTypes from "prop-types";
import {CustomInput} from 'reactstrap';
import {ChapterItemWrapper, FlexLesson} from "../style";
import {TextBlackMedium} from "../../Text/style";

const ChapterItem = ({index, allChecked, largeUnitName, lessons, handleCheckAll}) => (
  <>
    {!!lessons.length && (
      <ChapterItemWrapper>
        <TextBlackMedium className="mb-2" fontSize={14}>{largeUnitName}</TextBlackMedium>
        <div>
          <CustomInput
            className="mb-2"
            type="checkbox"
            label="すべて選択"
            id={`checkAll${index}`}
            checked={allChecked}
            onChange={e => handleCheckAll({e, index, isCheckAll: true})}
          />
          <FlexLesson>
            {lessons.map(lesson =>
              <div key={lesson.id} className="w-50 mb-2">
                <CustomInput
                  type="checkbox"
                  label={lesson.name}
                  id={lesson.id}
                  checked={lesson.isChecked}
                  onChange={e => handleCheckAll({e, index, isCheckAll: false, id: lesson.id})}
                />
              </div>
            )}
          </FlexLesson>
        </div>
      </ChapterItemWrapper>
    )}
  </>
);

ChapterItem.propTypes = {
  largeUnitName: PropTypes.string,
  lessons: PropTypes.instanceOf(Array),
  handleCheckAll: PropTypes.func,
  allChecked: PropTypes.bool,
  index: PropTypes.number
};

ChapterItem.defaultProps = {
  largeUnitName: '',
  lessons: [],
  handleCheckAll: () => {},
  allChecked: false,
  index: 0
};

export default React.memo(ChapterItem);