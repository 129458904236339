import styled from "styled-components";

export const WrapperItem = styled.div`
  background-color: white;
  box-shadow: 2px 2px 12px ${({ theme }) => theme.boxShadowColor};
  border-radius: 5px;
  padding: 1rem 1.2rem;
  
  @media screen and (min-width: ${({theme}) => theme.laptopWidth}px) {
    padding: 1rem 2rem;
  }
  
  @media screen and (max-width: ${({theme}) => theme.mobileWidth}px) {
    padding: 1rem;
  }
  
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  
  &.home-slider {
    width: 30%;
    margin-right: 15px;
    padding-right: 1rem;
    @media screen and (max-width: ${({theme}) => theme.homepageMaxWidth}px) {
      width: 100%;
      margin-right: 0;
    }
  }
  
  &.home-daily-quest {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 70%;
    margin-bottom: 15px;
    padding: .75rem 1.1rem;
    @media screen and (max-width: ${({theme}) => theme.homepageMaxWidth}px) {
      width: 100%;
    }
  }
  
  &.wrapper-ranking-home {
    width: 100%;
    display: block;
    @media screen and (min-width: ${({theme}) => theme.homepageMaxWidth}px) {
      display: none;
    }
  }
  
  &.recent-training {
    flex: 1;
    height: 100%;
    @media screen and (min-height: 850px) and (min-width: ${({ theme }) => theme.iPadMaxWidth}px) {
      height: 205px;
    }
    @media screen and (max-width: ${({ theme }) => theme.iPadMaxWidth}px) {
      height: auto;
      flex: none;
    }
    @media screen and (min-width: ${({theme}) => theme.tabletMinWidth}px) and (max-width: ${({theme}) => theme.tabletMaxWidth}px) {
      margin-bottom: 15px;
    }
    @media screen and (min-width: ${({theme}) => theme.homepageMaxWidth}px\0/) {
      flex: 1 0 26.5%;
    }
  }
`;
