import React from 'react';
import {ContentWrapper} from "../Layout/style";
import RouterPath from "../../constants/router-path";

const Content = ({children, sidebar, history}) => {
    const className = history.location.pathname === RouterPath.SELECT_CHARRACTOR.path ? 'no-sidebar' : '';

    return (
      <ContentWrapper $toggle={sidebar} className={className}>
          {children}
      </ContentWrapper>
    )
};

export default Content;