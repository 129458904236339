import styled from "styled-components";
import { ButtonGroup } from "reactstrap";

export const MainButtonGroup = styled(ButtonGroup)`
  padding: 3px 5px;
  border: 1px solid ${({ theme }) => theme.textColorGray};
  border-radius: 10px;
  width: 100%;
  overflow-x: auto;

  &.btn-number,
  & .request-number {
    overflow: visible;
  }

  button {
    background-color: transparent;
    color: ${({ theme }) => theme.textColorGray};
    border: none;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 5px;
    }

    &.active,
    &:hover {
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }
    
    &.active {
      pointer-events: none;
    }
  }
`;
