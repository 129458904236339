import React from "react";
import PropTypes from "prop-types";
import { Spinner  } from "reactstrap";
import {
  ChapterList,
  CustomSelect,
  FlexBetween,
  SettingTargetWrapper
} from "../style";
import { TextGrayDefault } from "../../Text/style";
import { MainButton } from "../../Button/style";
import ButtonGroup from "../../ButtonGroup/ButtonGroup";
import ChapterItem from "./ChapterItem";

const SettingTarget = ({
  currentSubject,
  handleChangeSubject,
  chapters,
  handleCheckAll,
  handleSettingTarget,
  loadingSettings,
  targetSubjectList,
  targetSubSubjectList,
  handleChangeSubSubject,
  currentSubSubject,
  gradeList,
  choiceGrade,
  groupCode,
  gradeCurrent,
  sttSubmit
}) => (
  <SettingTargetWrapper>
    <FlexBetween className="mb-3">
      <TextGrayDefault fontSize={14}>学年を選択してください</TextGrayDefault>
      <CustomSelect
        type="select"
        name="select"
        id="exampleSelect"
        onChange={(e)=>choiceGrade(e)}
        value={groupCode || gradeCurrent}
      >
        {gradeList.map((item) => (
          <option value={item.groupCode} key={item.groupCode}>
            {item.groupName}
          </option>
        ))}
      </CustomSelect>
    </FlexBetween>
    <div className="mb-3">
      <ButtonGroup
        listButton={targetSubjectList}
        selected={currentSubject.index}
        handleChangeSelected={handleChangeSubject}
        getId
        field="name"
        isLoading={loadingSettings}
      />
    </div>
    {!!targetSubjectList.length && (
      <>
        {groupCode === 1 && <FlexBetween className="mb-3">
          <TextGrayDefault fontSize={14}>課題を選んでください</TextGrayDefault>
          <CustomSelect
            type="select"
            name="select"
            id="exampleSelect"
            onChange={handleChangeSubSubject}
            value={currentSubSubject || ""}
          >
            {targetSubSubjectList.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </CustomSelect>
        </FlexBetween>}

        {loadingSettings && !chapters.length? 
        <div className="d-flex justify-content-center w-70 mb-3">
          <Spinner size="sm" color="primary" />  
        </div>      
        : <ChapterList >
          {chapters.map((chapter, index) => (
            <ChapterItem
              index={index}
              key={chapter.largeUnitId}
              {...chapter}
              handleCheckAll={handleCheckAll}
            />
          ))}
        </ChapterList>}

        <div className="text-center">
          <MainButton
            color="primary"
            onClick={handleSettingTarget}
            disabled={loadingSettings || !chapters.length || sttSubmit}
          >
            変更
          </MainButton>
        </div>
      </>
    )}
  </SettingTargetWrapper>
);

SettingTarget.propTypes = {
  currentSubject: PropTypes.instanceOf(Object),
  chapters: PropTypes.instanceOf(Array),
  handleChangeSubject: PropTypes.func,
  handleCheckAll: PropTypes.func,
  handleSettingTarget: PropTypes.func,
  handleChangeSubSubject: PropTypes.func,
  loadingSettings: PropTypes.bool,
  currentSubSubject: PropTypes.number,
  targetSubjectList: PropTypes.instanceOf(Array)
};

SettingTarget.defaultProps = {
  currentSubject: {},
  chapters: [],
  handleChangeSubject: () => {},
  handleCheckAll: () => {},
  handleSettingTarget: () => {},
  handleChangeSubSubject: () => {},
  loadingSettings: false,
  currentSubSubject: null,
  targetSubjectList: []
};

export default React.memo(SettingTarget);
