import Storage from "../../utils/storega";
import { TYPES } from "../actions";

const initialState = {
  status: null,
  error: null,
  loading: false,
  listCharacter: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ACTIONS WITHOUT DATA WILL BE PLACES HERE
    case TYPES.GET_CHARACTOR_REQUEST:
    case TYPES.SELECT_CHARACTOR_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true
      };

    // ACTIONS WITH DATA WILL BE PLACES HERE
    case TYPES.GET_CHARACTOR_SUCCESS: {
      let listCharacter = action.data.charactor;
      if (Array.isArray(listCharacter) && listCharacter.length <= 3) {
        listCharacter = [...listCharacter, ...listCharacter];
      }
      return {
        ...state,
        status: action.type,
        listCharacter,
        loading: false
      };
    }

    // ACTIONS WITH DATA WILL BE PLACES HERE
    case TYPES.SELECT_CHARACTOR_SUCCESS:
      Storage.set("USER_PROFILE", action.data.selectCharactor);

      return {
        ...state,
        status: action.type,
        loading: false
      };

    // ACTION FAILED WILL BE PLACED HERE
    case TYPES.GET_CHARACTOR_FAILED:
    case TYPES.SELECT_CHARACTOR_FAILED:
      return {
        ...state,
        status: "",
        error: "",
        loading: false
      };
    default:
      return state;
  }
};
