/* eslint-disable no-nested-ternary */
import styled from "styled-components";
import { Progress, Button, Form, Row } from "reactstrap";
import { CloseIconStyle } from "../Testing/style";
import { MainButton } from "../../components/Button/style";

export const TitleFlip = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  label {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #0a2761;
    margin-bottom: 0;
  }
  .btn {
    line-height: 0;
  }
  .empty {
    min-height: 35px;
  }
  &.title-right-note {
    flex-wrap: wrap;
  }
  @media screen and (max-width: ${({ theme }) => theme.tabletWidth}px) {
    margin-bottom: 0;
    label {
      font-size: 20px;
    }
  }
`;

export const ContentFlip = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 1140px;
  margin: 0 auto;
  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    width: 55%;
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletWidth}px) {
    width: 100%;
    overflow: hidden;
  }
`;

export const CloseLearning = styled(CloseIconStyle)`
  margin-left: ${({isresult}) => isresult ? '0' : '90px'};
  width: 36px !important;
  height: 36px;
  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    margin-left: ${({isresult}) => isresult ? '0' : '45px'};
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletWidth}px) {
    margin-left: ${({isresult}) => isresult ? '0' : '12px'};
  }
`;

export const CardFlip = styled.div`
  position: relative;
  border-radius: 10px;
  /* min-height: 405px; */
  width: 100%;
  text-align: center;
  cursor: pointer;
  
  &.disabled {
    pointer-events: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    /* min-height: 300px; */
  }
`;
export const CardFront = styled.div`
  color: ${({ theme }) => theme.textPrimary};
  background: #ffffff 0% 0% no-repeat padding-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border: 3px solid #dee0ec;
  white-space: pre-line;
  border-radius: 10px;
  width: 100%;
  height: calc(100vh - 360px);

  /* min-height: 310px; */
  transform-style: preserve-3d;
  transition: transform 0.8s;
  
  font-size: ${({fontSize}) => fontSize}rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  transform: ${(props) =>
    props.flip
      ? props.isMobile
        ? "rotateY(180deg)"
        : "rotateX(180deg)"
      : props.isMobile
        ? "rotateY(0deg)"
        : "rotateX(0deg)"};

  padding: 1rem;
  background-image: url(${props => props.urlImg ? props.urlImg : ''});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  ul {
    margin-left: ${({checkAlign}) => checkAlign ? '2.5rem' : 0};
  }

  li,
  li font {
    font-size: ${({fontSize}) => fontSize - 0.1}rem;
  }
  img {
    width: 100%;
    max-width: 280px;
    max-height: 80px;
    object-fit: contain;
  }
  span {
    width: 100%;
  }

  span font {
    font-size: ${({fontSize}) => fontSize}rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    border-width: 2px;
    font-size: ${({fontSize}) => fontSize - 0.7}rem;
    span font {
      font-size: ${({fontSize}) => fontSize - 0.7}rem;
    }
    ul {
      padding-left: 1.5rem;
      margin-left: 0;
      margin-bottom: 0;
    }
    li,
    li font {
      font-size: ${({fontSize}) =>  fontSize - 0.9}rem;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.tabletWidth}px) {
    min-height: calc(100vh - 350px);
    max-height: calc(100vh - 305px);
    height: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.tabletMaxWidth}px) and (min-height : 736px) {
    min-height: calc(100vh - 400px);
    max-height: calc(100vh - 315px);
    height: 100%;
  }
  div {
    height: auto;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-y: auto;
    -webkit-overflow-y-scrolling: touch;
    word-break: break-word;
  }
`;

export const CardBack = styled.p`
  color: ${({ theme }) => theme.textPrimary};
  background: #ffffff 0% 0% no-repeat padding-box;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #dee0ec;
  white-space: pre-line;
  overflow-y: auto;

  border-radius: 10px;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s;

  font-size: ${({fontSize}) => fontSize}rem;
  font {
    font-size: 2.1rem;
  }

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: ${(props) =>
    props.flip
      ? props.isMobile
        ? "rotateY(0deg)"
        : "rotateX(0deg)"
      : props.isMobile
      ? "rotateY(180deg)"
      : "rotateX(180deg)"};
  padding: 1rem;
  background-image: url(${props => props.urlImg ? props.urlImg : ''});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    border-width: 2px;
  }
  @media screen and (max-width: ${({ theme }) => theme.tabletWidth}px) {
    min-height: calc(100vh - 350px);
    max-height: calc(100vh - 305px);
    height: auto;
  }
  @media screen and (max-width: ${({ theme }) => theme.tabletWidth}px) and (min-height : 736px) {
    min-height: calc(100vh - 400px);
    max-height: calc(100vh - 315px);
  }
`;

export const CustomProgress = styled(Progress)`
  flex: 1;
  margin: 0 0.5rem;
`;
export const DesContent = styled.div`
  background: #f8f9fd 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    height: 300px;
    overflow: auto;
    text-align: left;
    font: normal normal normal 18px/30px Noto Sans JP;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.modalHeaderBackgroundColor};
    margin-bottom: 0;
    padding: 16px;
    width: 100%;
  }
`;

export const CustomSpan = styled.span`
  text-align: left;
  font: normal normal normal 15px/30px Noto Sans JP;
  letter-spacing: 0px;
  color: #757ca8;
  margin-left: 10px;
`;

export const Search = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c8dbfe;
  border-radius: 5px;
  padding: 2px 5px;
  display: flex;
  align-items: center;

  input {
    border: none;
    outline: none;
    width: 95%;
    padding: 0 5px;
    text-align: left;
    font: normal normal normal 20px/30px Noto Sans JP;
    letter-spacing: 0px;
    color: #c8dbfe;
  }

  input::placeholder {
    color: #c8dbfe;
  }

  svg {
    color: #c8dbfe;
  }
`;

export const Group = styled.div`
  padding: 10px 0;
`;

export const CustomBtn = styled(Button)`
  min-width: 150px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  line-height: 0;
  
  &.auto-play {
    min-width: 120px;
    height: 42px;
    position: relative;
    
    &:active {
      color: #007bff !important;
      background-color: #fff !important;
    }

    &.active {
      background-color: #007bff !important;
      color: #fff !important;
    }
    
    &:focus, &:active:focus {
      box-shadow: none !important;
    }

    @media screen and (min-width: ${({ theme }) => theme.iPadMaxWidth}px) {
      &:hover {
        &::before {
          content: '';
          width: 10px;
          height: 10px;
          background: ${({theme}) => theme.buttonColorBlue};
          position: absolute;
          border-radius: 50%;
          left: 50%;
          transform: translateX(-50%);
          bottom: -15px;
          z-index: 1;
        }

        &::after {
          content: '${({texthover}) => texthover}';
          width: 90px;
          text-align: center;
          padding: 5px;
          background: ${({theme}) => theme.buttonColorBlue};
          position: absolute;
          border-radius: 5px;
          left: 50%;
          transform: translateX(-50%);
          bottom: -53px;
          color: #fff;
          z-index: 1;
          height: 34px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  
  &.disabled {
    pointer-events: none;
  }
  
  svg {
    margin-right: 0.5rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.tabletWidth}px) {
    min-width: auto;
    height: auto;
    padding: 6px;
    svg {
      margin-right: 0;
    }
  }
`;
export const TestingBtn = styled(CustomBtn)`
  @media screen and (max-width: ${({ theme }) => theme.tabletWidth}px) {
    span {
      font-size: 14px;
      padding-left: 4px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.mobileWidth}px) {
    &:hover {
      background-color: #fff !important;
      color: #007bff !important;
    }
  }
`;

export const BtnSmall = styled(CustomBtn)`
  min-width: 100px;
  height: 42px;
  background: #276bf0;
  border-radius: 5px;
  color: white;
  line-height: 0;
  font-weight: 500;
`;

export const ListWord = styled.div`
  background: #f8f9fd 0% 0% no-repeat padding-box;
  border-radius: 10px;
  height: 277px;
  overflow: auto;
`;

export const ListRow = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-bottom: 1px solid #edeff8;
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;

  label {
    margin-bottom: 0;
    color: ${({ theme }) => theme.textColorGray};
  }
`;

export const FirstCol = styled.div`
  flex: 2;
  span {
    color: ${({checkColor}) => checkColor ? "#276BF0" : "#757ca8"};
  }
`;

export const SecondCol = styled.div`
  flex: 1;
  text-align: right;
  span,
  svg {
    color: ${({checkColor}) => checkColor ? "#276BF0" : "#757ca8"};
  }
`;

export const CustomForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-left: 0;
    margin-bottom: 5px;
  }

  .form-group {
    width: 100%;
  }

  button {
    width: 141px;
    height: 42px;
    margin-bottom: 1rem;
    font-size: 16px;

    &.modal-btn {
      width: 100px;
      height: auto;
    }

    &.table-btn {
      width: 100px;
      min-width: 100px;
      height: auto;
      margin-bottom: 0;
    }
  }

  input::placeholder {
    color: ${({ theme }) => theme.modalHeaderTextColor};
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder {
    color: ${({ theme }) => theme.modalHeaderTextColor};
  }

  input::-ms-input-placeholder {
    color: ${({ theme }) => theme.modalHeaderTextColor};
  }

  input {
    border: 1px solid ${({ theme }) => theme.modalHeaderTextColor};

    &:focus,
    &:active,
    &.is-invalid:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;

export const Testing = styled.div`
  text-align: center;
  label {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #276bf0;
  }
  & .custom-control-label::before,
  & .custom-control-label::after {
    width: 18px;
    height: 18px;
  }
`;

export const SttTesting = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
  position: relative;
  margin-bottom: 0.5rem;
  &:before {
    content: "";
    position: absolute;
    border: 1px solid #edeff8;
    width: 50%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    padding: 25px;
  }
`;

export const ItemStt = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 1rem;
  }
  flex: 0 0 55%;
  span {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #276bf0;
  }

  &:first-child {
    flex: 0 0 45%;
  }
  
  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    flex: 0 0 100%;
    &:first-child {
      flex: 0 0 100%;
    }
  }
`;
export const MainLearning = styled.div`
  padding-top: 1rem;
  height: 100%;
  @media screen and (max-width: ${({ theme }) => theme.tabletWidth}px) {
   padding-top: 4rem;
   overflow: hidden;
    .breadcrumb {
      padding-top: 0;
    }
  }
`;

export const CustomIcon = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
  object-fit: contain;

  &.active {
    cursor: default;
  }
`;

export const NextPage = styled(Row)`
  background: #ffffff 0% 0% no-repeat padding-box;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  .progress {
    height: 5px;
    border-radius: 0;
    margin: 0;

    .progress-bar {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
`;

export const LearningNavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;

  .prev-btn, .next-btn {
    border-radius: 5px;
    font-size: 18px;
    width: 110px;
    padding-top: 4px;
    padding-bottom: 4px;
    max-height: 42px;

    &.btn-outline-primary {
      border: 2px solid #a3cafd;
    }
  }

  .question {
    margin-left: 33px;
    margin-right: 33px;
    border-radius: 5px;
    border: 2px solid #c8dbfe;
    padding: 2px 0;
    font-size: 21px;
  }

  .arrow {
    font-size: 18px;
  }
  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    .prev-btn, .next-btn {
      font-size: 24px;
      width: 130px;
      padding-top: 2px;
      padding-bottom: 2px;
    }
    .question {
      margin-left: 40px;
      margin-right: 40px;
      font-size: 24px;
    }
    .arrow {
      font-size: 24px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletWidth}px) {
    padding: 1rem;
    .question {
      margin-left: 6px;
      margin-right: 0;
      font-size: 16px;
      background: #fff;
    }
    .prev-btn, .next-btn {
      font-size: 16px;
      width: auto;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
`;

export const FlexEnd = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  margin-top: 16px;

  > * {
    margin-left: 1rem;
  }
  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    margin-top: 26px;
  }
`;

export const ImageRound = styled.div`
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  width: 42px;
  height: 42px;
  box-shadow: 0px 3px 6px #757ca84e;
  cursor: pointer;
    
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
  }
  
  &.md {
    img {
      width: 30px;
      height: 30px;
    }
  }
  &.md-1 {
    img {
      width: 25px;
      height: 25px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    width: 50px;
    height: 50px;
    img {
      width: 27px;
      height: 27px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletWidth}px) {
    width: 36px;
    height: 36px;
    box-shadow: 0px 2px 3px #757CA84E;
    img {
      width: 20px;
      height: 20px;
    }
    &.md-1 {
      width: 36px;
      height: 36px;
      img {
        width: 25px;
        height: 25px;
      }
    }
    &.md, &.md-2 {
      width: 27px;
      height: 27px;
      img {
        width: 18px;
        height: 18px;
      }
    }
    &.md-2 {
      img {
        width: 22px;
        height: 22px;
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.iPadMinWidth}px) and (max-width: ${({ theme }) => theme.iPadMaxWidth}px) {
    &.md {
      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.iPadMaxWidth}px) {
    &:hover {
      &::before {
        content: '';
        width: 10px;
        height: 10px;
        background: ${({theme}) => theme.buttonColorBlue};
        position: absolute;
        border-radius: 50%;
        left: 50%;
        transform: translateX(-50%);
        bottom: -15px;
        z-index: 1;
      }
      
      &::after {
        content: '${({textHover}) => textHover}';
        width: 90px;
        text-align: center;
        padding: 5px;
        background: ${({theme}) => theme.buttonColorBlue};
        position: absolute;
        border-radius: 5px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -53px;
        color: #fff;
        z-index: 1;
      }
    }
  }
  
  &:active, &:focus {
    background: ${({theme}) => theme.buttonColorBlue};
  }

  &.disabled {
    pointer-events: none;
  }
`;

export const ImageRoundSolution = styled.div`
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  width: 42px;
  height: 42px;
  box-shadow: 0px 3px 6px #757ca84e;

  img {
    width: 100%;
    height: 100%;
    &:active, &:focus {
    background: ${({theme}) => theme.buttonColorBlue};
  }
  }
  @media screen and (min-width: ${({ theme }) => theme.laptopWidth}px) {
    width: 50px;
    height: 50px;
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletWidth}px) {
    width: 32px;
    height: 32px;
    box-shadow: 0px 2px 3px #757CA84E;
  }
  
  @media screen and (min-width: ${({ theme }) => theme.iPadMaxWidth}px) {
    &:hover {
      &::before {
        content: '';
        width: 10px;
        height: 10px;
        background: ${({theme}) => theme.buttonColorBlue};
        position: absolute;
        border-radius: 50%;
        left: 50%;
        transform: translateX(-50%);
        bottom: -15px;
        z-index: 1;
      }
      
      &::after {
        content: '${({textHover}) => textHover}';
        width: 90px;
        text-align: center;
        padding: 5px;
        background: ${({theme}) => theme.buttonColorBlue};
        position: absolute;
        border-radius: 5px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -53px;
        color: #fff;
        z-index: 1;
      }
    }
  }
  
  &:active, &:focus {
    background: ${({theme}) => theme.buttonColorBlue};
  }
`;

export const CardFlipWrapper = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.tabletWidth}px) {
    padding-bottom: 5px;
    position: relative;
  }
`;

export const IconMainButton = styled(MainButton)`
  &.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 0;
    background-color: #fff;
  }
  svg {
    color: ${({ theme, color }) => theme.mainButton[color]};
  }
`;

export const LearningDoneWrapper = styled.div`
  padding: 2rem 0;

  .to-testing-btn {
    width: calc(260px + 1rem);
    font-size: 1.5rem;
    border-radius: 10px;
  }
`;

export const LearningDoneItem = styled.div`
  &:first-child {
    padding-right: 1rem;
  }

  button {
    width: 130px;
  }
`;
