import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from "react-router-dom";
import {
  toggleSidebarRight,
  setEditProfile,
  setSettingTarget
} from '../../store/actions/sidebar'
import {
  updateNickName,
  userSettingTarget,
  getTargetSubjects,
  getTargetSubSubjects,
  getLargeUnitAndLesson,
  resetLargeUnitAndLesson
} from '../../store/actions/settings'
import { logout } from '../../store/actions/auth';
import SidebarRight from './SidebarRight';

const mapStateToProps = (state) => ({
  status: state.sidebar.status,
  error: state.sidebar.error,
  sidebarRight: state.sidebar.sidebarRight,
  isEditProfile: state.sidebar.isEditProfile,
  loadingSettings: state.settings.loading,
  isSettingTarget: state.sidebar.isSettingTarget,
  targetSubjectList: state.settings.targetSubjectList,
  targetSubSubjectList: state.settings.targetSubSubjectList,
  largeUnitAndLessonList: state.settings.largeUnitAndLessonList,
  statusSetting: state.settings.status,
  currentGrade: state.home.currentGrade,
  sttProfile: state.home.status,
  gradeList: state.home.gradeList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toggleSidebarRight,
  setEditProfile,
  updateNickName,
  setSettingTarget,
  userSettingTarget,
  getTargetSubjects,
  getTargetSubSubjects,
  getLargeUnitAndLesson,
  resetLargeUnitAndLesson,
  logout
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarRight));
