import { combineReducers } from "redux";
import auth from "./auth";
import testing from "./testing";
import learning from "./learning";
import lesson from "./lesson";
import progress from "./progress";
import myNote from "./myNote";
import sidebar from "./sidebar";
import settings from "./settings";
import home from "./home";
import ranking from "./ranking";
import oftenWrong from "./oftenWrong";
import charactor from "./charactor"

const appReducer = combineReducers({
  auth,
  testing,
  learning,
  lesson,
  progress,
  myNote,
  sidebar,
  settings,
  home,
  ranking,
  oftenWrong,
  charactor
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
