/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-did-update-set-state */
import React, {lazy, PureComponent, Suspense} from "react";
import {matchPath, Redirect, Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Storage from "../utils/storega";
import RouterPath from "../constants/router-path";
import Loading from "../components/loading";
import PrivateRoute from "../components/Route/PrivateRoute";
import {AppNoLayoutStyle, AppStyle, FlexBox, LayoutWrapper} from "../components/Layout/style";
import Navigation from "../components/Navigation";
import Sidebar from "../components/Sidebar";
import SidebarRight from "../components/SidebarRight";
import ContentWrapper from "../components/ContentWrapper";
import CheckMaintain from "../containers/CheckMaintain";
import {getLoginUrl} from "../utils/commonUtils";
import {logout} from "../store/actions/auth";

const SignInSchool = lazy(() => import("../containers/SignInSchool"));
const SignIn = lazy(() => import("../containers/SignIn"));
const LessonPage = lazy(() => import("../containers/Lessons"));
const TestingPage = lazy(() => import("../containers/Testing"));
const ResultTestingPage = lazy(() => import("../containers/ResultTesting"));
const LearningPage = lazy(() => import("../containers/Learning"));
const CommonPage = lazy(() => import("../containers/Common"));
const HomePage = lazy(() => import("../containers/HomePage"));
const ProgressPage = lazy(() => import("../containers/Progress"));
const MyNote = lazy(() => import("../containers/MyNote"));
const RankingPage = lazy(() => import("../containers/Ranking"));
const OftenWrongPage = lazy(() => import("../containers/OftenWrong"));
const SelectCharactor = lazy(() => import("../containers/Charactor"));

const LoadingPage = () => <Loading />;

class Routes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const profile = Storage.get("USER_PROFILE");
    const token = Storage.get("USER_ACCESS_TOKEN");
    const { pathname } = this.props.location;
    if (profile && profile.character === null && token) {
      this.props.history.push(`${RouterPath.SELECT_CHARRACTOR.path}`);
    }
    if (
      profile &&
      profile.character &&
      token &&
      pathname === RouterPath.SELECT_CHARRACTOR.path
    ) {
      this.props.history.push(`${RouterPath.HOME.path}`);
    }
  }

  componentDidCatch(error) {
    if (/Loading chunk [\d]+ failed/.test(error.message)) {
      window.location.reload();
    }
  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.props.location;
    const profile = Storage.get("USER_PROFILE");
    if (prevProps.location.pathname !== pathname) {
      profile &&
        !profile.character &&
        this.props.history.push(`${RouterPath.SELECT_CHARRACTOR.path}`);
    }
    // If update nickname
    if (
      prevProps.currentUser &&
      profile &&
      prevProps.currentUser.nickName !== profile.nickName
    )
      return;

    if (
      prevProps.currentUser !== this.props.currentUser &&
      prevProps.location.pathname !== pathname &&
      pathname !== RouterPath.RESULT.path
    ) {
      this.props.currentUser &&
        this.props.currentUser.character &&
        this.props.history.push(`${RouterPath.HOME.path}`);
    }
  }

  _authCondition = () => Storage.has("USER_ACCESS_TOKEN");

  _noAuthCondition = () => {
    const url = new URL(window.location.href);
    const ssoToken = url.searchParams.get("ssoToken");
    if (ssoToken) this.props.logout();
    return !Storage.has("USER_ACCESS_TOKEN");
  };

  _renderLazyComponent = (LazyComponent, params) => (props) => (
    <LazyComponent {...props} {...params} />
  );

  _renderAuthRoutes = () => {
    const { pathname } = this.props.location;
    const isscrollinpage = matchPath(pathname, RouterPath.LESSONS.path)
      || matchPath(pathname, RouterPath.LESSONS.pathUnit)
      || matchPath(pathname, RouterPath.LESSONS.pathLesson)
      || matchPath(pathname, RouterPath.MY_NOTE.path);
    // const { sidebarRight } = this.props;  

    return (
      <AppStyle>
        <LayoutWrapper isscrollinpage={isscrollinpage  ? 'true' : ''}>
          <Navigation />
          <SidebarRight />
          <FlexBox>
            <Sidebar isscrollinpage={isscrollinpage ? 'true' : ''} />
            <ContentWrapper>
              <Suspense fallback={<LoadingPage />}>
                <Switch>
                  <Route
                    exact
                    path={RouterPath.HOME.path}
                    component={this._renderLazyComponent(HomePage)}
                  />
                  <Route
                    path={RouterPath.LESSONS.path}
                    component={this._renderLazyComponent(LessonPage)}
                  />
                  <Route
                    path={RouterPath.LESSONS.pathUnit}
                    component={this._renderLazyComponent(LessonPage)}
                  />
                   <Route
                    path={RouterPath.LESSONS.pathLesson}
                    component={this._renderLazyComponent(LessonPage)}
                  />
                  <Route
                    exact
                    path={RouterPath.PROGRESS.path}
                    component={this._renderLazyComponent(ProgressPage)}
                  />
                  <Route
                    exact
                    path={RouterPath.MY_NOTE.path}
                    component={this._renderLazyComponent(MyNote)}
                  />
                  <Route
                    exact
                    path={RouterPath.COMMON.path}
                    component={this._renderLazyComponent(CommonPage)}
                  />

                  <Route
                    exact
                    path={RouterPath.RANKING.path}
                    component={this._renderLazyComponent(RankingPage)}
                  />
                  <Route
                    exact
                    path={RouterPath.OFTEN_WRONG.path}
                    component={this._renderLazyComponent(OftenWrongPage)}
                  />
                  <Redirect to={RouterPath.HOME.path} />
                </Switch>
              </Suspense>
            </ContentWrapper>
          </FlexBox>
        </LayoutWrapper>
      </AppStyle>
    );
  };

  _renderNoSiderBar = () => {
    return (
      <AppStyle>
        <LayoutWrapper>
          <Navigation />
          <SidebarRight />
          <FlexBox>
            <ContentWrapper>
              <div className="container-fluid main-wrapper">
                <Suspense fallback={<LoadingPage />}>
                  <Switch>
                    <Route
                      exact
                      path={RouterPath.SELECT_CHARRACTOR.path}
                      component={this._renderLazyComponent(SelectCharactor)}
                    />
                    <Redirect to={RouterPath.HOME.path} />
                  </Switch>
                </Suspense>
              </div>
            </ContentWrapper>
          </FlexBox>
        </LayoutWrapper>
      </AppStyle>
    );
  };

  _renderNoLayout = () => {
    const { pathname } = this.props.location;
    return (
      <AppNoLayoutStyle isresult={matchPath(pathname, RouterPath.RESULT.path) ? 'true' : ''}>
        <div className="container-fluid main-wrapper">
          <Suspense fallback={<LoadingPage />}>
            <Switch>
              <Route
                exact
                path={RouterPath.TESTING.path}
                component={this._renderLazyComponent(TestingPage)}
              />
              <Route
                exact
                path={RouterPath.TESTING.pathQuest}
                component={this._renderLazyComponent(TestingPage)}
              />
              <Route
                exact
                path={RouterPath.RESULT.path}
                component={this._renderLazyComponent(ResultTestingPage)}
              />
              <Route
                exact
                path={RouterPath.LEARNING.path}
                component={this._renderLazyComponent(LearningPage)}
              />
              <Redirect to={RouterPath.HOME.path} />
            </Switch>
          </Suspense>
        </div>
      </AppNoLayoutStyle>
    );
  };

  render() {
    return (
      <Suspense fallback={<LoadingPage />}>
        <CheckMaintain />
        <Switch>
          <PrivateRoute
            path={RouterPath.SIGN_IN_SCHOOL.path}
            component={this._renderLazyComponent(SignInSchool)}
            condition={this._noAuthCondition}
            redirect={RouterPath.HOME.path}
          />
          <PrivateRoute
            path={RouterPath.SIGN_IN.path}
            component={this._renderLazyComponent(SignIn)}
            condition={this._noAuthCondition}
            redirect={RouterPath.HOME.path}
          />
          <PrivateRoute
            path={RouterPath.LEARNING.path}
            condition={this._authCondition}
            component={this._renderNoLayout}
            redirect={getLoginUrl()}
          />
          <PrivateRoute
            path={RouterPath.SELECT_CHARRACTOR.path}
            condition={this._authCondition}
            component={this._renderNoSiderBar}
            redirect={getLoginUrl()}
          />
          <PrivateRoute
            path={RouterPath.TESTING.path}
            condition={this._authCondition}
            component={this._renderNoLayout}
            redirect={getLoginUrl()}
          />
          <PrivateRoute
            path={RouterPath.RESULT.path}
            condition={this._authCondition}
            component={this._renderNoLayout}
            redirect={getLoginUrl()}
          />
          <PrivateRoute
            path={RouterPath.TESTING.pathQuest}
            condition={this._authCondition}
            component={this._renderNoLayout}
            redirect={getLoginUrl()}
          />
          <PrivateRoute
            path="/"
            condition={this._authCondition}
            component={this._renderAuthRoutes}
            redirect={getLoginUrl()}
          />
        </Switch>
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  listCharactor: state.charactor.listCharactor,
  currentUser: state.home.currentUser
  // sidebarRight: state.sidebar.sidebarRight,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
