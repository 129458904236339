import {callApi, params} from "../../utils/commonUtils";
import Storage from "../../utils/storega";
import {getEnv} from "../../configs/env";
import {TYPES} from ".";

const updateStatus = (status, statusInfo) => ({
  type: status,
  data: statusInfo
});

const updateUserInfo = (payload, isLevelUp) => ({
  type: "UPDATE_USER_INFO_REQUEST",
  payload,
  isLevelUp
});

const getRanking = ({ gradeId }) => {
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_RANKING_REQUEST));
    callApi(
      `${getEnv(
        "REACT_APP_API_SERVER"
      )}/users/rankings/home_page/grades/${gradeId}`,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.GET_RANKING_SUCCESS, { data: data.data }));
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_RANKING_FAILED, { error: err }));
      }
    );
  };
};

const getQuests = (payload = {}) => {
  let url = `${getEnv("REACT_APP_API_SERVER")}/users/quests`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_QUESTS_REQUEST));
    callApi(
      url,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.GET_QUESTS_SUCCESS, { data: data.data }));
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_QUESTS_FAILED, { error: err }));
      }
    );
  };
};

const getRecentActivities = () => {
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_RECENT_ACTIVITIES_REQUEST));
    callApi(
      `${getEnv("REACT_APP_API_SERVER")}/users/blocks/recent_training`,
      config,
      null,
      (data) => {
        dispatch(
          updateStatus(TYPES.GET_RECENT_ACTIVITIES_SUCCESS, { data: data.data })
        );
      },
      (err) => {
        dispatch(
          updateStatus(TYPES.GET_RECENT_ACTIVITIES_FAILED, { error: err })
        );
      }
    );
  };
};

const getSubjects = (payload = {}) => {
  let url = `${getEnv("REACT_APP_API_SERVER")}/users/subjects/homepage`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_SUBJECT_HOMEPAGE_REQUEST));
    callApi(
      url,
      config,
      null,
      (data) => {
        dispatch(
          updateStatus(TYPES.GET_SUBJECT_HOMEPAGE_SUCCESS, { data: data.data })
        );
      },
      (err) => {
        dispatch(
          updateStatus(TYPES.GET_SUBJECT_HOMEPAGE_FAILED, { error: err })
        );
      }
    );
  };
};

const getGrades = () => {
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_GRADE_REQUEST));
    callApi(
      `${getEnv("REACT_APP_API_SERVER")}/users/grades/groups`,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.GET_GRADE_SUCCESS, { data: data.data }));
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_GRADE_FAILED, { error: err }));
      }
    );
  };
};

const getUserProfile = () => {
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_USER_PROFILE_REQUEST));
    callApi(
      `${getEnv("REACT_APP_API_SERVER")}/users/profile`,
      config,
      null,
      (data) => {
        dispatch(
          updateStatus(TYPES.GET_USER_PROFILE_SUCCESS, { data: data.data })
        );
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_USER_PROFILE_FAILED, { error: err }));
      }
    );
  };
};

const changeGrade = ({ grade }) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CHANGE_GRADE_REQUEST, { grade }));
  };
};

const setDefault = () => ({
  type: "SET_DEFAULT_REQUEST"
});

const updateSubSubjectList = ({ subject }) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.UPDATE_SUB_SUBJECT_LIST_REQUEST, { subject }));
  };
};

const getSubjectsHeader = (payload = {}) => {
  let url = `${getEnv("REACT_APP_API_SERVER")}/users/subjects/header`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_SUBJECT_HEADER_REQUEST));
    callApi(
      url,
      config,
      null,
      (data) => {
        dispatch(
          updateStatus(TYPES.GET_SUBJECT_HEADER_SUCCESS, { data: data.data })
        );
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_SUBJECT_HEADER_FAILED, { error: err }));
      }
    );
  };
};

const getMessageCharacter = ({
  characterId,
  conditionMessage,
  level,
  nickName
}) => {
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    callApi(
      `${getEnv(
        "REACT_APP_API_SERVER"
      )}/users/message_character?conditionMessage=${conditionMessage}&level=${level}`,
      config,
      null,
      (data) => {
        dispatch(
          updateStatus(TYPES.GET_MESSAGE_CHARACTER_SUCCESS, { data: data.data })
        );
      },
      (err) => {
        dispatch(
          updateStatus(TYPES.GET_MESSAGE_CHARACTER_FAILED, { error: err })
        );
      }
    );
  };
};

const getQuestTestingInfo = ({ blockId }) => {
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };

  return (dispatch) => {
    callApi(
      `${getEnv(
        "REACT_APP_API_SERVER"
      )}/users/quests/blocks/${blockId}/info_test`,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.GET_QUEST_TESTING_INFO_SUCCESS, { data }));
      },
      (err) => {
        dispatch(
          updateStatus(TYPES.GET_QUEST_TESTING_INFO_FAILED, { error: err })
        );
      }
    );
  };
};

export {
  getRanking,
  getQuests,
  getRecentActivities,
  getSubjects,
  getGrades,
  getUserProfile,
  changeGrade,
  updateUserInfo,
  updateSubSubjectList,
  getSubjectsHeader,
  getMessageCharacter,
  getQuestTestingInfo,
  setDefault
};
