import { TYPES } from '../actions';

const initialState = {
  status: null,
  error: null,
  loading: false,
  targetSubjectList: [],
  targetSubSubjectList: [],
  largeUnitAndLessonList: [],
  idx: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ACTIONS WITHOUT DATA WILL BE PLACES HERE
    case TYPES.UPDATE_NICKNAME_REQUEST:
    case TYPES.USER_SETTING_TARGET_REQUEST:
    case TYPES.GET_TARGET_SUBJECTS_REQUEST:
    case TYPES.GET_TARGET_SUB_SUBJECTS_REQUEST:
    case TYPES.GET_LARGE_UNIT_AND_LESSON_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true
      };

    // ACTIONS WITH DATA WILL BE PLACES HERE
    case TYPES.UPDATE_NICKNAME_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false
      };

    case TYPES.USER_SETTING_TARGET_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        idx: action.data.idx
      };

    case TYPES.RESET_LARGE_UNIT_AND_LESSON_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: false,
        largeUnitAndLessonList: []
      };

    case TYPES.GET_TARGET_SUBJECTS_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        targetSubjectList: action.data.data
      };

    case TYPES.GET_TARGET_SUB_SUBJECTS_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        targetSubSubjectList: action.data.data
      };

    case TYPES.GET_LARGE_UNIT_AND_LESSON_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        largeUnitAndLessonList: action.data.data
      };

    case TYPES.RESET_SETTINGS:
      return {
        ...state,
        status: action.type,
      };

    // ACTION FAILED WILL BE PLACED HERE
    case TYPES.UPDATE_NICKNAME_FAILED:
    case TYPES.USER_SETTING_TARGET_FAILED:
    case TYPES.GET_TARGET_SUBJECTS_FAILED:
    case TYPES.GET_TARGET_SUB_SUBJECTS_FAILED:
    case TYPES.GET_LARGE_UNIT_AND_LESSON_FAILED:
      return {
        ...state,
        status: '',
        error: '',
        loading: false
      };

    default:
      return state;
  }
};
