import { TYPES } from "../actions";

const initialState = {
  status: null,
  error: null,
  sidebar: false,
  sidebarRight: false,
  isEditProfile: false,
  isSettingTarget: false,
  loading: false,
  modalGrade: false,
  modalGradeNavigation: false,
  isRedirectPage: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.TOGGLE_SIDEBAR_REQUEST:
      return {
        ...state,
        status: action.type,
        sidebar: !state.sidebar
      };

    case TYPES.TOGGLE_SIDEBAR_RIGHT_REQUEST:
      return {
        ...state,
        status: action.type,
        sidebarRight: !state.sidebarRight,
        isEditProfile: !state.sidebarRight === false ? false : state.isEditProfile,
        isSettingTarget: !state.sidebarRight === false ? false : state.isSettingTarget,
      };

    case TYPES.EDIT_PROFILE_REQUEST:
      return {
        ...state,
        status: action.type,
        isEditProfile: !state.isEditProfile
      };

    case TYPES.SETTING_TARGET_REQUEST:
      return {
        ...state,
        status: action.type,
        isSettingTarget: !state.isSettingTarget
      };

    case TYPES.SHOW_MODAL_GRADE_REQUEST:
      return {
        ...state,
        status: action.type,
        modalGrade: !state.modalGrade,
        isRedirectPage: true
      };

    case TYPES.SHOW_MODAL_GRADE_NAVIGATION_REQUEST:
      return {
        ...state,
        status: action.type,
        modalGrade: !state.modalGradeNavigation,
        isRedirectPage: false
      };

    default:
      return state;
  }
};