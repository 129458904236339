import { callApiNoToken } from "../../utils/commonUtils";
import { getEnv } from "../../configs/env";
import { TYPES } from "./index";

const updateStatus = (status, statusInfo) => ({
  type: status,
  data: statusInfo
});

const login = (payload) => {
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({
      username: payload.username,
      password: payload.password,
      schoolId: payload.schoolId || '0001',
      systemCode: payload.schoolId ? 2 : 1
    })
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LOGIN_REQUEST));
    callApiNoToken(
      `${getEnv("REACT_APP_API_SERVER")}/users/auth/login`,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.LOGIN_SUCCESS, { user: data.data }));
      },
      (err) => {
        dispatch(updateStatus(TYPES.LOGIN_FAILED, { error: err }));
      }
    );
  };
};

const ssoTokenVerify = ({ ssoToken, systemCode }) => {
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({ ssoToken, systemCode })
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.SSO_TOKEN_VERIFY_REQUEST));
    callApiNoToken(
      `${getEnv("REACT_APP_API_SERVER")}/ssoTokenVerify`,
      config,
      null,
      (data) => {
        dispatch(
          updateStatus(TYPES.SSO_TOKEN_VERIFY_SUCCESS, { user: data.data })
        );
      },
      (err) => {
        dispatch(updateStatus(TYPES.SSO_TOKEN_VERIFY_FAILED, { error: err }));
      }
    );
  };
};

const logout = (payload) => ({
  type: "LOGOUT",
  payload
});

const checkMaintain = () => {
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CHECK_MAINTAIN_REQUEST));
    callApiNoToken(
      `${getEnv('REACT_APP_API_SERVER')}/admins/configure/system_state`,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.CHECK_MAINTAIN_SUCCESS, { maintain: data.data }));
      },
      (err) => {
        dispatch(updateStatus(TYPES.CHECK_MAINTAIN_FAILED, { error: err }));
      }
    );
  };
}

export { login, ssoTokenVerify, logout, checkMaintain };
