import styled from "styled-components";
import { textGrayDefault } from "../Text/style";

export const LayoutWrapper = styled.div`
  ${textGrayDefault}
  position: relative;
  ${({ isscrollinpage }) => {
    if (isscrollinpage === 'true') {
      return 'height: 100%;';
    }
    return `
      min-height: 100vh;
    `;
  }}
  font-weight: 500;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
    overflow: hidden;
  }
`;

export const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.backgroundMainColor};
  /* height: 100%; */
  flex: 1;
  position: relative;
  transition: all .3s;
  & .mt-content {
    padding-top: ${({ theme }) => theme.contentPadding * 2}px;
    @media screen and (max-width: ${({ theme }) => theme.maxWidthMobile}px) {
      padding-bottom: ${({ theme }) => theme.contentPadding * 2}px;
    }
  }

  .px-content {
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .btn {
    padding: 7px 5px;
  }
  
  width: calc(100% - ${({ $toggle, theme }) => $toggle ? `${theme.sidebarWidthSmall}px` : '15%'});
  @media screen and (max-width: ${({ theme }) => theme.macWidth}px) {
    width: calc(100% - ${({ $toggle, theme }) => $toggle ? `${theme.sidebarWidthSmall}px` : `${theme.sidebarMacWidth}px`});
  }
  @media (max-width: ${({theme}) => theme.tabletWidth}px) {
    min-width: 100vw;
    width: calc(100% - ( ${({ $toggle, theme }) => $toggle ? theme.sidebarMacWidthSmall : theme.sidebarMacWidth}px )) ;
  }
  @media (max-width: ${({theme}) => theme.mobileWidth}px) {
    min-width: 100vw;
  }
  
  &.no-sidebar {
    width: 100%;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  height: calc(100% - ${({ theme }) => theme.headerHeight}px);
`;

export const AppNoLayoutStyle = styled.div`
  color: ${({ theme }) => theme.textSecondary};
  background: ${({ theme }) => theme.backgroundMainColor};
  /* height: 100%; */

  @media (max-width: ${({theme}) => theme.homepageMaxWidth}px) {
    height: auto;
  }
  min-height: ${({ isresult }) => isresult === 'true' ? 'unset' : '100%'};
  display: flex;
  @media (max-width: ${({ theme }) => theme.largeBoostrap}px) {
    height: auto;
    min-height: 100%;
  }
  @media (min-width: ${({ theme }) => theme.tabletMaxWidth}px\0/) {
    height: 100%;
    min-height: 100%;
  }
`;
export const AppStyle = styled.div`
  color: ${({ theme }) => theme.textSecondary};
  background: ${({ theme }) => theme.backgroundMainColor};
  height: 100%;
`;
