/* eslint-disable radix */
import {useCallback, useEffect, useState} from "react";
import Storage from "../utils/storega";
import {TYPES} from "../store/actions";
import RouterPath from "../constants/router-path";

const useChangeGrade = ({
  gradeList,
  changeGrade,
  headerSubjectList,
  headerSubSubjectList,
  showModalGrade,
  history,
  updateSubSubjectList,
  statusHome,
  getSubjectsHeader,
  isRedirectPage
}) => {
  const [selectedGrade, setSelectedGrade] = useState(0);
  const [selectedSubject, setSelectedSubject] = useState(0);
  const [selectedSubSubject, setSelectedSubSubject] = useState(0);
  const localGrade = Storage.get("USER_SELECT_GRADE");
  const [maitain, setMaitain] = useState(false);

  const handleChangeSelectedGrade = useCallback(
    (index, e) => {
      let params;
      const type = Storage.get('MENU_TYPE');
      const currentType = Storage.get('CURRENT_MENU_TYPE');
      if(e?.target && e?.target?.value) {
        setSelectedGrade(gradeList.findIndex(item => item.groupCode === parseInt(e.target.value)));
        params = { gradeGroupCode: parseInt(e.target.value), menu: type || currentType };
      } else {
        setSelectedGrade(index);
        params = { gradeGroupCode: gradeList[index]?.groupCode, menu: type || currentType };
      }
      getSubjectsHeader({ params });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gradeList]
  );

  const toLessons = useCallback(async (type) => {
    const getMaitain = await headerSubjectList[selectedSubject]?.subSubjectList?.find(i => i.id === selectedSubSubject);
    if (getMaitain.isMaintain) {
      setMaitain(getMaitain.isMaintain)
    } else {
      history.push({
        pathname: RouterPath.LESSONS.pathType({
          gradeId: gradeList[selectedGrade]?.groupCode,
          subjectId: headerSubjectList[selectedSubject]?.subjectId,
          subSubjectId: selectedSubSubject,
          type
        })
      });
    }
  }, [gradeList, headerSubjectList, history, selectedGrade, selectedSubject, selectedSubSubject]);

  const handleChangeGrade = useCallback((type) => {
    changeGrade({
      grade: {
        ...gradeList[selectedGrade],
        subject: headerSubjectList[selectedSubject],
        subSubject: headerSubSubjectList.find((i) => i.id === selectedSubSubject)
      }
    });
    Storage.set("USER_SELECT_GRADE", {
      grade: {
        ...gradeList[selectedGrade],
        gradeLC: selectedGrade
      },
      subject: {
        ...headerSubjectList[selectedSubject],
        subjectLC: headerSubjectList[selectedSubject] ? selectedSubject : 0
      },
      subSubject: headerSubSubjectList.find((i) => i.id === selectedSubSubject)
    });
    showModalGrade();
    if (!headerSubjectList.length) {
      setSelectedSubject(0);
      setSelectedSubSubject(0);
      const state = { ...history.location.state };
      delete state.gradeId;
      delete state.subjectId;
      delete state.subSubjectId;
      history.replace({ ...history.location, state });
    }
    if (isRedirectPage) toLessons(type);
  }, [
    selectedGrade,
    changeGrade,
    gradeList,
    showModalGrade,
    selectedSubject,
    selectedSubSubject,
    headerSubjectList,
    headerSubSubjectList,
    isRedirectPage,
    history,
    toLessons
  ]);

  const handleChangeSelectedSubject = useCallback((index) => {
    setSelectedSubject(index);
    const subject = headerSubjectList[index];
    if (subject && subject.subSubjectList) {
      const subSubject = subject.subSubjectList.find(i => i.totalLesson > 0);
      if (subSubject) setSelectedSubSubject(subSubject.id);
      else setSelectedSubSubject(null);
      updateSubSubjectList({ subject });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerSubjectList]);

  useEffect(() => {
    if (statusHome === TYPES.GET_SUBJECT_HEADER_SUCCESS) {
      if (headerSubjectList?.length > 0) {
        const checkSubSubject = headerSubjectList.find(i => i.subjectId === localGrade.subject?.subjectId);

        if (checkSubSubject === undefined) {
          updateSubSubjectList({ subject: headerSubjectList[0]});

          // Neu sub subject dau tien bi disable thi tim thang khac tiep theo k bi disable
          const firstSubSubjectList = headerSubjectList[0]?.subSubjectList;
          if (firstSubSubjectList?.length) {
            const subSubject = firstSubSubjectList.find(i => i.totalLesson > 0);
            if (subSubject) setSelectedSubSubject(subSubject.id);
            else setSelectedSubSubject(null);
          } else setSelectedSubSubject(null);

          setSelectedSubject(0);
        } else {
          updateSubSubjectList({ subject: checkSubSubject });

          const indexSubject = headerSubjectList?.findIndex(i => i.subSubjectList.findIndex(ii => ii.id === localGrade.subSubject?.id) !== -1)
          const indexSubSubject = headerSubjectList[indexSubject]?.subSubjectList?.findIndex(i => i.id === localGrade.subSubject?.id)
          if (indexSubSubject > -1) {
            const totalLesson = headerSubjectList[indexSubject]?.subSubjectList[indexSubSubject]?.totalLesson;
            if (totalLesson > 0) setSelectedSubSubject(localGrade.subSubject?.id)

            // Neu sub subject da chon bi disable thi tim thang khac tiep theo k bi disable
            else {
              const nextIndex = headerSubjectList[indexSubject]?.subSubjectList?.find(i => i.totalLesson > 0);
              if (nextIndex) setSelectedSubSubject(nextIndex.id);
              else setSelectedSubSubject(null);
            }
          } else setSelectedSubSubject(null);

          setSelectedSubject(localGrade.subject?.subjectLC);
        }
      } else if (headerSubSubjectList.length) {
        setSelectedSubSubject(headerSubSubjectList[0]?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[statusHome]);

  const handleSelectSubSubject = useCallback((e) => {
    setSelectedSubSubject(Number(e.target.value));
  }, []);

  const handleClose = () =>{
    setMaitain(false);
    showModalGrade();
  }

  return {
    selectedGrade,
    setSelectedGrade,
    selectedSubject,
    setSelectedSubject,
    selectedSubSubject,
    setSelectedSubSubject,
    localGrade,
    handleChangeSelectedGrade,
    handleChangeGrade,
    handleChangeSelectedSubject,
    handleSelectSubSubject,
    maitain,
    handleClose
  }
};

export default useChangeGrade;