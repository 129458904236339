export default [
  // UI
  "ENQUEUE_SNACKBAR",
  // AUTH
  "SIGN_IN",
  "LOGIN",
  "GET_USER_PROFILE",
  "SSO_TOKEN_VERIFY",
  "LOGOUT",
  "CHECK_MAINTAIN",
  // TESTING
  "NEXT_QUESTION",
  "GET_QUESTION",
  "SAVE_ANSWERS",
  "RESET_TESTING",
  "SUBMIT_ANSWERS",
  "GET_OFTEN_WRONG_QUESTION",
  "ADD_EXAMS_QUESTION_WRONG",
  "GET_QUESTION_WRONG_LIST",
  "GET_NEXT_BLOCK",
  // LEARNING
  "LEARNING",
  "VOCAL_GROUPS",
  "SEARCH_GROUPS",
  "CREATE_GROUP",
  "ADD_WORD_GROUP",
  "INFOR_TEST",
  "DEL_WORD_GROUP",
  "RESET_LEARNING",
  // CHAPTER,
  "GET_LARGE_UNIT",
  "GET_LARGE_LESSON",
  "GET_LARGE_BLOCK",
  "RESET_DATA",
  "CHANGE_GRADE_LESSONS",
  "RESET_LESSONS",
  "GET_TESTING_INFO",
  // PROGRESS
  "PROGRESS",
  "FRIEND_PROGRESS",
  "FRIEND_REQUEST",
  "SEARCH_FRIEND",
  "ACCEPT_REQUEST",
  "REJECT_REQUEST",
  "DELETE_FRIEND",
  "SEND_FRIEND",
  "RESET_FRIEND_SEARCH_DATA",
  "PROGRESS_SUBJECT",
  // MY_NOTE
  "MY_NOTE",
  "EDIT_GROUP",
  "DEL_GROUP",
  "DEL_WORD_DETAILS",
  "ADD_WORD_DETAIL",
  "EDIT_WORD_DETAILS",
  "AUTO_WORDS",
  "UPDATE_CURRENT_VOCAL_GROUP",
  "DEL_GROUP_MY_NOTE",
  "CLEAR_AUTO_WORDS",
  "CHECK_OUT_SIDE",
  "ACCEPT_OUT_SIDE",
  // SIDEBAR
  "TOGGLE_SIDEBAR",
  "TOGGLE_SIDEBAR_RIGHT",
  "EDIT_PROFILE",
  "SETTING_TARGET",
  "GET_TARGET_SUBJECTS",
  "GET_TARGET_SUB_SUBJECTS",
  "GET_LARGE_UNIT_AND_LESSON",
  "RESET_LARGE_UNIT_AND_LESSON",
  "SHOW_MODAL_GRADE",
  "SHOW_MODAL_GRADE_NAVIGATION",
  // SETTINGS
  "UPDATE_NICKNAME",
  "USER_SETTING_TARGET",
  "SET_UNSUBMIT_TEST_REQUEST",
  "RESET_SETTINGS",
  // HOME
  "GET_RANKING",
  "GET_QUESTS",
  "GET_RECENT_ACTIVITIES",
  "GET_SUBJECT_HOMEPAGE",
  "GET_GRADE",
  "CHANGE_GRADE",
  "UPDATE_USER_INFO_REQUEST",
  "UPDATE_SUB_SUBJECT_LIST",
  "GET_SUBJECT_HEADER",
  "GET_MESSAGE_CHARACTER",
  "GET_QUEST_TESTING_INFO",
  "SET_DEFAULT",
  // Ranking
  "GET_USER_GRADES",
  "GET_USER_RANKING",
  "RESET_RANKING",
  // OFTEN_WRONG
  "GET_OFTEN_WRONG",
  "GET_SIMILAR_WORD",
  "INFOR_TEST_OFTEN_WRONG",
  "CREATE_BLOCK_OFTEN_WRONG",
  "GET_SUBJECT_OFTEN_WRONG",
  // CHARACTOR
  "GET_CHARACTOR",
  "SELECT_CHARACTOR"
];
