import _ from "lodash";
import { TYPES } from "../actions";

const answerFormatList = {
  options: "4T" || "4G",
  yesNo: "O",
  input: "I",
  sort: "R"
};

const INIT_STATE = {
  questions: [],
  currentQuestion: 0,
  totalQuestion: 0,
  testingAnswers: [],
  mode: 0,
  userLearningHistoriesId: 0,
  resultTesting: [],
  isSubmited: false,
  dataLink: {},
  status: "",
  levelUpInfo: {},
  largeUnitQuestion: '',
  blockIdTestingQuestionWrong: null,
  isLoading: false,
  nextExamBlock: {},
  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // ACTIONS WITHOUT DATA WILL BE PLACES HERE
    case TYPES.SUBMIT_ANSWERS_REQUEST:
      return {
        ...state,
        status: action.type,
        isLoading: true
      };
    case TYPES.GET_NEXT_BLOCK_REQUEST:
      return {
        ...state,
        status: action.type
      };

    case TYPES.GET_QUESTION_SUCCESS:
      const questions = action.data.data.data;
      const isShuffleQuestion = (action.data.isShuffleQuestion === 'true');
      let shuffleQuestionArray = questions.questionDTOList;

      if (isShuffleQuestion) {
        shuffleQuestionArray = _.shuffle(shuffleQuestionArray);
      }

      const newQuestionArray = shuffleQuestionArray.map((question, index) => {
        let options = _.shuffle([
          { value: question.correctAws, label: question.correctAws },
          { value: question.wrongAws1, label: question.wrongAws1 },
          { value: question.wrongAws2, label: question.wrongAws2 },
          { value: question.wrongAws3, label: question.wrongAws3 }
        ]);

        const aswFormat = question.aswFormat.slice(
          0,
          question.aswFormat.indexOf(":")
        );

        if (aswFormat === answerFormatList.yesNo) {
          options = [
            { value: "O", label: "○" },
            { value: "X", label: "×" }
          ];
        } else if (aswFormat === answerFormatList.sort) {
          options = [
            {
              value: question.correctAws,
              label: _.shuffle(question.correctAws.split(""))
            }
          ];
        }
        return {
          ...question,
          questionNumber: index + 1,
          options,
          aswFormat
        };
      });

      return {
        ...state,
        status: action.type,
        questions: newQuestionArray,
        totalQuestion: questions.questionDTOList.length,
        mode: questions.mode,
        userLearningHistoriesId: questions.userLearningHistoriesDTO.id,
        dataLink:
          questions.block === null
            ? null
            : {
                dataBlock: questions.block,
                dataUnit: questions.largeUnit,
                dataLesson: questions.lesson,
                dataGrade: questions.grade,
                dataSubject: questions.subject,
                dataSubSubject: questions.subSubject
              }
      };

    case TYPES.GET_OFTEN_WRONG_QUESTION_SUCCESS:
      const oftenWrongQuestions = action.data.data.data;
      const isShuffleOftenWrongQuestion = (action.data.isShuffleQuestion === 'true');
      const dataLink = action.data.hasDataLink && oftenWrongQuestions.block ?
        {
          dataBlock: oftenWrongQuestions.block,
          dataUnit: oftenWrongQuestions.largeUnit,
          dataLesson: oftenWrongQuestions.lesson,
          dataGrade: oftenWrongQuestions.grade,
          dataSubject: oftenWrongQuestions.subject,
          dataSubSubject: oftenWrongQuestions.subSubject
        } : null;
      let shuffleOftenWrongQuestionArray = oftenWrongQuestions.questionDTOList;

      if (isShuffleOftenWrongQuestion) {
        shuffleOftenWrongQuestionArray = _.shuffle(shuffleOftenWrongQuestionArray);
      }

      const newOftenWrongQuestionArray = shuffleOftenWrongQuestionArray.map(
        (question, index) => {
          let options = _.shuffle([
            { value: question.correctAws, label: question.correctAws },
            { value: question.wrongAws1, label: question.wrongAws1 },
            { value: question.wrongAws2, label: question.wrongAws2 },
            { value: question.wrongAws3, label: question.wrongAws3 }
          ]);

          const aswFormat = question.aswFormat.slice(
            0,
            question.aswFormat.indexOf(":")
          );

          if (aswFormat === answerFormatList.yesNo) {
            options = [
              { value: "O", label: "○" },
              { value: "X", label: "×" }
            ];
          } else if (aswFormat === answerFormatList.sort) {
            options = [
              {
                value: question.correctAws,
                label: _.shuffle(question.correctAws.split(""))
              }
            ];
          }
          return {
            ...question,
            questionNumber: index + 1,
            options,
            aswFormat
          };
        }
      );

      return {
        ...state,
        status: action.type,
        questions: newOftenWrongQuestionArray,
        totalQuestion: oftenWrongQuestions.questionDTOList.length,
        mode: oftenWrongQuestions.mode,
        userLearningHistoriesId:
          oftenWrongQuestions.userLearningHistoriesDTO.id,
        dataLink
      };
    case TYPES.NEXT_QUESTION_REQUEST:
      return {
        ...state,
        status: action.type,
        currentQuestion:
          state.currentQuestion === state.questions.length - 1
            ? state.questions.length - 1
            : state.currentQuestion + 1
      };

    case TYPES.PREV_QUESTION_REQUEST:
      return {
        ...state,
        status: action.type,
        currentQuestion:
          state.currentQuestion === 0
            ? state.currentQuestion
            : state.currentQuestion - 1
      };
    case TYPES.SAVE_ANSWERS_REQUEST:
      const { questionId, answer, blockId } = action.payload;
      const answerArray = [...state.testingAnswers];
      const questionIndex = state.testingAnswers.findIndex(
        (question) => question.questionId === questionId
      );

      if (questionIndex !== -1) {
        answerArray[questionIndex] = {
          questionId,
          answer
        };
        if (blockId) answerArray[questionIndex].blockId = blockId;
      } else {
        const answerData = { questionId, answer };
        if (blockId) answerData.blockId = blockId;
        answerArray.push(answerData);
      }

      return {
        ...state,
        status: action.type,
        testingAnswers: [...answerArray]
      };
    case TYPES.RESET_TESTING_REQUEST:
      return {
        ...INIT_STATE
      };
    case TYPES.SET_UNSUBMIT_TEST_REQUEST:
      return {
        ...state,
        isSubmited: false,
        isLoading: true
      };

    case TYPES.ADD_EXAMS_QUESTION_WRONG_REQUEST:
      return {
        ...state,
        status: action.type
      };

    case TYPES.SUBMIT_ANSWERS_SUCCESS:
      const result = action.data.data.data;
      const newResultArray = state.questions.map((question) => {
        const resultItem = result.questionResultDTOS.find(
          (item) => item.questionId === question.id
        );
        if (resultItem) {
          return {
            ...resultItem,
            status: action.type,
            correctAws: question.correctAws,
            questionContent: question.questionContent,
            questionDirective: question.questionDirective,
            options: question.options,
            aswFormat: question.aswFormat,
            questionNumber: question.questionNumber,
            questionImageFile: question.questionImageFile,
            questionSoundFile: question.questionSoundFile,
            explanationContent: question.explanationContent,
            explanationImageFile: question.explanationImageFile,
            questionFormat: question.questionFormat
          };
        }
        return { ...question };
      });

      return {
        ...state,
        resultTesting: {
          resultTestingList: newResultArray,
          correctQuestionNum: result.correctQuestionNum,
          doneQuestionNum: result.doneQuestionNum,
          executionTime: result.executionTime,
          progressNum: Math.round(
            (result.correctQuestionNum / state.totalQuestion) * 100
          ),
          totalQuestion: state.totalQuestion
        },
        levelUpInfo: result.levelUpDTO,
        isSubmited: true,
        isLoading: false,
        testingAnswers: [],
        currentQuestion: 0,
        questions: [],
        totalQuestion: 0,
        status: action.type,
        largeUnitQuestion: result.largeUnit?.name,
        dataLink:
          result.block === null
            ? null
            : {
                dataBlock: result.block,
                dataUnit: result.largeUnit,
                dataLesson: result.lesson,
                dataGrade: result.grade,
                dataSubject: result.subject,
                dataSubSubject: result.subSubject
              }
      };

    case TYPES.ADD_EXAMS_QUESTION_WRONG_SUCCESS:
      return {
        ...state,
        status: action.type,
        blockIdTestingQuestionWrong: action.data.data?.id
      };

    case TYPES.GET_NEXT_BLOCK_SUCCESS:
      return {
        ...state,
        status: action.type,
        nextExamBlock: action.data.data
      }

    case TYPES.GET_QUESTION_FAILED:
    case TYPES.SUBMIT_ANSWERS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error?.messageId || action.data.error?.message
      }  
    default:
      return state;
  }
};
