import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {textBlackBold, textBlackMedium} from "../Text/style";

const flexBox = css`
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  height: ${({ theme }) => theme.headerHeight}px;
  background-color: #fff;
  nav {
    padding-left: 2rem;
    padding-right: 1.5rem;
  }
  
  .logo-text {
    ${textBlackMedium}
    text-decoration: none;
    padding-left: 30px;
    
    @media screen and (max-width: ${({theme}) => theme.homepageMaxWidth}px) {
      padding-left: 12px;
    }

    @media screen and (max-width: ${({theme}) => theme.mobileWidthExtraSmall}px) {
      padding-left: 8px;
    }
  }
  .navbar {
    height: ${({ theme }) => theme.headerHeight}px;
  }
  .nick-name {
    max-width: 290px;
  }

  .exp-group {
      .exp {
        margin-right: 4px;
      }
    }
  
  @media (min-width: ${({theme}) => theme.tabletWidth}px) and (max-width: ${({theme}) => theme.iPadMaxWidth}px) {
    nav {
      padding-right: 15px;
    }
    .exp-group {
      font-size: 14px;
      img {
        width: 20px;
      }
    }
  }
  @media (min-width: ${({theme}) => theme.tabletWidth}px) and (max-width: 810px) {
    .exp-group {
      .exp {
        max-width: 34px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  @media (max-width: ${({theme}) => theme.tabletWidth}px) {
    ${({ $toggle, theme }) => !$toggle && `
      max-width: ${theme.tabletWidth}px;
      min-width: 575px;
      width: auto;
      display: flex;
    `}
    .logo-text {
      display: ${({ $toggle }) => $toggle ? 'block' : 'none'};
      width: 46px;
      overflow: hidden;
    }
    .desktop-nav-icon {
      display: block;
    }
    .exp-group {
      display: none;
    }
    .navbar {
      padding-top: 12px;
      padding-left: 15px;
      padding-right: 15px;
      flex: 1 1 auto;
    }
  }

  @media (max-width: ${({theme}) => theme.mobileWidthExtraSmall}px) {
    .navbar {
      padding-left: 10px;
      padding-right: 10px;
    }
  }  

  @media (min-width: ${({theme}) => theme.maxWidthMobile}px) and (max-width: ${({theme}) => theme.tabletWidth}px) {
    .nick-name {
      max-width: 80px;
    }
  }
  @media (min-width: ${({theme}) => theme.iPadMinWidth}px) and (max-width: 889px) {
    .nick-name {
      max-width: 100px;
    }
  }
  @media (min-width: 890px) and  (max-width: ${({theme}) => theme.largeBoostrap}px) {
    .nick-name {
      max-width: 160px;
    }
  }
`;

export const Logo = styled.img`
  width: auto;
  height: 40px;
  margin-right: 5px;
  
  @media screen and (max-width: ${({theme}) => theme.homepageMaxWidth}px) {
    width: 125px;
    object-fit: contain;
  }
`;

export const FlexBetween = styled.div`
  ${flexBox}
  justify-content: space-between;
  width: calc(${({ theme }) => theme.sidebarWidth}px - 130px);
  padding-right: 1rem;
`;

export const NavigationIcon = styled.img`
  width: 25px;
  cursor: pointer;
`;

export const ContentRight = styled.div`
  ${flexBox}
  flex-direction: row-reverse;

  @media (max-width: ${({theme}) => theme.tabletWidth}px) {
    .user-image {
      width: 40px;
      height: 40px;
    }
  }

  @media (max-width: ${({theme}) => theme.mobileWidthExtraSmall}px) {
    .user-image {
      width: 32px;
      height: 32px;
    }
  }
`;

export const ContentGroup = styled.div`
  ${flexBox}
  position: relative;
  &:not(:first-child) {
    margin-right: 2rem;
    .subject-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .group-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media (min-width: ${({theme}) => theme.tabletWidth}px) and (max-width: 810px) {
      .subject-name {
        max-width: 92px;
      }
      .group-name {
        max-width: 50px;
      }
    }
    @media (min-width: 850px) and (max-width: ${({theme}) => theme.iPadMaxWidth}px) {
      margin-right: 1rem;
    }
    @media (min-width: ${({theme}) => theme.tabletWidth}px) and (max-width: 850px) {
      margin-right: 8px;
    }
    @media (max-width: ${({theme}) => theme.tabletWidth}px) {
      margin-right: 6px;
    }
    @media (min-width: 420px) and (max-width: 470px) {
      .subject-name {
        max-width: 115px;
      }
      .group-name {
        max-width: 60px;
      }
    }
    @media (max-width: 420px) {
      .subject-name {
        max-width: 85px;
      }
      .group-name {
        max-width: 55px;
      }
    }
    @media (max-width: 360px) {
      .subject-name {
        max-width: 75px;
      }
      .group-name {
        max-width: 40px;
      }
    }
    @media (max-width: 340px) {
      margin-right: 6px;
      .subject-name {
        max-width: 70px;
      }
      .group-name {
        max-width: 35px;
      }
    }
  }
  
  &.cursor {
    cursor: pointer;
  }
`;

export const UserImage = styled.img`
  width: ${({ width }) => width || '20'}px;
  height: ${({ width }) => width || '20'}px;
  border-radius: 50%;
  cursor: pointer;
`;

export const UserName = styled.div`
  ${textBlackBold}
  margin-right: .75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: ${({theme}) => theme.tabletWidth}px) and (max-width: ${({theme}) => theme.iPadMaxWidth}px) {
    font-size: 14px;
  }
  @media (min-width: ${({theme}) => theme.tabletWidth}px) and (max-width: 810px) {
    max-width: 90px;
  }
  @media (max-width: ${({theme}) => theme.tabletWidth}px) {
    display: none;
  }
`;

export const CustomIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  width: 20px !important;
  cursor: pointer;
  color: ${({ color }) => color || 'inherit'};
  @media (max-width: ${({theme}) => theme.mobileWidthExtraSmall}px) {}
  width: 10px !important;
`;

export const MobileLogo = styled.div`
  display: none;
  @media (max-width: ${({theme}) => theme.tabletWidth}px) {
    display: ${({ $toggle }) => $toggle ? 'none' : 'flex'};
    flex: 0 0 200px;
    z-index: 2;
    text-align: center;
    align-items: center;
    justify-content: center;
    
    .mobile-logo-text {
      text-decoration: none;
    }
  }
`;

export const TextDark = styled.span`
  ${textBlackBold}
`;

export const SelectGroup = styled.div`
  ${flexBox}
  padding: .3rem .4rem .3rem .8rem;
  border: 2px solid ${({ theme }) => theme.selectGroupBorderColor};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.selectGroupBackgroundColor};
  @media (min-width: ${({theme}) => theme.tabletWidth}px) and (max-width: ${({theme}) => theme.iPadMaxWidth}px) {
    font-size: 14px;
  }
  @media (max-width: 450px) {
    padding: .3rem 0 .3rem .3rem;
  }
  @media (max-width: 420px) {
    padding: .3rem 0 .3rem .3rem;
    font-size: 10px;
  }
  
  &:hover {
    box-shadow: 0 0 3px 0 #ccc;
  }
`;

export const SelectItem = styled.div`
  ${flexBox}
`;

export const Divider = styled.div`
  border-left: 2px solid ${({ theme }) => theme.selectGroupBorderColor};
  height: 23px;
  width: 2px;
  margin: 0 12px;
  @media (max-width: 410px) {
    margin: 0 4px;
  }
`;

export const RedDot = styled.div`
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
  top: 0;
  right: 0;
`;

export const ChangeGradeWrapper = styled.div`
  padding: 1rem 0; 

  .btn-group {
    max-width: 100%;
    width: 100%;
    
    &:first-child {
      margin-bottom: 2rem;
    }
  }
  
  .modal-btn {
    min-width: 100px;
  }
`;

export const SubSubjectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1.5rem 2rem;
  background-color: #F3F6FF;
  justify-content: space-between;

  .form-check {
    width: ${({isWrap}) => isWrap ? '32%' : 'auto'};
    margin-bottom: .5rem;
    overflow: visible;
  }
  
  .form-check-input {
    width: 20px;
    height: 23px;
    margin-top: .15rem;
    margin-left: -1.5rem;
  }

  @media (max-width: ${({theme}) => theme.maxWidthMobile}px) {
    padding: 1.5rem .5rem;
    font-size: 1rem;
    align-items: flex-start;

    .form-check-label {
      font-size: 15px;
    }

    .form-check-input {
      width: 18px;
      height: 18px;
      margin-top: .1rem;
      margin-left: -1.25rem;
    }
  }
`;

export const MenuExplain = styled.div`
  display: none;
  cursor: default;
  
  @media screen and (min-width: ${({theme}) => theme.macWidth}px) {
    display: flex;
  }
  
  img {
    object-fit: contain;
  }
`;
