import React from "react";
import { MainButton } from "../components/Button/style";

export const friendStatus = {
  REQUEST: {
    VALUE: 0,
    TEXT: "フォロー"
  },
  WAITING: {
    VALUE: 1,
    TEXT: "送信済み"
  },
  FRIEND: {
    VALUE: 2,
    TEXT: "友達"
  }
};

export const renderButton = (status, handleClick, sttBtn) => {
  let button;
  switch (status) {
    case friendStatus.WAITING.VALUE:
      button = (
        <MainButton
          outline
          className="table-btn"
          color="primary"
          size="sm"
          disabled
        >
          {friendStatus.WAITING.TEXT}
        </MainButton>
      );
      break;
    case friendStatus.FRIEND.VALUE:
      button = (
        <MainButton
          outline
          className="table-btn"
          color="primary"
          size="sm"
          disabled
        >
          {friendStatus.FRIEND.TEXT}
        </MainButton>
      );
      break;
    default:
      button = (
        <MainButton
          className="table-btn"
          color="primary"
          size="sm"
          onClick={handleClick}
          disabled={sttBtn}
        >
          {friendStatus.REQUEST.TEXT}
        </MainButton>
      );
      break;
  }
  return button;
};
