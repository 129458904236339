import { TYPES } from '../actions';
import {friendStatus} from "../../constants/friendStatus";

const initialState = {
  status: null,
  error: null,
  progressList: [],
  friendProgressData: {},
  friendRequestData: {},
  currentPage: 0,
  currentPageRequest: 0,
  totalPageRequest: 0,
  totalPage: 0,
  friendSearchData: {},
  totalRequest: 0,
  progressSubjectList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ACTIONS WITHOUT DATA WILL BE PLACES HERE
    case TYPES.PROGRESS_REQUEST:
    case TYPES.SEARCH_FRIEND_REQUEST:
    case TYPES.ACCEPT_REQUEST_REQUEST:
    case TYPES.DELETE_FRIEND_REQUEST:
    case TYPES.FRIEND_PROGRESS_REQUEST:
    case TYPES.FRIEND_REQUEST_REQUEST:
    case TYPES.SEND_FRIEND_REQUEST:
    case TYPES.REJECT_REQUEST_REQUEST:
    case TYPES.PROGRESS_SUBJECT_REQUEST:
      return {
        ...state,
        status: action.type
      };

    // ACTIONS WITH DATA WILL BE PLACES HERE
    case TYPES.PROGRESS_SUCCESS:
      return {
        ...state,
        status: action.type,
        progressList: action.data.progress
      };

    case TYPES.FRIEND_PROGRESS_SUCCESS:
      return {
        ...state,
        status: action.type,
        friendProgressData: action.data.progress,
        currentPage: action.data.progress.page.currentPage,
        totalPage: action.data.progress.page.totalPage
      };

    case TYPES.FRIEND_REQUEST_SUCCESS:
      return {
        ...state,
        status: action.type,
        friendRequestData: action.data.progress,
        currentPageRequest: action.data.progress.page.currentPage,
        totalPageRequest: action.data.progress.page.totalPage,
        totalRequest: action.data.progress.page.totalElement
      };

    case TYPES.SEARCH_FRIEND_SUCCESS:
      return {
        ...state,
        status: action.type,
        friendSearchData: action.data.progress
      };

    case TYPES.ACCEPT_REQUEST_SUCCESS:
      const requestId = action.data.id;
      const newFriendRequestList = state.friendRequestData.friendList.filter(i => i.id !== requestId);

      return {
        ...state,
        status: action.type,
        friendRequestData: {
          ...state.friendRequestData,
          friendList: newFriendRequestList
        }
      };
    case TYPES.REJECT_REQUEST_SUCCESS:
      const newFriendRejectList = state.friendRequestData.friendList.filter(i => i.id !== action.data.id);

      return {
        ...state,
        status: action.type,
        friendRequestData: {
          ...state.friendRequestData,
          friendList: newFriendRejectList
        }
      }

    case TYPES.DELETE_FRIEND_SUCCESS:
      const friendId = action.data.id;
      const newFriendProgressList = state.friendProgressData.friendProgressList.filter(i => i.friendInfo.id !== friendId);

      return {
        ...state,
        status: action.type,
        friendProgressData: {
          ...state.friendProgressData,
          friendProgressList: newFriendProgressList
        },
        currentPage: newFriendProgressList.length ? state.currentPage : 0,
        totalPage: newFriendProgressList.length ? state.totalPage : 0
      };

    case TYPES.SEND_FRIEND_SUCCESS:
      const friendRequestId = action.data.id;
      const index = state.friendSearchData.usersList.findIndex(i => i.id === friendRequestId);
      const newFriendSearchData = state.friendSearchData;
      if (index >= 0)
        newFriendSearchData.usersList[index].statusFriend = friendStatus.WAITING.VALUE;

      return {
        ...state,
        status: action.type,
        friendSearchData: newFriendSearchData
      };

    case TYPES.RESET_FRIEND_SEARCH_DATA_REQUEST:
      const resetFriendSearchData = state.friendSearchData;
      resetFriendSearchData.usersList = [];
      return {
        ...state,
        status: action.type,
        friendSearchData: resetFriendSearchData
      };

    case TYPES.PROGRESS_SUBJECT_SUCCESS:
      return {
        ...state,
        status: action.type,
        progressSubjectList: action.data.progressSubject
      };

    // ACTION FAILED WILL BE PLACED HERE
    case TYPES.PROGRESS_FAILED:
    case TYPES.FRIEND_PROGRESS_FAILED:
    case TYPES.FRIEND_REQUEST_FAILED:
    case TYPES.SEARCH_FRIEND_FAILED:
    case TYPES.ACCEPT_REQUEST_FAILED:
    case TYPES.DELETE_FRIEND_FAILED:
    case TYPES.SEND_FRIEND_FAILED:
    case TYPES.PROGRESS_SUBJECT_FAILED:
      return {
        ...state,
        status: '',
        error: ''
      };
    default:
      return state;
  }
};
