/* eslint-disable no-alert */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import cx from "classnames";
import IconHome from "../../static/images/iconHome.png";
import IconHomeActive from "../../static/images/iconHomeActive.png";
import IconLearning from "../../static/images/IconLearning.png";
import IconLearningActive from "../../static/images/IconLearningActive.png";
import IconMyWord from "../../static/images/IconMyWord.png";
import IconMyWordActive from "../../static/images/IconMyWordActive.png";
import IconReward from "../../static/images/IconReward.png";
import IconRewardActive from "../../static/images/IconRewardActive.png";
import IconLessons from "../../static/images/IconLessons.png";
import IconLessonsActive from "../../static/images/IconLessonsActive.png";
import IconProgress from "../../static/images/IconProgress.png";
import IconProgressActive from "../../static/images/IconProgressActive.png";
import IconOftenWrong from "../../static/images/IconOftenWrong.png";
import IconOftenWrongActive from "../../static/images/IconOftenWrongActive.png";
import RouterPath from "../../constants/router-path";
import {LARGE_UNIT_TYPE} from "../../constants/common";
import useWindowSize from "../../hooks/useWindowSize";
import theme from "../../style/variables";
import HeaderMenu from "../Navigation/HeaderMenu";
import Modal from "../Modal/Modal";
import useChangeGrade from "../../hooks/useChangeGrade";
import Storage from "../../utils/storega";
import ContentMaintain from '../Maintain/ContentMaintain';
import {Copyright, Divider, SidebarItem, SidebarLink, SidebarWrapper} from "./style";

const Sidebar = 
({
  sidebar,
  currentGrade,
  // subjectList,
  toggleSidebar,
  isscrollinpage,
  modalGrade,
  gradeList,
  changeGrade,
  headerSubjectList,
  headerSubSubjectList,
  showModalGrade,
  updateSubSubjectList,
  statusHome,
  getSubjectsHeader,
  isRedirectPage,
  isOutSide,
  checkOutSideAction,
  acceptOutSideAction
}) => {
  const [type, setType] = useState(LARGE_UNIT_TYPE.LEARNING_AND_TEST);
  const history = useHistory();
  const size = useWindowSize();

  const {
    selectedGrade,
    setSelectedGrade,
    selectedSubject,
    setSelectedSubject,
    selectedSubSubject,
    setSelectedSubSubject,
    localGrade,
    handleChangeSelectedGrade,
    handleChangeGrade,
    handleChangeSelectedSubject,
    handleSelectSubSubject,
    maitain,
    handleClose
  } = useChangeGrade({
    gradeList,
    changeGrade,
    headerSubjectList,
    headerSubSubjectList,
    showModalGrade,
    history,
    updateSubSubjectList,
    statusHome,
    getSubjectsHeader,
    isRedirectPage
  });

  useEffect(() => {
    if (localGrade && history.location.pathname !== RouterPath.SELECT_CHARRACTOR.path) {
      setSelectedGrade(localGrade.grade?.gradeLC);
      setSelectedSubject(localGrade.subject?.subjectLC);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickSidebar = () => {
    if (size.width <= theme.tabletWidth) {
      toggleSidebar();
    }
  };

  const toLessons = useCallback((e, type) => {
    e.preventDefault();
    if(isOutSide && history.location.pathname === RouterPath.MY_NOTE.path) {
      let cfAlet;
      cfAlet = window.confirm('このままではデータが失われます。よろしいですか？');
      if(cfAlet) {
        setType(type);
        Storage.set('MENU_TYPE', type);
        if (localGrade) {
          const params = {gradeGroupCode: localGrade.grade?.groupCode, menu: type};
          getSubjectsHeader({params});
        }
        checkOutSideAction(false);
        acceptOutSideAction();
        showModalGrade();
      }
    } else {
      setType(type);
      Storage.set('MENU_TYPE', type);
      if (localGrade) {
        const params = {gradeGroupCode: localGrade.grade?.groupCode, menu: type};
        getSubjectsHeader({params});
      }
      showModalGrade();
    }
    
  }, [setType, showModalGrade, localGrade, getSubjectsHeader, isOutSide, history, acceptOutSideAction, checkOutSideAction]);

  useEffect(() => {
    if (currentGrade.subject && currentGrade.subject.subjectId) {
      const grade = Storage.get("USER_SELECT_GRADE");
      setSelectedGrade(grade.grade?.gradeLC);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGrade]);

  const handleCloseModal = () => {
    showModalGrade();
    if(!isOutSide && history.location.pathname === RouterPath.MY_NOTE.path) {
      checkOutSideAction(true);
      acceptOutSideAction();
    }
    const localGrade = Storage.get("USER_SELECT_GRADE");
    if (localGrade) {
      // if (!headerSubjectList.length) {
      //   const params = { gradeGroupCode: localGrade.grade?.groupCode };
      //   getSubjectsHeader({ params });
      // }

      const params = {gradeGroupCode: localGrade.grade?.groupCode, menu: Storage.get('CURRENT_MENU_TYPE') || 1};
      getSubjectsHeader({ params });
      updateSubSubjectList({ subject: localGrade.subject });
      setSelectedGrade(localGrade.grade?.gradeLC);
      setSelectedSubject(localGrade.subject?.subjectLC);
      setSelectedSubSubject(localGrade.subSubject?.id);
    }
    Storage.set('MENU_TYPE', '');
  };

  const getType = parseFloat(history.location.pathname.split('/type/')[1]);

  return (
    <>
      <SidebarWrapper isscrollinpage={isscrollinpage} $toggle={sidebar}>
        <SidebarLink
          exact
          to="/"
          activeClassName="active"
          $toggle={sidebar}
          url={IconHome}
          urlhover={IconHomeActive}
          onClick={handleClickSidebar}
        >
          <div className="sidebar-icon" />
          <SidebarItem $toggle={sidebar}>ホーム</SidebarItem>
        </SidebarLink>
        <Divider $toggle={sidebar} />

        <SidebarLink
          to={RouterPath.LESSONS.path}
          className={cx(getType === LARGE_UNIT_TYPE.LEARNING_AND_TEST && 'active')}
          $toggle={sidebar}
          url={IconLearning}
          urlhover={IconLearningActive}
          readOnly
          onClick={e => {
            toLessons(e, LARGE_UNIT_TYPE.LEARNING_AND_TEST);
            handleClickSidebar();
          }}
        >
          <div className="sidebar-icon" />
          <SidebarItem $toggle={sidebar}>暗記学習</SidebarItem>
        </SidebarLink>

        <SidebarLink
          to={RouterPath.LESSONS.path}
          className={cx(getType === LARGE_UNIT_TYPE.ONLY_TEST  && 'active')}
          $toggle={sidebar}
          url={IconLessons}
          urlhover={IconLessonsActive}
          readOnly
          onClick={e => {
            toLessons(e, LARGE_UNIT_TYPE.ONLY_TEST);
            handleClickSidebar();
          }}
        >
          <div className="sidebar-icon" />
          <SidebarItem $toggle={sidebar}>単元学習</SidebarItem>
        </SidebarLink>

        <SidebarLink
          to={RouterPath.MY_NOTE.path}
          activeClassName="active"
          $toggle={sidebar}
          url={IconMyWord}
          urlhover={IconMyWordActive}
          onClick={handleClickSidebar}
        >
          <div className="sidebar-icon" />
          <SidebarItem $toggle={sidebar}>My 単語帳</SidebarItem>
        </SidebarLink>

        <SidebarLink
          to={RouterPath.PROGRESS.path}
          activeClassName="active"
          $toggle={sidebar}
          url={IconProgress}
          urlhover={IconProgressActive}
          onClick={handleClickSidebar}
        >
          <div className="sidebar-icon" />
          <SidebarItem $toggle={sidebar}>進捗情報</SidebarItem>
        </SidebarLink>

        <SidebarLink
          to={RouterPath.RANKING.path}
          activeClassName="active"
          $toggle={sidebar}
          url={IconReward}
          urlhover={IconRewardActive}
          onClick={handleClickSidebar}
        >
          <div className="sidebar-icon" />
          <SidebarItem $toggle={sidebar}>ランキング</SidebarItem>
        </SidebarLink>

        <SidebarLink
          to={RouterPath.OFTEN_WRONG.path}
          activeClassName="active"
          $toggle={sidebar}
          url={IconOftenWrong}
          urlhover={IconOftenWrongActive}
          onClick={handleClickSidebar}
        >
          <div className="sidebar-icon" />
          <SidebarItem $toggle={sidebar}>苦手な問題</SidebarItem>
        </SidebarLink>

        <Copyright $toggle={sidebar}>
          Copyright © Sanaru Co.Ltd.
          <br />
          All rights reserved
        </Copyright>
      </SidebarWrapper>

      <Modal
        show={modalGrade}
        close={handleCloseModal}
        title="学年と教科を選択"
      >
        <HeaderMenu
          gradeList={gradeList}
          headerSubjectList={headerSubjectList}
          headerSubSubjectList={headerSubSubjectList}
          selectedGrade={selectedGrade}
          handleChangeSelectedGrade={handleChangeSelectedGrade}
          selectedSubject={selectedSubject}
          handleChangeSelectedSubject={handleChangeSelectedSubject}
          selectedSubSubject={selectedSubSubject}
          handleSelectSubSubject={handleSelectSubSubject}
          handleChangeGrade={handleChangeGrade}
          type={type}
          number={0}
        />
      </Modal>
      <Modal
        show={maitain}
        title="メンテナンスのお知らせ"
        maitain="maitain"
      >
        <ContentMaintain onClose={() => handleClose()} btnText="戻る"/>
      </Modal>
    </>
  )
};

export default React.memo(Sidebar);

Sidebar.propTypes = {
  sidebar: PropTypes.bool,
  currentGrade: PropTypes.instanceOf(Object)
  // subjectList: PropTypes.instanceOf(Array)
};

Sidebar.defaultProps = {
  sidebar: false,
  currentGrade: {}
  // subjectList: []
};
