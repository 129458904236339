import {callApi, params} from "../../utils/commonUtils";
import Storage from "../../utils/storega";
import {getEnv} from "../../configs/env";
import {TYPES} from ".";

const updateStatus = (status, statusInfo) => ({
  type: status,
  data: statusInfo
});

const updateNickName = (payload) => {
  const config = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    },
    body: JSON.stringify({...payload})
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.UPDATE_NICKNAME_REQUEST));
    callApi(
      `${getEnv('REACT_APP_API_SERVER')}/users/update_profile`,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.UPDATE_NICKNAME_SUCCESS, {data}));
        dispatch(updateStatus(TYPES.GET_USER_PROFILE_SUCCESS, {data: data.data}));
      },
      (err) => {
        dispatch(updateStatus(TYPES.UPDATE_NICKNAME_FAILED, {error: err}));
      }
    );
  };
};

const userSettingTarget = (payload) => {
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    },
    body: JSON.stringify(payload.lessonsIds)
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.USER_SETTING_TARGET_REQUEST));
    callApi(
      `${getEnv('REACT_APP_API_SERVER')}/users/user_target_settings`,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.USER_SETTING_TARGET_SUCCESS, {data, idx: payload.idx}));
      },
      (err) => {
        dispatch(updateStatus(TYPES.USER_SETTING_TARGET_FAILED, {error: err}));
      }
    );
  };
};

const getTargetSubjects = (payload = {}) => {
  let url = `${getEnv("REACT_APP_API_SERVER")}/users/subjects`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_TARGET_SUBJECTS_REQUEST));
    callApi(
      url,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.GET_TARGET_SUBJECTS_SUCCESS, {data: data.data}));
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_TARGET_SUBJECTS_FAILED, {error: err}));
      }
    );
  };
};

const getTargetSubSubjects = (payload = {}) => {
  let url = `${getEnv("REACT_APP_API_SERVER")}/users/sub_subjects`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_TARGET_SUB_SUBJECTS_REQUEST));
    callApi(
      url,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.GET_TARGET_SUB_SUBJECTS_SUCCESS, {data: data.data}));
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_TARGET_SUB_SUBJECTS_FAILED, {error: err}));
      }
    );
  };
};

const getLargeUnitAndLesson = (payload = {}) => {
  let url = `${getEnv("REACT_APP_API_SERVER")}/users/large_units/large_units_and_lessons`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LARGE_UNIT_AND_LESSON_REQUEST));
    callApi(
      url,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.GET_LARGE_UNIT_AND_LESSON_SUCCESS, {data: data.data}));
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_LARGE_UNIT_AND_LESSON_FAILED, {error: err}));
      }
    );
  };
};

const resetLargeUnitAndLesson = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.RESET_LARGE_UNIT_AND_LESSON_REQUEST));
  };
};

const resetSetting = () => ({
  type: "RESET_SETTINGS"
});

export {
  updateNickName,
  userSettingTarget,
  getTargetSubjects,
  getTargetSubSubjects,
  getLargeUnitAndLesson,
  resetLargeUnitAndLesson,
  resetSetting
};
