import React from "react";
import styled from "styled-components";
import { Spinner } from "reactstrap";

const Div = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "90vh")};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = ({ height }) => {
  return (
    <Div height={height}>
      <Spinner type="grow" color="primary" />
    </Div>
  );
};

export default Loading;
