import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from "react-router-dom";
import ContentWrapper from './ContentWrapper';

const mapStateToProps = (state) => ({
    status: state.sidebar.status,
    error: state.sidebar.error,
    sidebar: state.sidebar.sidebar
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentWrapper));
