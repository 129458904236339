const theme = {
  // color
  headerColor: "#F5A623",
  buttonMainColor: "#7ED321",
  buttonActiveColor: "#417505",
  buttonCancelColor: "#D8D8D8",
  buttonDangerColor: "#D0021B",
  textPrimary: "#152B5A",
  textActive: "#4A90E2",
  textSecondary: "#afb3d0",
  textHover: "#fff",
  buttonColorGreen: "#7ED321",
  buttonColorBlue: "#276BF0",
  buttonColorGray: "#D8D8D8",
  headerColorBlue: "#4A90E2",
  contentBackgroundColor: "#c5deee",
  contentSubBackgroundColor: "#E2EFFD",
  textColorGray: "#AEB3D0",
  textColorLightGray: "#EEEEEE",
  backgroundMainColor: "#EDF4FC",
  backgroundColorWhite: "#FFF",
  textColorBlack: "#1C2E55",
  progressBarColor: "#FEC24D",
  progressBarFullColor: "#276BF0",
  progressCheckColor: "#37B8BC",
  selectGroupBackgroundColor: "#F8F9FD",
  selectGroupBorderColor: "#DEE0EC",
  selectedCardColor: "#2B53A0",
  progressBackgroundColor: "#E9F1FE",
  modalHeaderBackgroundColor: "#0A2761",
  modalHeaderTextColor: "#C8DBFE",
  modalBorderColor: "#EDEFF8",
  boxShadowColor: "#2B53A01A",
  progressTrailColor: "#BDE0FE",
  progressTrailColorLight: "#E9F1FE",
  progressTextColor: "#0A2761",
  progressGroupBackgroundColor: "#F3F6FF",
  tableBackgroundColor: "#F8F9FD",
  tableOddRowBackgroundColor: "#F0F2F8",
  copyRightColor: "#B1B6D1",
  blueDot: "#4D88FA",
  numberUnitColor: "#96A5BA",
  lastAccessColor: "#B4B9D3",
  disableBackgound: "#e8e8e8",
  mainButton: {
    primary: "#276BF0",
    danger: "#FF6867",
    warning: "#FEC24D",
    pink: "#F76D93",
    success: "#7ED321",
    englishColor: "#7ab5f5",
    nationalColor: "#276bf0",
    mathColor: "#44c161",
    math2Color: "#0af1f1",
    physicalColor: "#beb2ff",
    socialColor: "#f7a4b0",
    society2Color: "#fec24d"
  },
  mainButtonOutline: {
    primary: "#E9F1FE",
    danger: "#FFF5F5",
    warning: "#FEC24D"
  },
  textSecondaryHover: "#288DE8",
  buttonColorYellow: "#FEB22A",
  backgroundColorGreen: "#4DC9A8",
  backgroundColorLightGreen: "#F1FFFB",

  // margin, padding, border
  contentPadding: 10,
  borderRoundCard: 0.25,
  // width, height, size
  headerHeight: 66,
  footerHeight: 50,
  sidebarWidth: 290,
  sidebarMacWidth: 200,
  sidebarRightWidth: 390,
  sidebarRightWidthSmall: 320,
  sidebarWidthSmall: 90,
  sidebarMacWidthSmall: 0,
  buttonMinWidth: 100,
  modalWidth: 500,
  modalLevel: 350,
  modalWidthMobile: 360,
  paginationWidth: 35,
  BlockControlWrapperWidth: 130,
  WaitingBlockControlWrapperWidth: 200,
  countDownTimerSize: 130,
  testingOptionButtonHeight: 58,
  laptopWidth: 1500,
  macWidth: 1280,
  tabletWidth: 768 - 0.1,
  mobileWidth: 767,
  mobileWidthExtraSmall: 320,
  maxWidthMobile: 480,
  iPadMinWidth: 768,
  iPadMaxWidth: 1024,
  tabletMinWidth: 640,
  tabletMaxWidth: 799,
  homepageMaxWidth: 1249,
  largeBoostrap: 992,
  lessonWrapWidth: 1050,

  // font-size
  fontSizeBase: 16,
  fontSizeHeading: 24,

  // animated
  baseTransition: "all 0.3s ease"
};

export default theme;
